import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBikeDetail } from "../../State/NewBike/Action";
import MainCarousel from "../MainCarousel/MainCarousel";

export default function NewBikeDetail() {
  const location = useLocation();
  const bikeId = new URLSearchParams(location.search).get("bikeId");
  const newBike = useSelector((store) => store.newBikesResponse.bike);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(getBikeDetail(bikeId));
  }, [bikeId, dispatch]);

  if (!newBike) {
    return <p className="text-center text-lg font-semibold">Loading...</p>;
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="text-2xl font-bold text-blue-600 text-center mb-4">
        {newBike.brand} - {newBike.model}
      </header>

      {/* Carousel */}
      <div className="max-w-3xl mx-auto">
        {newBike?.images && <MainCarousel imageList={newBike.images} />}
      </div>

      {/* Price */}
      <div className="text-center text-xl font-semibold text-gray-800 my-4">
        ₹{newBike.exShowroomPrice} /-
      </div>

      {/* Tabs */}
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-4">
        <div className="flex justify-center space-x-4 border-b pb-2">
          {[
            "Primary Details",
            "Specifications",
            "Servicing",
            newBike.fuelType === "ELECTRIC" && "Battery Info",
          ]
            .filter(Boolean) // Remove undefined values
            .map((label, index) => (
              <button
                key={index}
                className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-all duration-300 ${
                  activeTab === index
                    ? "border-b-4 border-blue-600 text-blue-600"
                    : "text-gray-600 hover:text-blue-600"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {label}
              </button>
            ))}
        </div>

        {/* Tab Panels */}
        <div className="p-4">
          {activeTab === 0 && (
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="font-semibold text-gray-700">Brand:</p>
                  <p>{newBike.brand}</p>
                </div>
                <div>
                  <p className="font-semibold text-gray-700">Model:</p>
                  <p>{newBike.model}</p>
                </div>
                <div>
                  <p className="font-semibold text-gray-700">Color:</p>
                  <p>{newBike.color}</p>
                </div>
                {newBike.mileage && (
                  <div>
                    <p className="font-semibold text-gray-700">Mileage:</p>
                    <p>{newBike.mileage} kmpl</p>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <p className="font-semibold text-gray-700">About:</p>
                <p>{newBike.summary}</p>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="grid grid-cols-2 gap-4">
              {newBike.engineCapacity && (
                <div>
                  <p className="font-semibold text-gray-700">
                    Engine Capacity:
                  </p>
                  <p>{newBike.engineCapacity} cc</p>
                </div>
              )}
              <div>
                <p className="font-semibold text-gray-700">
                  Ex Showroom Price:
                </p>
                <p>₹{newBike.exShowroomPrice}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Variant:</p>
                <p>{newBike.variant}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Height:</p>
                <p>{newBike.height} mm</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Length:</p>
                <p>{newBike.length} mm</p>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="grid grid-cols-2 gap-4">
              {["service1", "service2", "service3", "service4"].map(
                (service, idx) =>
                  newBike[service] && (
                    <div key={idx}>
                      <p className="font-semibold text-gray-700">
                        Service {idx + 1}:
                      </p>
                      <p>{newBike[service]} kms</p>
                    </div>
                  )
              )}
            </div>
          )}

          {activeTab === 3 && newBike.fuelType === "ELECTRIC" && (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold text-gray-700">Charging Time:</p>
                <p>{newBike.chargingTimeInHours} Hours</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Max Power:</p>
                <p>{newBike.maxPowerInKw} KW</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Fast Charging:</p>
                <p>{newBike.fastCharging ? "Yes" : "No"}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Battery Capacity:</p>
                <p>{newBike.batteryCapacityInKwh} KW</p>
              </div>
              <div>
                <p className="font-semibold text-gray-700">Battery Warranty:</p>
                <p>{newBike.batteryWarranty} Years</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
