import {
  SEND_SMS_OTP_FAILURE,
  SEND_SMS_OTP_REQUEST,
  SEND_SMS_OTP_SUCCESS,
  SMS_OTP_SIGNIN_FAILURE,
  SMS_OTP_SIGNIN_REQUEST,
  SMS_OTP_SIGNIN_SUCCESS,
} from "./ActionType";

const initialState = {
  loading: false,
  error: null,
  loginResponse: {},
  success: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case [SEND_SMS_OTP_REQUEST, SMS_OTP_SIGNIN_REQUEST]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEND_SMS_OTP_SUCCESS:
      return {
        ...state,
        success: action.payload,
        loading: false,
        error: null,
      };
    case [SEND_SMS_OTP_FAILURE, SMS_OTP_SIGNIN_FAILURE]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case SMS_OTP_SIGNIN_SUCCESS:
        return {
            ...state,
            loginResponse: action.payload,
            loading: false,
            error: null,
        };
    default:
      return state;
  }
};
