import React, { useEffect, useState } from "react";
import { getReviews } from "../../State/Reviews/Action";
import { useDispatch, useSelector } from "react-redux";
import AppPagination from "../Tools/AppPagination";
import moment from "moment";
import { Rating } from "@mui/material";

const ReviewList = () => {
  const [vehicle, setVehicle] = useState("CAR");
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("?vehicleType=CAR");
  const dispatch = useDispatch();
  const { review } = useSelector((store) => store);

  useEffect(() => {
    let filter1 = `${filter}&page=${page}`;
    dispatch(getReviews(filter1));
  }, [dispatch, page, filter]);

  const handleVehicleChange = (vehicle) => {
    setVehicle(vehicle);
    setFilter(`?vehicleType=${vehicle}`);
    setPage(0);
  };

  return (
    <div className="px-6 py-4 max-w-6xl mx-auto bg-white shadow-lg rounded-lg">
      <div className="flex justify-center gap-4 my-4">
        <button
          className={`px-6 py-2 text-lg font-semibold rounded-md transition-all duration-300 border-2 ${
            vehicle === "CAR"
              ? "bg-blue-500 text-white border-blue-500"
              : "bg-gray-100 border-gray-300 text-gray-700 hover:bg-blue-100"
          }`}
          onClick={() => handleVehicleChange("CAR")}
        >
          Car Reviews
        </button>
        <button
          className={`px-6 py-2 text-lg font-semibold rounded-md transition-all duration-300 border-2 ${
            vehicle === "BIKE"
              ? "bg-blue-500 text-white border-blue-500"
              : "bg-gray-100 border-gray-300 text-gray-700 hover:bg-blue-100"
          }`}
          onClick={() => handleVehicleChange("BIKE")}
        >
          Bike Reviews
        </button>
      </div>

      <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
        All Reviews
      </h1>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="py-3 px-4 text-left text-sm font-semibold">
                Customer
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold">
                Partner
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold">
                Rating
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold">
                Review
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold">
                Review Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {review.reviews?.reviews?.map((review) => (
              <tr key={review.reviewId} className="hover:bg-gray-100">
                <td className="px-4 py-3 text-sm text-gray-700 font-medium">
                  {review.customer}
                </td>
                <td className="px-4 py-3 text-sm text-gray-700 font-medium">
                  {review.partner}
                </td>
                <td className="px-4 py-3">
                  <Rating name="read-only" value={review.rating} readOnly />
                </td>
                <td className="px-4 py-3 text-sm text-gray-600 max-w-xs truncate">
                  {review.review}
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">
                  {moment(review.date).format("DD-MM-YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <AppPagination
          setPage={setPage}
          totalPages={review?.reviews?.totalPages}
        />
      </div>
    </div>
  );
};

export default ReviewList;
