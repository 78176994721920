import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AppPagination from "../Tools/AppPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  addExpense,
  getExpense,
  getExpenseSummary,
} from "../../State/Expense/Action";
import moment from "moment";

const Expense = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const { expense } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [newExpense, setNewExpense] = useState({
    title: "",
    description: "",
    amount: "",
    date: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"), // ✅ Correct format
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setNewExpense({
      title: "",
      description: "",
      amount: "",
      date: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"),
    });
    setOpen(false);
  };

  const handleChange = (e) => {
    setNewExpense({ ...newExpense, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setNewExpense({
      ...newExpense,
      date: dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS"),
    }); // ✅ Format date on selection
  };

  const handleSubmit = async () => {
    try {
      await dispatch(addExpense(newExpense)); // ✅ Wait for expense to be added
      dispatch(getExpense(`?page=${page}`)); // ✅ Fetch updated expenses list
      dispatch(getExpenseSummary()); // ✅ Fetch updated summary
      handleClose();
    } catch (error) {
      alert("Error adding expense");
    }
  };

  useEffect(() => {
    dispatch(getExpense(`?page=${page}`));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getExpenseSummary());
  }, [dispatch]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center my-4">
        <h1 className="text-2xl font-bold">Expense List</h1>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Expense
        </Button>
      </div>

      <div className="overflow-x-auto w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th className="py-3 px-4 text-left text-sm font-bold text-gray-900">
                User
              </th>
              <th className="py-3 px-4 text-left text-sm font-bold text-gray-900">
                Title
              </th>
              <th className="py-3 px-4 text-left text-sm font-bold text-gray-900">
                Description
              </th>
              <th className="py-3 px-4 text-left text-sm font-bold text-gray-900">
                Amount
              </th>
              <th className="py-3 px-4 text-left text-sm font-bold text-gray-900">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {expense.expenses?.expenses?.map((expense, index) => (
              <tr key={index} className="border hover:bg-gray-100">
                <td className="px-4 py-4 text-sm text-gray-900">
                  {expense.userName}
                </td>
                <td className="px-4 py-4 text-sm text-gray-900">
                  {expense.title}
                </td>
                <td className="px-4 py-4 text-sm text-gray-500">
                  {expense.description}
                </td>
                <td className="px-4 py-4 text-sm text-gray-500">
                  ₹{expense.amount}
                </td>
                <td className="px-4 py-4 text-sm text-gray-500">
                  {moment(expense.date).format("lll")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-6 text-center">
          <AppPagination
            setPage={setPage}
            totalPages={expense.expenses?.totalPages}
          />
        </div>

        {/* 🟢 Displaying Total Expenses */}
        <div className="mt-8 p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-bold text-center">Expense Summary</h2>
          {expense.expenseSummary?.userExpense?.length > 0 ? (
            expense.expenseSummary?.userExpense?.map((userSummary, index) => (
              <div key={index} className="mb-4 p-3 border rounded-lg bg-white">
                <p className="text-gray-700">
                  Total Expense: {userSummary.userName}
                </p>
                <p className="text-gray-700">
                  Total Expense: ₹{parseInt(userSummary.totalExpense, 10)}
                </p>
                {userSummary.monthlyExpenses.length > 0 ? (
                  <ul className="mt-2">
                    {userSummary.monthlyExpenses.map((month, idx) => (
                      <li key={idx} className="text-gray-600">
                        {month.month} {month.year}: ₹
                        {parseInt(month.expense, 10)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500">No monthly expenses recorded.</p>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500">No expense summary available.</p>
          )}
        </div>
      </div>

      {/* Dialog for Adding Expense */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Expense</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Title"
            name="title"
            value={newExpense.title}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Description"
            name="description"
            value={newExpense.description}
            onChange={handleChange}
            multiline
            rows={3}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Amount"
            name="amount"
            type="number"
            value={newExpense.amount}
            onChange={handleChange}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Date"
              value={dayjs(newExpense.date)}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Expense;
