import { useState, useRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import GoogleIcon from "@mui/icons-material/Google"; // Google logo icon
import { useDispatch, useSelector } from "react-redux";
import { sendSmsOtp, smsOtpSignin } from "../../State/Auth/Action";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import { useMyContext } from "../../store/ContextApi";
import { FcGoogle } from "react-icons/fc";
import { CheckBox } from "@mui/icons-material";
const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]); // Array for 6-digit OTP
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const [jwtToken, setJwtToken] = useState("");
  const { setToken, token } = useMyContext();
  const [showCustomerName, setShowCustomerName] = useState(false);
  const [fullName, setFullName] = useState("");
  const [role, setRole] = useState("customer");

  // Create a ref for each OTP input manually
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // Handle phone input validation (only numbers & max 10 digits)
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };

  // Show alert dialog
  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleCheckboxChange = (event) => {
    setRole(event.target.checked ? "dealer" : "customer");
  };

  // Handle OTP request
  const handleGetOtp = async () => {
    if (phone.length !== 10) {
      showAlert("Please enter a valid 10-digit phone number");
      return;
    }

    dispatch(sendSmsOtp(phone));
    // Open OTP dialog
    setOpen(true);
  };

  // Handle OTP Input Change
  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Allow only single digit numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to next input box if a number is entered
    if (value && index < 3) {
      otpRefs[index + 1]?.current?.focus();
    }
  };

  const handleVerifyOtp = () => {
    const otpValue = otp.join("");
    if (otpValue.length !== 4) {
      showAlert("Please enter a valid 4-digit OTP");
      return;
    }
    const otpLoginRequest = { phoneNumber: phone, otp: otpValue };

    otpSignIn(otpLoginRequest);
  };

  const otpSignIn = async (input) => {
    try {
      const response = await api.post("/auth/public/otp-signin", input);

      //showing success message with react hot toast
      toast.success("Login Successful");

      //reset the input field by using reset() function provided by react hook form after submission

      if (response.status === 200 && response.data.jwtToken) {
        setJwtToken(response.data.jwtToken);
        const decodedToken = jwtDecode(response.data.jwtToken);

        handleSuccessfulLogin(response.data.jwtToken, decodedToken);
      } else {
        toast.error(
          "Login failed. Please check your credentials and try again."
        );
      }
    } catch (error) {
      if (error) {
        if (error.response.data.userExists === false) {
          setShowCustomerName(true);
        } else {
          toast.error("Invalid credentials");
        }
      }
    } finally {
      // setLoading(false);
    }
  };

  const handleSave = async () => {
    const otpValue = otp.join("");
    const input = { phoneNumber: phone, otp: otpValue, fullName, role };

    const response = await api.post("/auth/public/signup-by-phone", input);

    if (response.status === 200 && response.data.jwtToken) {
      setJwtToken(response.data.jwtToken);
      const decodedToken = jwtDecode(response.data.jwtToken);

      handleSuccessfulLogin(response.data.jwtToken, decodedToken);
    } else {
      toast.error(
        "Unable to register. Please check your credentials and try again."
      );
    }
  };

  const handleSuccessfulLogin = (token, decodedToken) => {
    const user = {
      username: decodedToken.sub,
      roles: decodedToken.roles ? decodedToken.roles.split(",") : [],
    };
    localStorage.setItem("JWT_TOKEN", token);
    localStorage.setItem("USER", JSON.stringify(user));

    //store the token on the context state  so that it can be shared any where in our application by context provider
    setToken(token);

    navigate("/home");
  };

  // Handle Backspace Key for OTP
  const handleOtpKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      otpRefs[index - 1]?.current?.focus();
    }
  };

  useEffect(() => {
    if (token) {
      if (localStorage.getItem("washType")) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  }, [navigate, token]);

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-500 to-indigo-600">
      <Card className="w-full max-w-sm p-6 rounded-lg shadow-xl bg-white">
        <CardContent className="flex flex-col gap-4">
          <Typography variant="h5" align="center" fontWeight="bold">
            Login to Your Account
          </Typography>

          {/* Phone Input (Only Numbers, Max 10 Digits) */}
          <TextField
            label="Phone Number"
            variant="outlined"
            type="tel"
            value={phone}
            onChange={handlePhoneChange}
            fullWidth
            inputProps={{ maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <PhoneAndroidIcon className="text-gray-500 mr-2" />
              ),
            }}
          />

          {/* Get OTP Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            startIcon={<LockOpenIcon />}
            onClick={handleGetOtp}
            style={{ marginTop: "10px", fontSize: "16px" }}
          >
            Get OTP
          </Button>

          {/* Google Login Divider */}
          <div className="flex items-center justify-between mt-4">
            <hr className="border-t border-gray-300 w-full" />
            <span className="px-2 text-gray-500">OR</span>
            <hr className="border-t border-gray-300 w-full" />
          </div>

          {/* Google Login Button */}
          <div className="flex items-center justify-between gap-1 py-5 ">
            <a
              href={`${apiUrl}/oauth2/authorization/google`}
              className="flex gap-1 items-center justify-center flex-1 border p-2 shadow-sm shadow-slate-200 rounded-md hover:bg-slate-300 transition-all duration-300"
            >
              <span>
                <FcGoogle className="text-2xl" />
              </span>
              <span className="font-semibold sm:text-customText text-xs">
                Login with Google
              </span>
            </a>
          </div>
        </CardContent>
      </Card>

      {/* OTP Verification Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle className="text-center font-bold">Verify OTP</DialogTitle>
        <DialogContent className="flex flex-col items-center">
          <Typography className="mb-4 text-gray-700">
            Enter the OTP sent to {phone}
          </Typography>

          {/* OTP Input Boxes */}
          <div className="flex gap-2">
            {otp.map((digit, index) => (
              <TextField
                key={index}
                variant="outlined"
                type="tel"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleOtpKeyDown(index, e)}
                inputRef={otpRefs[index]} // Link to ref for each input
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center", fontSize: "20px" },
                }}
                className="w-12 h-12 text-xl text-center border-2 border-gray-300 rounded-md focus:border-blue-500"
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleVerifyOtp}>
            Verify OTP
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showCustomerName} onClose={() => setOpen(false)}>
        <DialogTitle className="text-center font-bold">
          Customer Name
        </DialogTitle>
        <DialogContent className="flex flex-col items-center">
          {/* OTP Input Boxes */}
          <div className="flex gap-2">
            <TextField
              variant="outlined"
              label="Enter Full Name"
              onChange={(e) => setFullName(e.target.value)}
              // onChange={(e) => handleOtpChange(index, e.target.value)}
              // onKeyDown={(e) => handleOtpKeyDown(index, e)}
              // inputRef={otpRefs[index]} // Link to ref for each input
              inputProps={{
                maxLength: 30,
                style: { textAlign: "center", fontSize: "20px" },
              }}
              className="w-full h-12 text-xl text-center border-2 border-gray-300 rounded-md focus:border-blue-500"
            />
          </div>
          <div>
            <Box className="pt-4 flex items-center">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      sx={{
                        color: "primary.main",
                        "&.Mui-checked": { color: "primary.main" },
                        transform: "scale(1.3)", // Increases checkbox size
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: 400,
                        color: "#1976d2",
                      }}
                    >
                      Are you a Bike Dealer?
                    </span>
                  }
                />
              </FormGroup>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Dialog Box */}
      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <Typography>{alertMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
