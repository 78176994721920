import api from "../../services/api";
import {
  GET_CUSTOMER_USED_BIKES_INTEREST_FAILURE,
  GET_CUSTOMER_USED_BIKES_INTEREST_REQUEST,
  GET_CUSTOMER_USED_BIKES_INTEREST_SUCCESS,
} from "./ActionType";

const getCustomerUsedBikeInterestRequest = () => {
  return {
    type: GET_CUSTOMER_USED_BIKES_INTEREST_REQUEST,
  };
};

const getCustomerUsedBikeInterestSuccess = (data) => {
  return {
    type: GET_CUSTOMER_USED_BIKES_INTEREST_SUCCESS,
    payload: data,
  };
};

const getCustomerUsedBikeInterestFailure = (error) => {
  return {
    type: GET_CUSTOMER_USED_BIKES_INTEREST_FAILURE,
    payload: error,
  };
};

export const getCustomerUsedBikeInterest = (page) => async (dispatch) => {
  dispatch(getCustomerUsedBikeInterestRequest());
  try {
    const response = await api.get(
      `/interested/customer/used-bikes?page=${page}`
    );
    dispatch(getCustomerUsedBikeInterestSuccess(response.data));
  } catch (error) {
    dispatch(getCustomerUsedBikeInterestFailure(error));
  }
};
