import {
  GET_MODELS_BY_BRNAD_FAILURE,
  GET_MODELS_BY_BRNAD_REQUEST,
  GET_MODELS_BY_BRNAD_SUCCESS,
} from "./ActionType";

const initialState = {
  models: [],
  loading: false,
  error: null,
};

export const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODELS_BY_BRNAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MODELS_BY_BRNAD_SUCCESS:
      return {
        ...state,
        models: action.payload,
        loading: false,
        error: null,
      };
    case GET_MODELS_BY_BRNAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
