import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveContactUs } from "../../State/ContactUs/Action";
import { set } from "react-hook-form";

const ContactPage = () => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const resetForm = () => {
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("subject").value = "";
    document.getElementById("message").value = "";
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const formData = new FormData(event.target);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      subject: formData.get("subject"),
      message: formData.get("message"),
    };

    const inputRequest = {
      contactUs: data,
      setSuccessMessage,
      setErrorMessage,
    };

    console.log(inputRequest);
    dispatch(saveContactUs(inputRequest));
    resetForm();
  };
  return (
    <div className="flex items-start justify-center min-h-[calc(120vh)] bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4 text-center">
        <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
        <p className="text-gray-600 mb-4">
          We'd love to hear from you! If you have any questions or feedback,
          feel free to reach out to us on +919329211182 or email us at
          support@bluewasher.com or fill out the form below.
        </p>
        <form onSubmit={onSubmitHandler} className="space-y-4">
          <div>
            <label
              className="block text-left text-gray-700 mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label
              className="block text-left text-gray-700 mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label
              className="block text-left text-gray-700 mb-2"
              htmlFor="subject"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label
              className="block text-left text-gray-700 mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Send Message
          </button>
        </form>

        {successMessage.length > 0 && (
          <span className="text-green-700">{successMessage}</span>
        )}
        {errorMessage.length > 0 && (
          <span className="text-red-700">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default ContactPage;
