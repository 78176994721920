import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import api from "../../services/api";
import MainCarousel from "../MainCarousel/MainCarousel";
import { useDispatch, useSelector } from "react-redux";
import { getUsedBikeDetailsUnauthorized } from "../../State/UsedBike/Action";

export default function UsedBikeDetailShort() {
  // const [bike, setBike] = useState(null);
  const location = useLocation();
  const bikeId = new URLSearchParams(location.search).get("bikeId");
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const bike = useSelector((store) => store.usedBikesResponse.bike);

  useEffect(() => {
    dispatch(getUsedBikeDetailsUnauthorized(bikeId));
  }, [bikeId]);

  // if (!bike) return <Typography>Loading...</Typography>;

  return (
    <div>
      {bike && (
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              {bike.brand} {bike.model}
            </Typography>
          </Box>

          <Card sx={{ boxShadow: 3, borderRadius: 3, overflow: "hidden" }}>
            <MainCarousel imageList={bike.images || []} />
          </Card>

          <Typography
            variant="h5"
            color="primary"
            sx={{ mt: 2, textAlign: "center" }}
          >
            ₹{bike.price} /-
          </Typography>

          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
            centered
            sx={{ mt: 2 }}
          >
            <Tab label="Bike Details" />
            <Tab label="Seller Details" />
          </Tabs>

          {tabIndex === 0 && (
            <Card sx={{ mt: 2, p: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <b>Brand:</b> {bike.brand}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Model:</b> {bike.model}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Color:</b> {bike.color}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Price:</b> ₹{bike.price}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Running KM:</b> {bike.runningKms || "NA"}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Purchase Year:</b> {bike.purchaseYear}
                  </Grid>
                  {bike.modelYear && (
                    <Grid item xs={6}>
                      <b>Model Year:</b> {bike.modelYear}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <b>Description:</b> {bike.description}
                  </Grid>
                  <Grid item xs={12}>
                    <b>Bike No.:</b> {bike.registrationNumber}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          {tabIndex === 1 && (
            <Card sx={{ mt: 2, p: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <b>Seller:</b> {bike.sellerName}
                  </Grid>
                  <Grid item xs={6}>
                    <b>Contact:</b> {bike.maskedMobile}{" "}
                    <a href="/login">Login</a>
                  </Grid>
                  <Grid item xs={6}>
                    <b>Address:</b> {bike.sellerAddress}
                  </Grid>
                  <Grid item xs={6}>
                    <b>City:</b> {bike.sellerCity}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          <Box sx={{ mt: 3, p: 2, bgcolor: "#f9f9f9", borderRadius: 2 }}>
            <Typography variant="body2" color="textSecondary">
              <b>Note:</b> Blue bike does not verify the authenticity of any
              bike. Please check all details/documents before purchase.
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}
