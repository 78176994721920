import { useEffect, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsedBikesUnauthorized } from "../../State/UsedBike/Action";
import api from "../../services/api";
import AppPagination from "../Tools/AppPagination";
import UsedBikeDetailShort from "./UsedBikeDetailShort";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";
import { getBikeNames } from "../../State/NewBike/Action";

export default function UsedBikeListShort({ modelId = "" }) {
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [bikeId, setBikeId] = useState("");
  const [page, setPage] = useState(0);
  const [model, setModel] = useState(modelId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bikeNames = useSelector(
    (store) => store.newBikesResponse.bikeNames.bikeModelNames
  );

  const bikes = useSelector(
    (store) => store.usedBikesResponse.usedBikesPaged.usedBikes
  );
  const totalPages = useSelector(
    (store) => store.usedBikesResponse.usedBikesPaged.totalPages
  );

  useEffect(() => {
    dispatch(getUsedBikesUnauthorized(page, model));
  }, [page, model, dispatch]);

  useEffect(() => {
    dispatch(getBikeNames());
  }, []);

  const handleViewMore = (e, bike) => {
    navigate("/usedBikeDetailShort?bikeId=" + bike);
  };

  const handleBikeChange = (event, modelObj) => {
    setModel(modelObj ? modelObj.id : ""); // Ensure proper state update
  };

  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  return (
    <Box className="px-4">
      {!showBikeDetail ? (
        <>
          {/* Hero Section */}
          <Box className="flex justify-center my-4">
            <picture>
              <source media="(max-width: 465px)" srcSet={bikeImage1} />
              <source media="(min-width: 700px)" srcSet={bikeImage2} />
              <img
                src={bikeImage1}
                alt="bike ad"
                className="rounded-lg w-3/4 md:w-1/2"
              />
            </picture>
          </Box>

          <div className="flex justify-center mb-6">
            <Autocomplete
              onChange={handleBikeChange}
              onInputChange={(event, value, reason) => {
                if (reason === "clear") {
                  setModel("");
                }
              }}
              disablePortal
              {...defaultProps}
              sx={{ width: 300, backgroundColor: "white", borderRadius: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Search Bike" />
              )}
            />
          </div>

          {/* Bike List */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 justify-center">
            {bikes?.map((bike) => (
              <div
                key={bike.bikeId}
                className="bg-white shadow-lg rounded-lg overflow-hidden p-4 transition-transform transform hover:scale-105"
              >
                <img
                  onClick={(e) => handleViewMore(e, bike.bikeId)}
                  src={bike.imageUrl}
                  alt={bike.brand}
                  className="w-full h-40 object-cover cursor-pointer rounded-md"
                />
                <div className="mt-2">
                  <h3 className="text-lg font-semibold">
                    {bike.brand} {bike.model}
                  </h3>
                  <p className="text-gray-600">{bike.modelYear} Model</p>
                  <p className="text-blue-500 text-lg font-bold">
                    ₹{bike.price}/-
                  </p>
                  <div className="flex items-center text-gray-500 mt-1">
                    <LocationOnIcon fontSize="small" />
                    <span className="ml-1">{bike.sellerCity}</span>
                  </div>
                  <button
                    onClick={(e) => handleViewMore(e, bike.bikeId)}
                    className="text-blue-600 mt-2 block hover:underline"
                  >
                    View More
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <Box className="flex justify-center mt-6">
            <AppPagination totalPages={totalPages} setPage={setPage} />
          </Box>
        </>
      ) : (
        <UsedBikeDetailShort bikeId={bikeId} />
      )}
    </Box>
  );
}
