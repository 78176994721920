import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AppPagination from "../Tools/AppPagination";
import api from "../../services/api";
import bikesImage from "./images/bikes.png";
import styles from "./customer.module.css";
import mainStyles from "../Main.module.css";

export default function MyBikes() {
  const [bikes, setBikes] = useState([]);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const customerId = localStorage.getItem("userId");

  useEffect(() => {
    loadBikes();
  }, [page]);

  const loadBikes = () => {
    api
      .get(`/usedBikes/customer?page=${page}`)
      .then((res) => {
        setBikes(res.data.usedBikes);
        setTotalPages(res.data.totalPages);
      })
      .catch(console.error);
  };

  const handleViewMore = (bikeId) =>
    navigate(`/usedBikeDetail?bikeId=${bikeId}`);
  const handleUpdate = (bikeId) => navigate(`/updateUsedBike?bikeId=${bikeId}`);

  const handleDelete = () => {
    api.delete(`/usedBikes/${customerId}/${bikeId}`).then(() => {
      setOpen(false);
      loadBikes();
      window.scrollTo(0, 0);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.headingImageDiv}>
        <img src={bikesImage} alt="Bikes" className={styles.bigImage} />
      </div>
      <div className={styles.bikeListcontainer}>
        <Typography variant="h4" className={mainStyles.header}>
          My Bikes
        </Typography>
        <Grid container spacing={3}>
          {bikes.map((bike) => (
            <Grid item xs={12} sm={6} md={4} key={bike.bikeId}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={bike.imageUrl}
                  alt={bike.model}
                  onClick={() => handleViewMore(bike.bikeId)}
                />
                <CardContent>
                  <Typography variant="h6">
                    {bike.brand} {bike.model}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {bike.modelYear} Model
                  </Typography>
                  <Typography variant="h6">₹{bike.price}/-</Typography>
                  <Typography>Status: {bike.displayStatus}</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={1}
                  >
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="body2" ml={0.5}>
                      {bike.sellerAddress}, {bike.sellerCity}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-around" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleViewMore(bike.bikeId)}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<EditIcon />}
                      onClick={() => handleUpdate(bike.bikeId)}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setBikeId(bike.bikeId);
                        setOpen(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <AppPagination setPage={setPage} totalPages={totalPages} />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Delete Bike?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this bike?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
