import api from "../../services/api";
import {
  DELETE_BIKE_FAILURE,
  DELETE_BIKE_REQUEST,
  DELETE_BIKE_SUCCESS,
  GET_BIKE_DETAIL_FAILURE,
  GET_BIKE_DETAIL_REQUEST,
  GET_BIKE_DETAIL_SUCCESS,
  GET_BIKE_NAMES_FAILURE,
  GET_BIKE_NAMES_REQUEST,
  GET_BIKE_NAMES_SUCCESS,
  GET_NEW_BIKES_FAILURE,
  GET_NEW_BIKES_REQUEST,
  GET_NEW_BIKES_SUCCESS,
  REGISTER_NEW_BIKE_FAILURE,
  REGISTER_NEW_BIKE_REQUEST,
  REGISTER_NEW_BIKE_SUCCESS,
  UPDATE_NEW_BIKE_FAILURE,
  UPDATE_NEW_BIKE_REQUEST,
  UPDATE_NEW_BIKE_SUCCESS,
} from "./ActionType";

const getNewBikesRequest = () => {
  return {
    type: GET_NEW_BIKES_REQUEST,
  };
};

const getNewBikesSuccess = (newBikes) => {
  return {
    type: GET_NEW_BIKES_SUCCESS,
    payload: newBikes,
  };
};

const getNewBikesFailure = (error) => {
  return {
    type: GET_NEW_BIKES_FAILURE,
    payload: error,
  };
};

export const getNewBikes = (page, model) => {
  return async (dispatch) => {
    dispatch(getNewBikesRequest());
    try {
      const response = await api.get(`/newBikes?model=${model}&page=${page}`);
      dispatch(getNewBikesSuccess(response.data));
    } catch (error) {
      dispatch(getNewBikesFailure(error));
    }
  };
};

const getBikeNamesRequest = () => {
  return {
    type: GET_BIKE_NAMES_REQUEST,
  };
};

const getBikeNamesSuccess = (bikeNames) => {
  return {
    type: GET_BIKE_NAMES_SUCCESS,
    payload: bikeNames,
  };
};

const getBikeNamesFailure = (error) => {
  return {
    type: GET_BIKE_NAMES_FAILURE,
    payload: error,
  };
};

export const getBikeNames = () => {
  return async (dispatch) => {
    dispatch(getBikeNamesRequest());
    try {
      const response = await api.get("/public/bikeModels/names");
      dispatch(getBikeNamesSuccess(response.data));
    } catch (error) {
      dispatch(getBikeNamesFailure(error));
    }
  };
};

const deleteBikeRequest = () => {
  return {
    type: DELETE_BIKE_REQUEST,
  };
};

const deleteBikeSuccess = (bike) => {
  return {
    type: DELETE_BIKE_SUCCESS,
    payload: bike,
  };
};

const deleteBikeFailure = (error) => {
  return {
    type: DELETE_BIKE_FAILURE,
    payload: error,
  };
};

export const deleteBike = (bikeId) => {
  return async (dispatch) => {
    dispatch(deleteBikeRequest());
    try {
      const response = await api.put(`/newBikes/${bikeId}/delete`);
      dispatch(deleteBikeSuccess(response.data));
    } catch (error) {
      dispatch(deleteBikeFailure(error));
    }
  };
};

const getBikeDetailRequest = () => {
  return {
    type: GET_BIKE_DETAIL_REQUEST,
  };
};

const getBikeDetailSuccess = (bike) => {
  return {
    type: GET_BIKE_DETAIL_SUCCESS,
    payload: bike,
  };
};

const getBikeDetailFailure = (error) => {
  return {
    type: GET_BIKE_DETAIL_FAILURE,
    payload: error,
  };
};

export const getBikeDetail = (bikeId) => {
  return async (dispatch) => {
    dispatch(getBikeDetailRequest());
    try {
      const response = await api.get(`/newBikes/${bikeId}`);
      dispatch(getBikeDetailSuccess(response.data));
    } catch (error) {
      dispatch(getBikeDetailFailure(error));
    }
  };
};

const updateNewBikeRequest = () => {
  return {
    type: UPDATE_NEW_BIKE_REQUEST,
  };
};

const updateNewBikeSuccess = (bike) => {
  return {
    type: UPDATE_NEW_BIKE_SUCCESS,
    payload: bike,
  };
};

const updateNewBikeFailure = (error) => {
  return {
    type: UPDATE_NEW_BIKE_FAILURE,
    payload: error,
  };
};

export const updateNewBike = (bike) => {
  return async (dispatch) => {
    dispatch(updateNewBikeRequest());
    try {
      const response = await api.put(`/newBikes`, bike);
      dispatch(updateNewBikeSuccess(response.data));
    } catch (error) {
      dispatch(updateNewBikeFailure(error));
    }
  };
};

const registerNewBikeRequest = () => {
  return {
    type: REGISTER_NEW_BIKE_REQUEST,
  };
};

const registerNewBikeSuccess = (bike) => {
  return {
    type: REGISTER_NEW_BIKE_SUCCESS,
    payload: bike,
  };
};

const registerNewBikeFailure = (error) => {
  return {
    type: REGISTER_NEW_BIKE_FAILURE,
    payload: error,
  };
};

export const registerNewBike = (bike) => {
  return async (dispatch) => {
    dispatch(registerNewBikeRequest());
    try {
      const response = await api.post("/newBikes", bike);
      dispatch(registerNewBikeSuccess(response.data));
    } catch (error) {
      dispatch(registerNewBikeFailure(error));
    }
  };
};
