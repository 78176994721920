import api from "../../services/api";
import {
  ADD_CITY_FAILURE,
  ADD_CITY_REQUEST,
  ADD_CITY_SUCCESS,
  GET_CITIES_BY_STATE_FAILURE,
  GET_CITIES_BY_STATE_REQUEST,
  GET_CITIES_BY_STATE_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITY_FAILURE,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
} from "./ActionType";

const addCityRequest = () => ({ type: ADD_CITY_REQUEST });
const addCitySuccess = (city) => ({ type: ADD_CITY_SUCCESS, payload: city });
const addCityFailure = (error) => ({ type: ADD_CITY_FAILURE, payload: error });

const getCityRequest = () => ({ type: GET_CITY_REQUEST });
const getCitySuccess = (city) => ({ type: GET_CITY_SUCCESS, payload: city });
const getCityFailure = (error) => ({ type: GET_CITY_FAILURE, payload: error });

const getCitiesRequest = () => ({ type: GET_CITIES_REQUEST });
const getCitiesSuccess = (cities) => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
});
const getCitiesFailure = (error) => ({
  type: GET_CITIES_FAILURE,
  payload: error,
});

const getCitiesByStateRequest = () => ({ type: GET_CITIES_BY_STATE_REQUEST });
const getCitiesByStateSuccess = (cities) => ({
  type: GET_CITIES_BY_STATE_SUCCESS,
  payload: cities,
});
const getCitiesByStateFailure = (error) => ({
  type: GET_CITIES_BY_STATE_FAILURE,
  payload: error,
});

export const addCity = (request) => async (dispatch) => {
  dispatch(addCityRequest());
  try {
    const response = await api.post("/cities", request);
    const data = await response.json();
    dispatch(addCitySuccess(data));
  } catch (error) {
    dispatch(addCityFailure(error));
  }
};

export const getCities = () => async (dispatch) => {
  dispatch(getCitiesRequest());
  try {
    const response = await api.get("/cities");
    // const data = await response.json();
    dispatch(getCitiesSuccess(response.data));
  } catch (error) {
    dispatch(getCitiesFailure(error));
  }
};

export const getCitiesByState = (stateId) => async (dispatch) => {
  dispatch(getCitiesByStateRequest());
  try {
    const response = await api.get(`/cities/${stateId}`);
    // const data = await response.json();
    dispatch(getCitiesByStateSuccess(response.data));
  } catch (error) {
    dispatch(getCitiesByStateFailure(error));
  }
};
