// ActionTypes for booking actions
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";

export const GET_ALL_BOOKINGS_REQUEST_BY_USER_ID =
  "GET_ALL_BOOKINGS_REQUEST_BY_USER_ID";
export const GET_ALL_BOOKINGS_SUCCESS_BY_USER_ID =
  "GET_ALL_BOOKINGS_SUCCESS_BY_USER_ID";
export const GET_ALL_BOOKINGS_FAILURE_BY_USER_ID =
  "GET_ALL_BOOKINGS_FAILURE_BY_USER_ID";

export const GET_ALL_BOOKINGS_REQUEST = "GET_ALL_BOOKINGS_REQUEST";
export const GET_ALL_BOOKINGS_SUCCESS = "GET_ALL_BOOKINGS_SUCCESS";
export const GET_ALL_BOOKINGS_FAILURE = "GET_ALL_BOOKINGS_FAILURE";

export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAILURE = "UPDATE_BOOKING_FAILURE";

export const GET_ESTIMATED_PRICE_REQUEST = "GET_ESTIMATED_PRICE_REQUEST";
export const GET_ESTIMATED_PRICE_SUCCESS = "GET_ESTIMATED_PRICE_SUCCESS";
export const GET_ESTIMATED_PRICE_FAILURE = "GET_ESTIMATED_PRICE_FAILURE";

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAILURE = "CANCEL_BOOKING_FAILURE";

export const GET_BOOKING_BY_ID_REQUEST = "GET_BOOKING_BY_ID_REQUEST";
export const GET_BOOKING_BY_ID_SUCCESS = "GET_BOOKING_BY_ID_SUCCESS";
export const GET_BOOKING_BY_ID_FAILURE = "GET_BOOKING_BY_ID_FAILURE";

export const GET_BOOKING_BY_FILTER_REQUEST = "GET_BOOKING_BY_FILTER_REQUEST";
export const GET_BOOKING_BY_FILTER_SUCCESS = "GET_BOOKING_BY_FILTER_SUCCESS";
export const GET_BOOKING_BY_FILTER_FAILURE = "GET_BOOKING_BY_FILTER_FAILURE";
