import { useEffect, useState } from "react";
import styles from "./Workshop.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import validator from "validator";
import api from "../../services/api";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addWorkshop } from "../../State/Workshop/Action";
import { getStatesByCountry } from "../../State/State/Action";
import { getCitiesByState } from "../../State/City/Action";
import { getCountries } from "../../State/Country/Action";

export default function Workshop() {
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const countries = useSelector((store) => store.countryResponse.countries);
  const states = useSelector((store) => store.stateResponse.states);
  const cities = useSelector((store) => store.cityResponse.cities);

  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");

  //States for form fields
  const [name, setName] = useState("");
  const [ownerName, setOwnerName] = useState();

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [cityId, setCityId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [shopNumber, setShopNumber] = useState("");
  const [establishedOn, setEstablishedOn] = useState("");

  //Validators state
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidAlternatePhone, setIsValidAlternatePhone] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [workshopId, setWorkshopId] = useState("");
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();

  const [image1Uploaded, setImage1Uploaded] = useState(false);
  const [image2Uploaded, setImage2Uploaded] = useState(false);
  const [image3Uploaded, setImage3Uploaded] = useState(false);
  const [image4Uploaded, setImage4Uploaded] = useState(false);
  const [image5Uploaded, setImage5Uploaded] = useState(false);

  const workshopResponse = useSelector(
    (store) => store.workshopResponse.workshop
  );

  const dispatch = useDispatch();

  const [image1UploadFail, setImage1UploadFail] = useState(false);
  const [image2UploadFail, setImage2UploadFail] = useState(false);
  const [image3UploadFail, setImage3UploadFail] = useState(false);
  const [image4UploadFail, setImage4UploadFail] = useState(false);
  const [image5UploadFail, setImage5UploadFail] = useState(false);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (workshopResponse) {
      onSuccessfulSave();
    } else {
      onFailedSave();
    }
  }, [workshopResponse]);

  const handleImage1 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image1);
    formData.append("imageNumber", "IMAGE1");
    let saved = saveImage(formData);
    if (saved) setImage1Uploaded(true);
    else setImage1UploadFail(true);
  };

  const handleImage2 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image2);
    formData.append("imageNumber", "IMAGE2");
    let saved = saveImage(formData);
    if (saved) setImage2Uploaded(true);
    else setImage2UploadFail(true);
  };

  const handleImage3 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image3);
    formData.append("imageNumber", "IMAGE3");
    let saved = saveImage(formData);
    if (saved) setImage3Uploaded(true);
    else setImage3UploadFail(true);
  };

  const handleImage4 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image4);
    formData.append("imageNumber", "IMAGE4");
    let saved = saveImage(formData);
    if (saved) setImage4Uploaded(true);
    else setImage4UploadFail(true);
  };

  const handleImage5 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image5);
    formData.append("imageNumber", "IMAGE5");
    let saved = saveImage(formData);
    if (saved) setImage5Uploaded(true);
    else setImage5UploadFail(true);
  };

  const saveImage = (params) => {
    if (workshopId) {
      params.append("workshopId", workshopId);
    } else {
      params.append("workshopId", "");
    }

    var saved = true;

    api
      .post("/workshops/images", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setWorkshopId(res.data.workshopId);
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });
    return saved;
  };

  const loadStates = (e, countryId) => {
    setCountryId(countryId);
    dispatch(getStatesByCountry(countryId));
  };

  const loadCities = (e, stateId) => {
    setStateId(stateId);
    dispatch(getCitiesByState(stateId));
  };

  // Email Validation
  const validateEmail = (e) => {
    setEmail(e.target.value);

    if (validator.isEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  // Phone Number Validation
  const validatePhone = (e) => {
    setPhoneNumber(e.target.value);

    if (validator.isMobilePhone(phoneNumber)) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  const validateAlternatePhone = (e) => {
    setAlternatePhoneNumber(e.target.value);

    if (validator.isMobilePhone(alternatePhoneNumber)) {
      setIsValidAlternatePhone(true);
    } else {
      setIsValidAlternatePhone(false);
    }
  };

  const isValidForm = (e) => {
    return (
      workshopId &&
      isValidPhone &&
      isValidAlternatePhone &&
      isValidEmail &&
      (image1Uploaded ||
        image2Uploaded ||
        image3Uploaded ||
        image4Uploaded ||
        image5Uploaded)
    );
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setName("");
    setOwnerName("");
    setEmail("");
    setPhoneNumber("");
    setAlternatePhoneNumber("");
    setStreet("");
    setCityId("");
    setZipCode("");
    setShopNumber("");
    setEstablishedOn("");
    setWorkshopId("");
    setImage1Uploaded(false);
    setImage2Uploaded(false);
    setImage3Uploaded(false);
    setImage4Uploaded(false);
    setImage5Uploaded(false);
    setImage1UploadFail(false);
    setImage2UploadFail(false);
    setImage3UploadFail(false);
    setImage4UploadFail(false);
    setImage5UploadFail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const workshop = {
      workshopId,
      name,
      ownerName,
      shopNumber,
      email,
      phoneNumber,
      alternatePhoneNumber,
      street,
      cityId,
      stateId,
      countryId,
      zipCode,
      establishedOn,
    };

    if (isValidForm()) {
      dispatch(addWorkshop(workshop));
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Add Workshop</header>
      <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg space-y-4">
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          onChange={(e) => setName(e.target.value)}
          label="Enter Workshop/Garage Name"
        />
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          onChange={(e) => setOwnerName(e.target.value)}
          label="Enter Owner Name"
        />
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          type="tel"
          onChange={validatePhone}
          label="Enter Phone Number"
        />
        {!isValidPhone && (
          <p className="text-red-500 text-sm">
            Please enter a valid Phone number
          </p>
        )}
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          type="tel"
          onChange={validateAlternatePhone}
          label="Enter Alternate Phone Number"
        />
        {!isValidAlternatePhone && (
          <p className="text-red-500 text-sm">
            Please enter a valid Alternate Phone number
          </p>
        )}
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          onBlur={(e) => setZipCode(e.target.value)}
          label="Enter Zip Code"
        />
        <TextField
          fullWidth
          variant="outlined"
          className="border-gray-300 rounded-md"
          type="email"
          onChange={validateEmail}
          label="Enter Email Id"
        />
        {!isValidEmail && (
          <p className="text-red-500 text-sm">Please enter a valid Email ID</p>
        )}

        <select
          className="w-full p-4 text-lg border border-gray-300 rounded-xl shadow-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
          onChange={(e) => loadStates(e, e.target.value)}
        >
          <option>Select Country</option>
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.countryName}
            </option>
          ))}
        </select>

        <select
          className="w-full p-4 text-lg border border-gray-300 rounded-xl shadow-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition mt-4"
          onChange={(e) => loadCities(e, e.target.value)}
        >
          <option>Select State</option>
          {states.map((state) => (
            <option key={state.stateId} value={state.stateId}>
              {state.stateName}
            </option>
          ))}
        </select>

        <select
          className="w-full p-4 text-lg border border-gray-300 rounded-xl shadow-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition mt-4"
          onChange={(e) => setCityId(e.target.value)}
        >
          <option>Select City</option>
          {cities.map((city) => (
            <option key={city.cityId} value={city.cityId}>
              {city.cityName}
            </option>
          ))}
        </select>

        <label className="block text-gray-700 text-lg font-semibold mt-6">
          Started On
        </label>
        <input
          type="date"
          className="w-full p-4 text-lg border border-gray-300 rounded-xl shadow-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition mt-2"
        />
      </div>

      {[1, 2, 3, 4, 5].map((num) => (
        <div key={num} className="mb-6">
          <div className="text-lg text-center font-semibold text-gray-700 mb-2">
            Image {num}
          </div>
          <form
            onSubmit={(e) => eval(`handleImage${num}(e)`)}
            encType="multipart/form-data"
            className="flex flex-col items-center gap-4 p-4 border rounded-xl shadow-md bg-white"
          >
            <label className="w-full flex flex-col items-center px-4 py-3 bg-gray-100 text-gray-700 rounded-lg cursor-pointer border border-gray-300 hover:bg-gray-200 transition">
              <input
                type="file"
                className="hidden"
                onChange={(e) => eval(`setImage${num}(e.target.files[0])`)}
              />
              <span className="text-base font-medium">Choose File</span>
            </label>

            <button
              type="submit"
              className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition flex items-center justify-center gap-2"
            >
              <SaveIcon className="w-5 h-5" />
              Upload
            </button>

            {eval(`image${num}Uploaded`) && (
              <p className="text-green-600 font-medium">
                Image uploaded successfully
              </p>
            )}
            {eval(`image${num}UploadFail`) && (
              <p className="text-red-600 font-medium">Image upload failed</p>
            )}
          </form>
        </div>
      ))}

      <div className="flex justify-center">
        <button
          onClick={handleSubmit}
          className="flex items-center gap-2 px-6 py-3 bg-blue-600 text-white font-semibold text-lg rounded-lg shadow-md hover:bg-blue-700 active:bg-blue-800 transition focus:ring-2 focus:ring-blue-400"
        >
          <SaveIcon className="w-6 h-6" />
          Save Workshop
        </button>
      </div>

      <div className={styles.componentContainer}>
        {hasError && (
          <p className={mainStyles.error}>
            Please enter all the mandatory fields with correct value
          </p>
        )}

        {isSaved && (
          <div>
            <p className={mainStyles.success}>Workshop Saved Successfully</p>
          </div>
        )}
      </div>
    </div>
  );
}
