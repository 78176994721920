export const GET_WORKSHOPS_REQUEST = "GET_WORKSHOPS_REQUEST";
export const GET_WORKSHOPS_SUCCESS = "GET_WORKSHOPS_SUCCESS";
export const GET_WORKSHOPS_FAILURE = "GET_WORKSHOPS_FAILURE";

export const GET_WORKSHOP_DETAILS_REQUEST = "GET_WORKSHOP_DETAILS_REQUEST";
export const GET_WORKSHOP_DETAILS_SUCCESS = "GET_WORKSHOP_DETAILS_SUCCESS";
export const GET_WORKSHOP_DETAILS_FAILURE = "GET_WORKSHOP_DETAILS_FAILURE";

export const GET_USED_BIKES_IN_WORKSHOP_REQUEST =
  "GET_USED_BIKES_IN_WORKSHOP_REQUEST";
export const GET_USED_BIKES_IN_WORKSHOP_SUCCESS =
  "GET_USED_BIKES_IN_WORKSHOP_SUCCESS";
export const GET_USED_BIKES_IN_WORKSHOP_FAILURE =
  "GET_USED_BIKES_IN_WORKSHOP_FAILURE";

export const GET_MY_WORKSHOPS_REQUEST = "GET_MY_WORKSHOPS_REQUEST";
export const GET_MY_WORKSHOPS_SUCCESS = "GET_MY_WORKSHOPS_SUCCESS";
export const GET_MY_WORKSHOPS_FAILURE = "GET_MY_WORKSHOPS_FAILURE";

export const ADD_WORKSHOP_REQUEST = "ADD_WORKSHOP_REQUEST";
export const ADD_WORKSHOP_SUCCESS = "ADD_WORKSHOP_SUCCESS";
export const ADD_WORKSHOP_FAILURE = "ADD_WORKSHOP_FAILURE";

export const UPLOAD_WORKSHOP_IMAGE_REQUEST = "UPLOAD_WORKSHOP_IMAGE_REQUEST";
export const UPLOAD_WORKSHOP_IMAGE_SUCCESS = "UPLOAD_WORKSHOP_IMAGE_SUCCESS";
export const UPLOAD_WORKSHOP_IMAGE_FAILURE = "UPLOAD_WORKSHOP_IMAGE_FAILURE";
