import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { FaHeart, FaSearch } from "react-icons/fa";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppPagination from "../Tools/AppPagination";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getBikeNames } from "../../State/NewBike/Action";

export default function UsedBikeList({ modelId = "" }) {
  const [bikes, setBikes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [model, setModel] = useState(modelId);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBike, setSelectedBike] = useState(null);
  const [interestedBikes, setInterestedBikes] = useState(new Set());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bikeNames = useSelector(
    (store) => store.newBikesResponse.bikeNames.bikeModelNames
  );

  useEffect(() => {
    setPage(0); // Reset to first page when model changes
  }, [model]);

  useEffect(() => {
    dispatch(getBikeNames());

    api
      .get(`/usedBikes?model=${model}&page=${page}`)
      .then((res) => {
        setBikes(res.data.usedBikes);
        setTotalPages(res.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, model]);

  const handleViewMore = (e, bikeId) => {
    navigate(`/usedBikeDetail?bikeId=${bikeId}`);
  };

  const handleInterest = (bike) => {
    setInterestedBikes((prev) => new Set(prev).add(bike.bikeId)); // Disable button immediately

    api
      .post("/interested", { bikeId: bike.bikeId })
      .then((res) => {
        if (res.data.success) {
          setOpenDialog(true);
          setBikes((prevBikes) =>
            prevBikes.map((b) =>
              b.bikeId === bike.bikeId ? { ...b, interested: true } : b
            )
          );
        } else {
          alert(res.data.message);
          setInterestedBikes((prev) => {
            const newSet = new Set(prev);
            newSet.delete(bike.bikeId); // Re-enable button if failed
            return newSet;
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting interest:", error);
        alert("Failed to submit interest. Please try again!");
        setInterestedBikes((prev) => {
          const newSet = new Set(prev);
          newSet.delete(bike.bikeId); // Re-enable button if failed
          return newSet;
        });
      });
  };
  return (
    <div className="container mx-auto p-4">
      <div className="text-center py-4 text-2xl font-bold text-primary">
        Used Bikes
      </div>
      {/* Search Bar */}
      <div className="flex justify-center mb-6 w-full">
        <Autocomplete
          onChange={(event, value) => setModel(value?.id || "")}
          sx={{
            width: 300,
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: 3,
          }}
          options={bikeNames}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Bike"
              className="bg-white rounded-lg"
            />
          )}
          className="w-full max-w-lg"
        />
      </div>

      {/* Bike Listings */}
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
        {bikes.map((bike) => (
          <div
            key={bike.bikeId}
            className="bg-white shadow-lg rounded-lg p-4 hover:shadow-xl transition"
          >
            {/* Bike Image */}
            <div className="relative">
              <img
                src={bike.imageUrl}
                alt="Bike"
                className="w-full h-48 object-cover rounded-md cursor-pointer"
                onClick={(e) => handleViewMore(e, bike.bikeId)}
              />
            </div>

            {/* Bike Details */}
            <div className="mt-4 text-center">
              <h2 className="text-lg font-semibold text-gray-900">
                {bike.brand} {bike.model}
              </h2>
              <p className="text-gray-600">{bike.modelYear} Model</p>
              <p className="text-xl font-bold text-green-600 mt-2">
                ₹{bike.price}/-
              </p>
              <div className="flex items-center justify-center gap-1 text-gray-500 mt-2">
                <LocationOnIcon fontSize="small" />
                <span>{bike.sellerCity}</span>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-center gap-4 mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleViewMore(e, bike.bikeId)}
                className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition shadow-md"
              >
                <FaSearch className="text-lg" /> View More
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleInterest(bike)}
                disabled={bike.interested || interestedBikes.has(bike.bikeId)} // Check both interested state & clicked state
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition shadow-md ${
                  bike.interested || interestedBikes.has(bike.bikeId)
                    ? "bg-gray-400 cursor-not-allowed" // Disabled styling
                    : "bg-red-500 hover:bg-red-600 text-white" // Default styling
                }`}
              >
                <FaHeart className="text-lg" /> Interested
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="mt-6 flex justify-center">
        <AppPagination setPage={setPage} totalPages={totalPages} />
      </div>

      {/* Interest Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="xs"
        fullWidth
        sx={{ textAlign: "center" }}
      >
        <DialogTitle>🎉 Interest Submitted Successfully!</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
              alt="Success"
              className="w-16 h-16 mb-3"
            />
            <DialogContentText className="text-gray-700">
              {selectedBike ? (
                <>
                  You have shown interest in{" "}
                  <strong>
                    {selectedBike.brand} {selectedBike.model}
                  </strong>
                  . Our dealer will reach out to you shortly with more details.
                </>
              ) : (
                "Our team or dealer will contact you soon with further details!"
              )}
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions className="flex justify-center">
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
            className="px-6"
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
