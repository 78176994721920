import { useNavigate } from "react-router-dom";

export default function StatusUpdate() {
  const navigate = useNavigate();

  const handleClick = (page) => {
    navigate("/" + page);
  };

  return (
    <div className="flex flex-col items-center gap-6 p-6">
      {/* New Bike Section */}
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">New Bike</h1>
        <div
          className="cursor-pointer bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
          onClick={handleClick.bind(this, "new-bike-display-status-update")}
        >
          New Bike - Display Status
        </div>
      </div>

      {/* Used Bike Section */}
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Used Bike</h1>
        <div
          className="cursor-pointer bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
          onClick={handleClick.bind(this, "used-bike-display-status-update")}
        >
          Used Bike - Display Status
        </div>
      </div>
    </div>
  );
}
