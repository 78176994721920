import api from "../../services/api";
import {
  GET_COLORS_FAILURE,
  GET_COLORS_REQUEST,
  GET_COLORS_SUCCESS,
} from "./ActionType";

const getColorsRequest = () => ({
  type: GET_COLORS_REQUEST,
});

const getColorsSuccess = (colors) => ({
  type: GET_COLORS_SUCCESS,
  payload: colors,
});

const getColorsFailure = (error) => ({
  type: GET_COLORS_FAILURE,
  payload: error,
});

export const getColors = () => async (dispatch) => {
  dispatch(getColorsRequest());
  try {
    const response = await api.get("/public/colors");
    dispatch(getColorsSuccess(response.data));
  } catch (error) {
    dispatch(getColorsFailure(error));
  }
};
