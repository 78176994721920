import api from "../../services/api";
import {
  GET_USED_BIKES_FAILURE,
  GET_USED_BIKES_REQUEST,
  GET_USED_BIKES_SUCCESS,
  GET_USED_BIKES_UNAUTHORIZED_FAILURE,
  GET_USED_BIKES_UNAUTHORIZED_REQUEST,
  GET_USED_BIKES_UNAUTHORIZED_SUCCESS,
  GET_USED_BIKE_DETAILS_FAILURE,
  GET_USED_BIKE_DETAILS_REQUEST,
  GET_USED_BIKE_DETAILS_SUCCESS,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS,
  REGISTER_USED_BIKE_FAILURE,
  REGISTER_USED_BIKE_REQUEST,
  REGISTER_USED_BIKE_SUCCESS,
} from "./ActionType";

const getUsedBikesUnauthorizedRequest = () => ({
  type: GET_USED_BIKES_UNAUTHORIZED_REQUEST,
});

const getUsedBikesUnauthorizedSuccess = (usedBikes) => ({
  type: GET_USED_BIKES_UNAUTHORIZED_SUCCESS,
  payload: usedBikes,
});

const getUsedBikesUnauthorizedFailure = (error) => ({
  type: GET_USED_BIKES_UNAUTHORIZED_FAILURE,
  payload: error,
});

export const getUsedBikesUnauthorized = (page, model = "") => {
  return async (dispatch) => {
    dispatch(getUsedBikesUnauthorizedRequest());
    try {
      const response = await api.get(
        `/public/usedBikes/all?page=${page}&model=${model}`
      );
      dispatch(getUsedBikesUnauthorizedSuccess(response.data));
    } catch (error) {
      dispatch(getUsedBikesUnauthorizedFailure(error));
    }
  };
};

const getUsedBikeDetailsUnauthorizedRequest = () => ({
  type: GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST,
});

const getUsedBikeDetailsUnauthorizedSuccess = (usedBike) => ({
  type: GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS,
  payload: usedBike,
});

const getUsedBikeDetailsUnauthorizedFailure = (error) => ({
  type: GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE,
  payload: error,
});

export const getUsedBikeDetailsUnauthorized = (id) => {
  return async (dispatch) => {
    dispatch(getUsedBikeDetailsUnauthorizedRequest());
    try {
      const response = await api.get(`/public/usedBikes/${id}`);
      dispatch(getUsedBikeDetailsUnauthorizedSuccess(response.data));
    } catch (error) {
      dispatch(getUsedBikeDetailsUnauthorizedFailure(error));
    }
  };
};

const getUsedBikesRequest = () => ({
  type: GET_USED_BIKES_REQUEST,
});

const getUsedBikesSuccess = (usedBikes) => ({
  type: GET_USED_BIKES_SUCCESS,
  payload: usedBikes,
});

const getUsedBikesFailure = (error) => ({
  type: GET_USED_BIKES_FAILURE,
  payload: error,
});

export const getUsedBikes = (page, model) => {
  return async (dispatch) => {
    dispatch(getUsedBikesRequest());
    try {
      const response = await api.get(`/usedBikes?model=${model}&page=${page}`);
      dispatch(getUsedBikesSuccess(response.data));
    } catch (error) {
      dispatch(getUsedBikesFailure(error));
    }
  };
};

const getUsedBikeDetailsRequest = () => ({
  type: GET_USED_BIKE_DETAILS_REQUEST,
});

const getUsedBikeDetailsSuccess = (usedBike) => ({
  type: GET_USED_BIKE_DETAILS_SUCCESS,
  payload: usedBike,
});

const getUsedBikeDetailsFailure = (error) => ({
  type: GET_USED_BIKE_DETAILS_FAILURE,
  payload: error,
});

export const getUsedBikeDetails = (id) => {
  return async (dispatch) => {
    dispatch(getUsedBikeDetailsRequest());
    try {
      const response = await api.get(`/usedBikes/${id}`);
      dispatch(getUsedBikeDetailsSuccess(response.data));
    } catch (error) {
      dispatch(getUsedBikeDetailsFailure(error));
    }
  };
};

const registerUsedBikeRequest = () => {
  return {
    type: REGISTER_USED_BIKE_REQUEST,
  };
};

const registerUsedBikeSuccess = (usedBike) => {
  return {
    type: REGISTER_USED_BIKE_SUCCESS,
    payload: usedBike,
  };
};

const registerUsedBikeFailure = (error) => {
  return {
    type: REGISTER_USED_BIKE_FAILURE,
    payload: error,
  };
};

export const registerUsedBike = (usedBike) => async (dispatch) => {
  dispatch(registerUsedBikeRequest());
  try {
    const response = await api.post("/usedBikes", usedBike);
    dispatch(registerUsedBikeSuccess(response.data));
    window.location.reload(false);
  } catch (error) {
    dispatch(registerUsedBikeFailure(error));
  }
};
