import api from "../../services/api";
import {
  GET_EXCEPTION_LOG_FAILURE,
  GET_EXCEPTION_LOG_REQUEST,
  GET_EXCEPTION_LOG_SUCCESS,
} from "./ActionType";

const getExceptionLogRequest = () => {
  return {
    type: GET_EXCEPTION_LOG_REQUEST,
  };
};

const getExceptionLogSuccess = (data) => {
  return {
    type: GET_EXCEPTION_LOG_SUCCESS,
    payload: data,
  };
};

const getExceptionLogFailure = (error) => {
  return {
    type: GET_EXCEPTION_LOG_FAILURE,
    payload: error,
  };
};

export const getExceptionLog = () => {
  return (dispatch) => {
    dispatch(getExceptionLogRequest());
    api
      .get("/exception-logger")
      .then((response) => {
        dispatch(getExceptionLogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getExceptionLogFailure(error.message));
      });
  };
};
