import {
  GET_CUSTOMER_USED_BIKES_INTEREST_FAILURE,
  GET_CUSTOMER_USED_BIKES_INTEREST_REQUEST,
  GET_CUSTOMER_USED_BIKES_INTEREST_SUCCESS,
} from "./ActionType";

const initialState = {
  interestPaginated: {},
  loading: false,
  error: null,
};

export const interestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_USED_BIKES_INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CUSTOMER_USED_BIKES_INTEREST_SUCCESS:
      return {
        ...state,
        interestPaginated: action.payload,
        loading: false,
        error: null,
      };
    case GET_CUSTOMER_USED_BIKES_INTEREST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
