import {
  DELETE_BIKE_FAILURE,
  DELETE_BIKE_REQUEST,
  DELETE_BIKE_SUCCESS,
  GET_BIKE_DETAIL_FAILURE,
  GET_BIKE_DETAIL_REQUEST,
  GET_BIKE_DETAIL_SUCCESS,
  GET_BIKE_NAMES_FAILURE,
  GET_BIKE_NAMES_REQUEST,
  GET_BIKE_NAMES_SUCCESS,
  GET_NEW_BIKES_FAILURE,
  GET_NEW_BIKES_REQUEST,
  GET_NEW_BIKES_SUCCESS,
  REGISTER_NEW_BIKE_FAILURE,
  REGISTER_NEW_BIKE_REQUEST,
  REGISTER_NEW_BIKE_SUCCESS,
  UPDATE_NEW_BIKE_FAILURE,
  UPDATE_NEW_BIKE_REQUEST,
  UPDATE_NEW_BIKE_SUCCESS,
} from "./ActionType";

const initialState = {
  newBikesPaged: {},
  bikeNames: [],
  bikeDeleted: {},
  bikeUpdated: {},
  bike: {},
  loading: false,
  error: null,
};

export const newBikeReducer = (state = initialState, action) => {
  switch (action.type) {
    case [
      GET_NEW_BIKES_REQUEST,
      GET_BIKE_NAMES_REQUEST,
      DELETE_BIKE_REQUEST,
      GET_BIKE_DETAIL_REQUEST,
      UPDATE_NEW_BIKE_REQUEST,
      REGISTER_NEW_BIKE_REQUEST,
    ]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_NEW_BIKES_SUCCESS:
      return {
        ...state,
        newBikesPaged: action.payload,
        loading: false,
        error: null,
      };
    case [
      GET_NEW_BIKES_FAILURE,
      GET_BIKE_NAMES_FAILURE,
      DELETE_BIKE_FAILURE,
      GET_BIKE_DETAIL_FAILURE,
      UPDATE_NEW_BIKE_FAILURE,
      REGISTER_NEW_BIKE_FAILURE,
    ]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BIKE_NAMES_SUCCESS:
      return {
        ...state,
        bikeNames: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_BIKE_SUCCESS:
      return {
        ...state,
        bikeDeleted: action.payload,
        loading: false,
        error: null,
      };
    case GET_BIKE_DETAIL_SUCCESS:
      return {
        ...state,
        bike: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_NEW_BIKE_SUCCESS:
      return {
        ...state,
        bikeUpdated: action.payload,
        loading: false,
        error: null,
      };
    case REGISTER_NEW_BIKE_SUCCESS:
      return {
        ...state,
        bike: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
