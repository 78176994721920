import { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import styles from "./NewBike.module.css";
import mainStyles from "../Main.module.css";

import React from "react";

import MainCarousel from "../MainCarousel/MainCarousel";

import { useLocation } from "react-router-dom";
import api from "../../services/api";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
    width: 95%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    border-radius: 12px;
    opacity: 0.6;
    `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 375px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
    `
);

export default function NewBikeDetailShort() {
  const [newBike, setNewBike] = useState();
  const [images, setImages] = useState([]);
  const location = useLocation();
  const bikeId = new URLSearchParams(location.search).get("bikeId");

  useEffect(() => {
    api.get("/newBikes/" + bikeId).then((res) => {
      setNewBike(res.data);
      setImages(res.data.images);
    });
  }, [bikeId]);

  return (
    <div>
      {newBike && (
        <header className={mainStyles.header}>
          {newBike.brand} - {newBike.model}
        </header>
      )}

      <div className="slider">
        {newBike && <MainCarousel imageList={images} />}
      </div>
      {newBike && (
        <div className={mainStyles.headerPrice}>
          ₹{newBike.exShowroomPrice} /-
        </div>
      )}
      <div className={styles.bikeDetail}>
        {newBike && (
          <Tabs defaultValue={0}>
            <TabsList>
              <Tab value={0}>Primary details</Tab>
              <Tab value={1}>Bike Specification</Tab>
              <Tab value={2}>Servicing</Tab>
              {newBike.fuelType === "ELECTRIC" && (
                <Tab value={3}>Battery Info</Tab>
              )}
            </TabsList>
            <TabPanel value={0}>
              <div>
                {" "}
                {newBike.fuelType === "ELECTRIC" && (
                  <div>
                    <div className={mainStyles.fieldContianer}>
                      <div className={styles.fieldName}>Range </div>
                      <div className={styles.fieldValue}>
                        {newBike.rangeInKm} kms
                      </div>
                    </div>

                    <div className={mainStyles.fieldContianer}>
                      <div className={styles.fieldName}>Top Speed </div>
                      <div className={styles.fieldValue}>
                        {" "}
                        {newBike.topSpeedInKmph} Km/Hr
                      </div>
                    </div>

                    <div className={mainStyles.fieldContianer}>
                      <div className={styles.fieldName}>Fuel Type </div>
                      <div className={styles.fieldValue}> Electric</div>
                    </div>
                  </div>
                )}
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Brand </div>
                  <div className={styles.fieldValue}>{newBike.brand}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Model </div>
                  <div className={styles.fieldValue}> {newBike.model}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Color </div>
                  <div className={styles.fieldValue}>{newBike.color}</div>
                </div>
                {newBike.mileage && (
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Mileage </div>
                    <div className={styles.fieldValue}>{newBike.mileage}</div>
                  </div>
                )}
                <div className={mainStyles.fieldContianer}>
                  <p>
                    About {newBike.brand} - {newBike.model}
                  </p>
                  <div className={mainStyles.summary}>{newBike.summary}</div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={1}>
              <div className="leftDiv">
                {" "}
                {newBike.engineCapacity && (
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Engine Capacity </div>
                    <div className={styles.fieldValue}>
                      {newBike.engineCapacity}
                    </div>
                  </div>
                )}
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Ex Showroom Price </div>
                  <div className={styles.fieldValue}>
                    {" "}
                    {newBike.exShowroomPrice}
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Variant </div>
                  <div className={styles.fieldValue}>{newBike.variant}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Height </div>
                  <div className={styles.fieldValue}>{newBike.height}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Length </div>
                  <div className={styles.fieldValue}>{newBike.length}</div>
                </div>
              </div>
              {newBike.breakType && (
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Break</div>
                  <div className={styles.fieldValue}>{newBike.breakType}</div>
                </div>
              )}
              {newBike.headLight && (
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Head Light</div>
                  <div className={styles.fieldValue}>{newBike.headLight}</div>
                </div>
              )}
              {newBike.tailLight && (
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Tail Light</div>
                  <div className={styles.fieldValue}>{newBike.tailLight}</div>
                </div>
              )}
              {newBike.kerbWeightInKg && (
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Kerb weight</div>
                  <div className={styles.fieldValue}>
                    {newBike.kerbWeightInKg} KG
                  </div>
                </div>
              )}
              <div className="rightDiv">
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Power </div>
                  <div className={styles.fieldValue}>{newBike.power}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Torque </div>
                  <div className={styles.fieldValue}>{newBike.torque}</div>
                </div>
                {newBike.fuelCapacity && (
                  <div className={mainStyles.fieldContianer}>
                    <div className={styles.fieldName}>Fuel Capacity </div>
                    <div className={styles.fieldValue}>
                      {newBike.fuelCapacity}
                    </div>
                  </div>
                )}
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Launch Date </div>
                  <div className={styles.fieldValue}>{newBike.launchDate}</div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>ABS </div>
                  <div className={styles.fieldValue}>{newBike.abs}</div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={2}>
              <div>
                {" "}
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Service 1 </div>
                  <div className={styles.fieldValue}>
                    {newBike.service1} kms
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Service 2 </div>
                  <div className={styles.fieldValue}>
                    {" "}
                    {newBike.service2} kms
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Service 3 </div>
                  <div className={styles.fieldValue}>
                    {newBike.service3} kms
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Service 4 </div>
                  <div className={styles.fieldValue}>
                    {newBike.service4} kms
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={3}>
              <div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Charging Time </div>
                  <div className={styles.fieldValue}>
                    {newBike.chargingTimeInHours} Hours
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Max Power </div>
                  <div className={styles.fieldValue}>
                    {newBike.maxPowerInKw} KW
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Fast Charging? </div>
                  <div className={styles.fieldValue}>
                    {newBike.fastCharging}
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Battery </div>
                  <div className={styles.fieldValue}>
                    {newBike.batteryCapacityInKwh} KW
                  </div>
                </div>
                <div className={mainStyles.fieldContianer}>
                  <div className={styles.fieldName}>Battery Warranty</div>
                  <div className={styles.fieldValue}>
                    {newBike.batteryWarranty} KW
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        )}
      </div>
    </div>
  );
}
