import api from "../../services/api";
import {
  GET_ESTIMATED_PRICE_FAILURE,
  GET_ESTIMATED_PRICE_REQUEST,
  GET_ESTIMATED_PRICE_SUCCESS,
  UPDATE_ESTIMATED_PRICE_FAILURE,
  UPDATE_ESTIMATED_PRICE_REQUEST,
  UPDATE_ESTIMATED_PRICE_SUCCESS,
} from "./ActionType";

const getEstimatedPriceRequest = () => ({
  type: GET_ESTIMATED_PRICE_REQUEST,
});

const getEstimatedPriceSuccess = (estimatedPrice) => ({
  type: GET_ESTIMATED_PRICE_SUCCESS,
  payload: estimatedPrice,
});

const getEstimatedPriceFailure = (error) => ({
  type: GET_ESTIMATED_PRICE_FAILURE,
  payload: error,
});

export const getEstimatedPriceList = (cityId) => async (dispatch) => {
  dispatch(getEstimatedPriceRequest());
  try {
    const response = await api.get(`/price/estimated-price?cityId=` + cityId, {
      withCredentials: true,
    });
    dispatch(getEstimatedPriceSuccess(response.data));
  } catch (error) {
    dispatch(getEstimatedPriceFailure(error));
  }
};

const updateEstimatedPriceRequest = () => ({
  type: UPDATE_ESTIMATED_PRICE_REQUEST,
});

const updateEstimatedPriceSuccess = (estimatedPrice) => ({
  type: UPDATE_ESTIMATED_PRICE_SUCCESS,
  payload: estimatedPrice,
});

const updateEstimatedPriceFailure = (error) => ({
  type: UPDATE_ESTIMATED_PRICE_FAILURE,
  payload: error,
});

export const updateEstimatedPrice = (request) => async (dispatch) => {
  dispatch(updateEstimatedPriceRequest());
  try {
    const response = await api.put(
      `/price/estimated-price?estimatedPriceId=` + request.id,
      request,
      {
        withCredentials: true,
      }
    );
    dispatch(updateEstimatedPriceSuccess(response.data));
  } catch (error) {
    dispatch(updateEstimatedPriceFailure(error));
  }
};
