import {
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  GET_ALL_BOOKINGS_SUCCESS_BY_USER_ID,
  GET_ALL_BOOKINGS_FAILURE_BY_USER_ID,
  GET_ALL_BOOKINGS_REQUEST_BY_USER_ID,
  GET_ALL_BOOKINGS_REQUEST,
  GET_ALL_BOOKINGS_FAILURE,
  GET_ALL_BOOKINGS_SUCCESS,
  UPDATE_BOOKING_FAILURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  GET_ESTIMATED_PRICE_REQUEST,
  GET_ESTIMATED_PRICE_SUCCESS,
  GET_ESTIMATED_PRICE_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_SUCCESS,
  GET_BOOKING_BY_ID_REQUEST,
  GET_BOOKING_BY_ID_FAILURE,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_FILTER_FAILURE,
  GET_BOOKING_BY_FILTER_REQUEST,
  GET_BOOKING_BY_FILTER_SUCCESS,
} from "./ActionType";

// Initial state for booking
const initialState = {
  booking: {},
  bookings: [],
  pagedBooking: {},
  updated: {},
  cancelled: {},
  estimatedCost: {},
  loading: false,
  error: null,
};

// Reducer for booking
export const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case [
      CREATE_BOOKING_REQUEST,
      GET_ALL_BOOKINGS_REQUEST_BY_USER_ID,
      GET_ALL_BOOKINGS_REQUEST,
      UPDATE_BOOKING_REQUEST,
      CANCEL_BOOKING_REQUEST,
      GET_BOOKING_BY_ID_REQUEST,
      GET_BOOKING_BY_FILTER_REQUEST,
    ]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        booking: action.payload,
        loading: false,
        error: null,
      };
    case [
      CREATE_BOOKING_FAILURE,
      GET_ALL_BOOKINGS_FAILURE_BY_USER_ID,
      GET_ALL_BOOKINGS_FAILURE,
      UPDATE_BOOKING_FAILURE,
      CANCEL_BOOKING_FAILURE,
      GET_BOOKING_BY_ID_FAILURE,
      GET_BOOKING_BY_FILTER_FAILURE,
    ]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ALL_BOOKINGS_SUCCESS_BY_USER_ID:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
        error: null,
      };
    case GET_ALL_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        updated: action.payload,
        loading: false,
        error: null,
      };
    case GET_ESTIMATED_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ESTIMATED_PRICE_SUCCESS:
      return {
        ...state,
        estimatedCost: action.payload,
        loading: false,
        error: null,
      };
    case GET_ESTIMATED_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelled: action.payload,
        loading: false,
        error: null,
      };
    case GET_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: false,
        error: null,
      };
    case GET_BOOKING_BY_FILTER_SUCCESS:
      return {
        ...state,
        pagedBooking: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
