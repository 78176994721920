import { useEffect, useState } from "react";
import styles from "./Workshop.module.css";
import api from "../../services/api";
import workshopsImage from "./images/workshops.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarSharp from "@mui/icons-material/StarSharp";
import mainStyles from "../Main.module.css";
import AppPagination from "../Tools/AppPagination";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMyWorkshops } from "../../State/Workshop/Action";
import { useMyContext } from "../../store/ContextApi";

export default function DealerWorkshopList() {
  const [showWorkshopDetail, setShowWorkshopDetail] = useState(false);
  const [showWorkshopContainer, setShowWorkshopContainer] = useState(false);
  const [showWorkshopList, setShowWorkshopList] = useState(true);
  const [workshopId, setWorkshopId] = useState("");
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { isAdmin, isDealer } = useMyContext();
  let role = localStorage.getItem("role");
  const dispatch = useDispatch();

  const workshops = useSelector(
    (store) => store.workshopResponse.workshopPaginated.workshops
  );
  const totalPages = useSelector(
    (store) => store.workshopResponse.workshopPaginated.totalPages
  );

  useEffect(() => {
    dispatch(getMyWorkshops(page));
  }, [page]);

  const handleViewMore = (e, workshop) => {
    navigate("/workshopDetail?workshopId=" + workshop);
  };

  const handleUpdate = (e, workshop) => {
    navigate("/update-workshop?workshopId=" + workshop);
  };

  return (
    <div>
      <div>
        <div className={styles.headingImageDiv}>
          <img src={workshopsImage} alt="Bikes" className={styles.bigImage} />
        </div>

        <div className={styles.workshopListcontainer}>
          <header className={mainStyles.header}>Workshops</header>
          {workshops?.map((workshop) => (
            <div className={styles.propContainer}>
              <div className={styles.workshopDetailContainer}>
                <div className={styles.workshopImageDiv}>
                  <img
                    onClick={(e) => handleViewMore(e, workshop.workshopId)}
                    className={styles.workshopImage}
                    src={workshop.imageUrl}
                    alt="workshopImage"
                  />
                </div>
                <div className={styles.workshopDetailDiv}>
                  <div className={styles.workshopDetail}>
                    <div className={styles.name}>{workshop.name}</div>
                    <div className={styles.starContainer}>
                      {[...Array(workshop.rating)].map((item, i) => (
                        <div className={styles.starActive} key={i}>
                          <StarSharp />
                        </div>
                      ))}
                    </div>
                    <div className={styles.yearsOld}>
                      {workshop.yearsOld} year(s) old
                    </div>

                    <div>
                      <div className={styles.locationIcon}>
                        <LocationOnIcon fontSize="medium" /> {workshop.address},{" "}
                        {workshop.city}
                      </div>
                    </div>

                    <Button
                      onClick={(e) => handleViewMore(e, workshop.workshopId)}
                      className={mainStyles.button}
                    >
                      View
                    </Button>
                    {isDealer && (
                      <>
                        {" "}
                        <Button
                          onClick={(e) => handleUpdate(e, workshop.workshopId)}
                          className={`${mainStyles.button} ${mainStyles.delete}`}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <AppPagination totalPages={totalPages} setPage={setPage} />
        </div>
      </div>
    </div>
  );
}
