export const ADD_EXPENSE_REQUEST = "ADD_EXPENSE_REQUEST";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_FAILURE = "ADD_EXPENSE_FAILURE";

export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST";
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS";
export const GET_EXPENSE_FAILURE = "GET_EXPENSE_FAILURE";

export const GET_EXPENSE_SUMMARY_REQUEST = "GET_EXPENSE_SUMMARY_REQUEST";
export const GET_EXPENSE_SUMMARY_SUCCESS = "GET_EXPENSE_SUMMARY_SUCCESS";
export const GET_EXPENSE_SUMMARY_FAILURE = "GET_EXPENSE_SUMMARY_FAILURE";
