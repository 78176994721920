import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BWDateTimePicker from "../Tools/BWDateTime";
import { useDispatch } from "react-redux";
import { updateBooking } from "../../State/Booking/Action";
import moment from "moment";
import DateTimeDropDown from "../Tools/DateTimeDropDown";

export default function UpdateBookingDialog({
  bookingId,
  vehicle,
  washType,
  bookingAt,
  address,
  setUpdated,
}) {
  const [open, setOpen] = React.useState(false);
  const [scheduledAt, setScheduledAt] = React.useState(
    moment(new Date(bookingAt)).format("yyyy-MM-DD HH:mm:ss.SSS")
  );
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const formData = new FormData(event.target);
    const type = formData.get("washType") + "_" + vehicle + "_WASH";
    const booking = {
      address: formData.get("address"),
      scheduledDate: `${scheduledAt}`,
      washType: type,
    };

    console.log(booking);
    const inputRequest = {
      id: bookingId,
      booking,
      setSuccessMessage,
      setErrorMessage,
      setUpdated,
    };
    dispatch(updateBooking(inputRequest));
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="text-center font-bold text-green-700">
          Update Booking
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div className="mt-2 mb-2">
                    <span>
                      Vehicle: <strong>{vehicle}</strong>
                    </span>
                  </div>
                  <div className="mt-2 mb-2">
                    <InputLabel id="wash-label">Wash type</InputLabel>
                    <Select
                      labelId="wash-select-label"
                      id="wash-select"
                      //   value={age}
                      label="WashType"
                      defaultValue={washType}
                      name="washType"
                    >
                      <MenuItem value="PREMIUM">PREMIUM</MenuItem>
                      <MenuItem value="STANDARD">STANDARD</MenuItem>
                      {vehicle === "CAR" && (
                        <MenuItem value="BASIC">BASIC</MenuItem>
                      )}
                      {vehicle === "CAR" && (
                        <MenuItem value="MONTHLYBASIC">MONTHLY BASIC</MenuItem>
                      )}
                      {vehicle === "CAR" && (
                        <MenuItem value="MONTHLYSTANDARD">
                          MONTHLY STANDARD
                        </MenuItem>
                      )}
                      {/* {vehicle === "CAR" && (
                        <MenuItem value="MONTHLYULTRA">MONTHLY ULTRA</MenuItem>
                      )} */}
                      {vehicle === "CAR" && (
                        <MenuItem value="MONTHLYPREMIUM">
                          MONTHLY PREMIUM
                        </MenuItem>
                      )}
                    </Select>
                  </div>

                  <div className="mt-2 mb-2">
                    <DateTimeDropDown setDateTime={setScheduledAt} delay={0} />
                  </div>
                  <div className="mt-2 mb-2">
                    {" "}
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      id="address"
                      name="address"
                      label="Address"
                      type="text"
                      fullWidth
                      variant="standard"
                      defaultValue={address}
                    />
                  </div>
                </div>
              </div>
              <Button type="submit">Update</Button>
              <Button onClick={handleClose}>Cancel</Button>
              <div>
                {successMessage.length > 0 && (
                  <span className="text-green-700">{successMessage}</span>
                )}
              </div>
              <div>
                {errorMessage.length > 0 && (
                  <span className="text-red-700">{errorMessage}</span>
                )}
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
