import {
  GET_USED_BIKES_FAILURE,
  GET_USED_BIKES_REQUEST,
  GET_USED_BIKES_SUCCESS,
  GET_USED_BIKES_UNAUTHORIZED_FAILURE,
  GET_USED_BIKES_UNAUTHORIZED_REQUEST,
  GET_USED_BIKES_UNAUTHORIZED_SUCCESS,
  GET_USED_BIKE_DETAILS_FAILURE,
  GET_USED_BIKE_DETAILS_REQUEST,
  GET_USED_BIKE_DETAILS_SUCCESS,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST,
  GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS,
  REGISTER_USED_BIKE_FAILURE,
  REGISTER_USED_BIKE_REQUEST,
  REGISTER_USED_BIKE_SUCCESS,
} from "./ActionType";

const initialState = {
  usedBikesPaged: {},
  bike: {},
  loading: false,
  error: null,
};

export const usedBikeReducer = (state = initialState, action) => {
  switch (action.type) {
    case [
      GET_USED_BIKES_UNAUTHORIZED_REQUEST,
      GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST,
      GET_USED_BIKES_REQUEST,
      GET_USED_BIKE_DETAILS_REQUEST,
      REGISTER_USED_BIKE_REQUEST,
    ]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_USED_BIKES_UNAUTHORIZED_SUCCESS:
      return {
        ...state,
        usedBikesPaged: action.payload,
        loading: false,
        error: null,
      };
    case GET_USED_BIKES_SUCCESS:
      return {
        ...state,
        usedBikesPaged: action.payload,
        loading: false,
        error: null,
      };
    case [
      GET_USED_BIKES_UNAUTHORIZED_FAILURE,
      GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE,
      GET_USED_BIKES_FAILURE,
      GET_USED_BIKE_DETAILS_FAILURE,
      REGISTER_USED_BIKE_FAILURE,
    ]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case [GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS]:
      return {
        ...state,
        bike: action.payload,
        loading: false,
        error: null,
      };

    case GET_USED_BIKE_DETAILS_SUCCESS:
      return {
        ...state,
        bike: action.payload,
        loading: false,
        error: null,
      };
    case REGISTER_USED_BIKE_SUCCESS:
      return {
        ...state,
        bike: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
