import { useEffect, useState } from "react";
import styles from "./NewBike.module.css";
import mainStyles from "../Main.module.css";
import bikesImage from "./images/bikes.png";
import AppPagination from "../Tools/AppPagination";
import { FaCheck, FaTimes, FaTrash } from "react-icons/fa";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import api from "../../services/api";
import { BIKE_DISPLAY_STATUS } from "../Constant/Constant";
import { StyleSharp } from "@mui/icons-material";

export default function NewBikeDisplayStatusUpdate() {
  const [bikes, setBikes] = useState([]);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bikeNames, setBikeNames] = useState([]);
  // const [model, setModel] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("PENDING");

  const openConfirmationDialogBox = (e, bikeId) => {
    setBikeId(bikeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let role = localStorage.getItem("role");

  useEffect(() => {
    api.get("/bikeModels/names").then((res) => {
      setBikeNames(res.data.bikeModelNames);
    });
    loadBikes();
  }, [page, status]);

  const loadBikes = () => {
    api.get("/newBikes/status/" + status + "?page=" + page).then((res) => {
      setBikes(res.data.bikes);
      setTotalPages(res.data.totalPages);
    });
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleDelete = () => {
    api
      .put("/newBikes/" + bikeId + "/delete")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });

    setOpen(false);
  };

  const handleApprove = (e, bikeId) => {
    api
      .put("/newBikes/" + bikeId + "/approve")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = (e, bikeId) => {
    api
      .put("/newBikes/" + bikeId + "/reject")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="py-8">
      {/* Heading Section */}
      <div className="max-w-6xl mx-auto">
        <h2 className="text-2xl font-bold text-gray-900 mb-4 text-center">
          New Bikes
        </h2>

        {/* Status Filter Dropdown */}
        <div className="flex justify-center mb-6">
          <Box sx={{ width: "100%", maxWidth: "300px" }}>
            <FormControl fullWidth>
              <InputLabel id="brandSelect">Status</InputLabel>
              <Select
                labelId="statusSelect"
                id="statusSelect"
                name="status"
                className="bg-white"
                defaultValue={status}
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                {BIKE_DISPLAY_STATUS.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        {/* Bike List Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {bikes.map((bike) => (
            <div
              key={bike.bikeId}
              className="relative bg-white rounded-lg shadow-lg p-4 transition transform hover:scale-105"
            >
              {/* Status Badge */}
              <span
                className={`absolute top-2 right-2 px-3 py-1 text-xs font-semibold rounded-full ${
                  bike.displayStatus === "PENDING"
                    ? "bg-yellow-100 text-yellow-800"
                    : bike.displayStatus === "APPROVED"
                    ? "bg-green-100 text-green-800"
                    : bike.displayStatus === "REJECTED"
                    ? "bg-red-100 text-red-800"
                    : "bg-gray-300 text-gray-800"
                }`}
              >
                {bike.displayStatus}
              </span>

              {/* Bike Image */}
              <div className="relative">
                <img
                  src={bike.imageUrl}
                  alt="Bike"
                  className="w-full h-48 object-cover rounded-md"
                />
              </div>

              {/* Bike Details */}
              <div className="mt-4">
                <h2 className="text-lg font-semibold text-gray-900">
                  {bike.brand} {bike.model}
                </h2>
                <p className="text-gray-600">
                  Engine: {bike.engineCapacity} CC
                </p>
                <p className="text-xl font-bold text-green-600 mt-2">
                  ₹{bike.exShowroomPrice}/-
                </p>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-wrap gap-2 mt-4">
                {bike.displayStatus !== "DELETED" &&
                  bike.displayStatus !== "APPROVED" && (
                    <Button
                      onClick={(e) => handleApprove(e, bike.bikeId)}
                      className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition shadow-md"
                    >
                      <FaCheck className="text-lg" /> Approve
                    </Button>
                  )}
                {bike.displayStatus !== "DELETED" &&
                  bike.displayStatus !== "REJECTED" && (
                    <Button
                      onClick={(e) => handleReject(e, bike.bikeId)}
                      className="flex items-center gap-2 bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition shadow-md"
                    >
                      <FaTimes className="text-lg" /> Reject
                    </Button>
                  )}
                {bike.displayStatus !== "DELETED" && (
                  <Button
                    onClick={(e) => openConfirmationDialogBox(e, bike.bikeId)}
                    className="flex items-center gap-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition shadow-md"
                  >
                    <FaTrash className="text-lg" /> Delete
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Delete Confirmation Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"Delete Bike?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this bike?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              className="bg-red-500 text-white"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* Pagination */}
      <div className="mt-6 flex justify-center">
        <AppPagination setPage={setPage} totalPages={totalPages} />
      </div>
    </div>
  );
}
