import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Typography,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SellIcon from "@mui/icons-material/Sell";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HomeIcon from "@mui/icons-material/Home";

const Footer = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleNavigation = (event, newValue) => {
    setValue(newValue);
    const routes = [
      "/home",
      "/used-car-list-router",
      "/new-car-list-router",
      "/sell-car",
      "/contact",
    ];
    navigate(routes[newValue]);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#90caf9",
        boxShadow: 3,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigation}
        sx={{ backgroundColor: "#bbdefb", borderRadius: 2, padding: 1 }}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Used" icon={<AirportShuttleIcon />} />
        <BottomNavigationAction label="New Cars" icon={<DirectionsCarIcon />} />
        <BottomNavigationAction label="Sell Car" icon={<SellIcon />} />
        <BottomNavigationAction label="Support" icon={<SupportAgentIcon />} />
      </BottomNavigation>
      <Typography
        variant="body2"
        align="center"
        sx={{ padding: 1, backgroundColor: "#64b5f6", color: "white" }}
      >
        &copy; {new Date().getFullYear()}{" "}
        <a
          href="https://www.tricles.com"
          style={{ color: "white", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Tricles.com
        </a>
        . All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
