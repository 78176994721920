import { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import styles from "./UsedBike.module.css";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import mainStyles from "../Main.module.css";
import loadingImage from "../loading.gif";
import validator from "validator";
import api from "../../services/api";
import { useMyContext } from "../../store/ContextApi";
import { useSelect } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import { getColors } from "../../State/Color/Action";
import { getCities } from "../../State/City/Action";
import { getMyWorkshops, getWorkshops } from "../../State/Workshop/Action";
import { getBrands } from "../../State/Brand/Action";
import { getModelsByBrand } from "../../State/Model/Action";
import { registerUsedBike } from "../../State/UsedBike/Action";

export default function UsedBike() {
  const colors = useSelector((store) => store.colorResponse.colors);
  const brands = useSelector((store) => store.brandResponse.brands);
  const cities = useSelector((store) => store.cityResponse.cities);
  const models = useSelector((store) => store.modelResponse.models);
  const workshops = useSelector(
    (store) => store.workshopResponse.workshopPaginated.workshops
  );

  const bike = useSelector((store) => store.usedBikesResponse.bike);
  const [bikeModelId, setBikeModelId] = useState();
  const [purchaseYear, setPurchaseYear] = useState();
  const [brandId, setBrandId] = useState();
  const [color, setColor] = useState();
  const [runningKms, setRunningKms] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerMobile, setSellerMobile] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [sellerCityId, setSellerCityId] = useState();
  const [price, setPrice] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [description, setDescription] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [bikeId, setBikeId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [frontImageLoading, setFrontImageLoading] = useState(false);
  const [topImageLoading, setTopImageLoading] = useState(false);
  const [leftImageLoading, setLeftImageLoading] = useState(false);
  const [rightImageLoading, setRightImageLoading] = useState(false);
  const [backImageLoading, setBackImageLoading] = useState(false);
  const [workshopId, setWorkshopId] = useState();
  const { isDealer } = useMyContext();
  const dispatch = useDispatch();

  const fetchInitialData = async () => {
    dispatch(getColors());
    dispatch(getBrands());
    dispatch(getCities());
    dispatch(getMyWorkshops(0));
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (bike) {
      onSuccessfulSave();
      resetForm();
    }
  }, [bike]);

  const loadModels = (e, brand) => {
    setBrandId(brand);
    dispatch(getModelsByBrand(brand));
  };

  const handleSubmitTop = (e) => {
    e.preventDefault();
    setTopImageLoading(true);
    setTopViewImageUploaded(false);
    setTopViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    saveImage(formData);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    setFrontImageLoading(true);
    setFrontViewImageUploaded(false);
    setFrontViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    saveImage(formData);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();
    setLeftImageLoading(true);

    setLeftViewImageUploaded(false);
    setLeftViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    saveImage(formData);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();
    setRightImageLoading(true);

    setRightViewImageUploaded(false);
    setRightViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    saveImage(formData);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();
    setBackImageLoading(true);

    setBackViewImageUploaded(false);
    setBackViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    saveImage(formData);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;

    api
      .post("/usedBikes/images/", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setBikeId(res.data.bikeId);
        updateImage(res.data);
        setImageLoadingToFalse();
        setImageUploadedToTrue(params.get("imageAngle"));
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
        setImageLoadingToFalse();
        setImageUploadedToFalse(params.get("imageAngle"));
      });
    return saved;
  };

  const setImageUploadedToTrue = (angle) => {
    if (angle === "TOP") {
      setTopViewImageUploaded(true);
    } else if (angle === "FRONT") {
      setFrontViewImageUploaded(true);
    } else if (angle === "LEFT") {
      setLeftViewImageUploaded(true);
    } else if (angle === "RIGHT") {
      setRightViewImageUploaded(true);
    } else if (angle === "BACK") {
      setBackViewImageUploaded(true);
    }
  };

  const setImageUploadedToFalse = (angle) => {
    if (angle === "TOP") {
      setTopViewImageUploadFail(true);
    } else if (angle === "FRONT") {
      setFrontViewImageUploadFail(true);
    } else if (angle === "LEFT") {
      setLeftViewImageUploadFail(true);
    } else if (angle === "RIGHT") {
      setRightViewImageUploadFail(true);
    } else if (angle === "BACK") {
      setBackViewImageUploadFail(true);
    }
  };

  const setImageLoadingToFalse = () => {
    setFrontImageLoading(false);
    setTopImageLoading(false);
    setLeftImageLoading(false);
    setRightImageLoading(false);
    setBackImageLoading(false);
  };

  const updateImage = (res) => {
    if (res.imageAngle === "TOP") {
      setTopViewImage(res.imageUrl);
    } else if (res.imageAngle === "FRONT") {
      setFrontViewImage(res.imageUrl);
    } else if (res.imageAngle === "LEFT") {
      setLeftViewImage(res.imageUrl);
    } else if (res.imageAngle === "RIGHT") {
      setRightViewImage(res.imageUrl);
    } else if (res.imageAngle === "BACK") {
      setBackViewImage(res.imageUrl);
    }
  };

  const years = [
    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
    2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
    2024,
  ];

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    setSellerMobile(number);
    setIsValidPhoneNumber(isValidPhoneNumber);
  };

  const isValidForm = () => {
    setErrorMessage();
    if (bikeId === undefined || bikeId === "") {
      setErrorMessage("Bike Front image is required");
      return false;
    } else if (brandId === undefined || brandId === "") {
      setErrorMessage("Brand is required");
      return false;
    } else if (bikeModelId === undefined || bikeModelId === "") {
      setErrorMessage("Bike Model is required");
      return false;
    } else if (color === undefined || color === "") {
      setErrorMessage("Color is required");
      return false;
    } else if (purchaseYear === undefined || purchaseYear === "") {
      setErrorMessage("Purchase Year is required");
      return false;
    } else if (sellerName === undefined || sellerName === "") {
      setErrorMessage("Seller Name is required");
      return false;
    } else if (sellerMobile === undefined || sellerMobile === "") {
      setErrorMessage("Seller Mobile is required");
      return false;
    } else if (sellerAddress === undefined || sellerAddress === "") {
      setErrorMessage("Seller Address is required");
      return false;
    } else if (sellerCityId === undefined || sellerCityId === "") {
      setErrorMessage("Seller City is required");
      return false;
    } else if (price === undefined || price === "") {
      setErrorMessage("Price is required");
      return false;
    } else if (!frontViewImageUploaded) {
      setErrorMessage("Front View Image is required");
      return false;
    } else if (!isValidPhoneNumber) {
      setErrorMessage("Phone number is invalid");
      return false;
    }
    return true;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBikeId("");
    setBikeModelId();
    setBrandId();
    setColor();
    setPurchaseYear();
    setRunningKms();
    setSellerName();
    setSellerMobile();
    setSellerAddress();
    setSellerCityId();
    setPrice();
    setRegistrationNumber();
    setDescription();
    setTopViewImage();
    setLeftViewImage();
    setRightViewImage();
    setFrontViewImage();
    setBackViewImage();
    setTopViewImageUploaded(false);
    setLeftViewImageUploaded(false);
    setRightViewImageUploaded(false);
    setFrontViewImageUploaded(false);
    setBackViewImageUploaded(false);
    setTopViewImageUploadFail(false);
    setLeftViewImageUploadFail(false);
    setRightViewImageUploadFail(false);
    setFrontViewImageUploadFail(false);
    setBackViewImageUploadFail(false);
    setIsValidPhoneNumber(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var customerId = localStorage.getItem("userId");
    const usedBike = {
      customerId,
      brandId,
      bikeModelId,
      color,
      description,
      purchaseYear,
      runningKms,
      sellerName,
      sellerMobile,
      sellerAddress,
      sellerCityId,
      price,
      registrationNumber,
      bikeId,
      workshopId,
    };

    if (isValidForm()) {
      dispatch(registerUsedBike(usedBike));
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className="text-2xl font-bold text-white text-center bg-gradient-to-r from-blue-500 to-blue-700 py-4 shadow-lg rounded-md">
        Used Bike Registration
      </header>

      <div className="space-y-4 p-6 bg-white shadow-lg rounded-lg">
        {isDealer && (
          <div>
            <label className="block text-gray-700 font-semibold">
              Select Workshop
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setWorkshopId(e.target.value)}
            >
              <option> Select Workshop </option>
              {workshops?.map((workshop) => (
                <option key={workshop.workshopId} value={workshop.workshopId}>
                  {workshop.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TextField
            className="w-full"
            variant="outlined"
            onChange={(e) => setSellerName(e.target.value)}
            label="Enter Seller Name"
          />

          <TextField
            className="w-full"
            type="tel"
            variant="outlined"
            onChange={(e) => validatePhoneNumber(e.target.value)}
            label="Enter Seller Mobile"
          />
        </div>

        <TextField
          className="w-full"
          onChange={(e) => setSellerAddress(e.target.value)}
          label="Enter Address"
          multiline
          rows={3}
          variant="outlined"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-semibold">
              Select City
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setSellerCityId(e.target.value)}
            >
              <option> Select City </option>
              {cities.map((city) => (
                <option key={city.cityId} value={city.cityId}>
                  {city.cityName}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold">
              Select Brand
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => loadModels(e, e.target.value)}
            >
              <option> Select Brand </option>
              {brands.map((brand) => (
                <option key={brand.brandId} value={brand.brandId}>
                  {brand.brandName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-semibold">
              Select Model
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setBikeModelId(e.target.value)}
            >
              <option> Select Model </option>
              {models.map((model) => (
                <option key={model.modelId} value={model.modelId}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold">
              Select Color
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setColor(e.target.value)}
            >
              <option> Select Color </option>
              {colors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <TextField
            className="w-full"
            variant="outlined"
            onChange={(e) => setRegistrationNumber(e.target.value)}
            label="Enter Registration Number"
          />

          <TextField
            className="w-full"
            type="number"
            variant="outlined"
            onChange={(e) => setRunningKms(e.target.value)}
            label="Enter Running KMs"
          />

          <TextField
            className="w-full"
            type="number"
            variant="outlined"
            onChange={(e) => setPrice(e.target.value)}
            label="Enter Selling Price"
          />
        </div>

        <div>
          <label className="block text-gray-700 font-semibold">
            Select Purchase Year
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setPurchaseYear(e.target.value)}
          >
            <option> Select Purchase Year </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <TextField
          className="w-full"
          onChange={(e) => setDescription(e.target.value)}
          label="Enter remarks"
          multiline
          rows={3}
          variant="outlined"
        />
      </div>

      <div className="p-6 bg-white shadow-lg rounded-lg space-y-6">
        {[
          {
            label: "Bike Front View",
            image: frontViewImage,
            loading: frontImageLoading,
            handleSubmit: handleSubmitFront,
            setImage: setFrontViewImage,
            uploaded: frontViewImageUploaded,
            failed: frontViewImageUploadFail,
          },
          {
            label: "Bike Top View",
            image: topViewImage,
            loading: topImageLoading,
            handleSubmit: handleSubmitTop,
            setImage: setTopViewImage,
            uploaded: topViewImageUploaded,
            failed: topViewImageUploadFail,
          },
          {
            label: "Bike Left View",
            image: leftViewImage,
            loading: leftImageLoading,
            handleSubmit: handleSubmitLeft,
            setImage: setLeftViewImage,
            uploaded: leftViewImageUploaded,
            failed: leftViewImageUploadFail,
          },
          {
            label: "Bike Right View",
            image: rightViewImage,
            loading: rightImageLoading,
            handleSubmit: handleSubmitRight,
            setImage: setRightViewImage,
            uploaded: rightViewImageUploaded,
            failed: rightViewImageUploadFail,
          },
          {
            label: "Bike Back View",
            image: backViewImage,
            loading: backImageLoading,
            handleSubmit: handleSubmitBack,
            setImage: setBackViewImage,
            uploaded: backViewImageUploaded,
            failed: backViewImageUploadFail,
          },
        ].map(
          (
            { label, image, loading, handleSubmit, setImage, uploaded, failed },
            index
          ) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-4 space-y-4"
            >
              <Divider>
                <Chip
                  label={label}
                  size="small"
                  className="text-lg font-semibold"
                />
              </Divider>

              <div className="flex flex-col items-center space-y-4">
                {/* Display Uploaded Image */}
                {image && (
                  <img
                    className="w-40 h-40 object-cover rounded-lg border shadow-sm"
                    src={image}
                    alt={label}
                  />
                )}

                {/* Show Loading Animation */}
                {loading && (
                  <div className="flex justify-center">
                    <img
                      src={loadingImage}
                      className="w-10 h-10 animate-spin"
                      alt="loading"
                    />
                  </div>
                )}

                {/* File Upload Form */}
                <form
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  className="flex flex-col items-center space-y-3"
                >
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border file:border-gray-300 file:text-gray-700 file:bg-gray-100 hover:file:bg-gray-200"
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    className="bg-blue-600 hover:bg-blue-700"
                  >
                    Upload
                  </Button>

                  {/* Success & Error Messages */}
                  {uploaded && (
                    <p className="text-green-600 font-semibold">
                      Image uploaded successfully!
                    </p>
                  )}
                  {failed && (
                    <p className="text-red-600 font-semibold">
                      Image upload failed. Try again.
                    </p>
                  )}
                </form>
              </div>
            </div>
          )
        )}
      </div>

      <Divider></Divider>

      <div className={styles.componentContainer}>
        <Button
          onClick={handleSubmit}
          className={`${styles.component}`}
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {" "}
          Save Bike
        </Button>
      </div>

      <div className={styles.componentContainer}>
        {isSaved && (
          <div>
            <p className={mainStyles.success}>Bike Saved Successfully</p>
          </div>
        )}
        {errorMessage && <p className={mainStyles.error}>{errorMessage}</p>}
      </div>
    </div>
  );
}
