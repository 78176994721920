import api from "../../services/api";
import {
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
} from "./ActionType";

const getCountriesRequest = () => ({
  type: GET_COUNTRIES_REQUEST,
});

const getCountriesSuccess = (countries) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
});

const getCountriesFailure = (error) => ({
  type: GET_COUNTRIES_FAILURE,
  payload: error,
});

export const getCountries = () => async (dispatch) => {
  dispatch(getCountriesRequest());
  try {
    const response = await api.get("/countries");
    dispatch(getCountriesSuccess(response.data));
  } catch (error) {
    dispatch(getCountriesFailure(error));
  }
};
