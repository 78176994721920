import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getDBHealthCheck } from "../../State/DBHealthCheck/Action";

const DBHealthCheck = () => {
  const { dbHealthCheck } = useSelector((store) => store);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchHealthChecks();
  }, []);

  useEffect(() => {
    dbHealthCheck.dbHealthCheck && setLoading(false);
  }, [dbHealthCheck]);

  const fetchHealthChecks = async () => {
    dispatch(getDBHealthCheck());
  };

  return (
    <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, p: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Database Health Check Logs
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : dbHealthCheck.dbHealthCheck?.length > 0 ? (
          dbHealthCheck.dbHealthCheck?.map((record, index) => (
            <Typography key={index} variant="body1">
              {new Date(record.time).toLocaleString()} {/* Convert timestamp */}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No health check records found.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DBHealthCheck;
