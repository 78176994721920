import { useEffect, useState } from "react";
import api from "../../services/api";
import AppPagination from "../Tools/AppPagination";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonIcon from "@mui/icons-material/Person";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

const InterestedCustomers = () => {
  const [interestedBikes, setInterestedBikes] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    api.get(`/interested/used-bikes?page=${page}`).then((res) => {
      setInterestedBikes(res.data.interestedUsedBikes);
      setTotalPages(res.data.totalPages);
    });
  }, [page]);

  return (
    <div className="flex flex-col items-center p-4">
      <Typography variant="h4" className="mb-4 font-bold text-center">
        Interested Customers
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {interestedBikes.map((bike, index) => (
          <Grid item xs={12} md={8} key={index}>
            <Card className="flex items-center justify-between p-4 shadow-lg rounded-lg">
              <CardContent className="flex-1">
                {/* Customer Name on Top */}
                <Typography
                  variant="h6"
                  className="font-bold flex items-center gap-2 text-blue-600"
                >
                  <PersonIcon className="text-blue-500" /> {bike.customerName}
                </Typography>
                {/* Bike Name below Customer Name */}
                <Typography
                  variant="body1"
                  className="text-gray-700 flex items-center gap-2 mt-1"
                >
                  <TwoWheelerIcon className="text-red-500" /> {bike.bikeName}
                </Typography>
                <Typography
                  variant="body1"
                  className="text-gray-600 flex items-center gap-2 mt-1"
                >
                  <PhoneIcon className="text-green-500" /> {bike.phone}
                </Typography>
                <Typography
                  variant="body1"
                  className="text-gray-600 flex items-center gap-2 mt-1"
                >
                  <MonetizationOnIcon className="text-yellow-500" /> ₹
                  {bike.price.toLocaleString()}
                </Typography>
              </CardContent>

              {/* Image + View More Button Wrapper */}
              <div className="flex flex-col items-center">
                {/* Strict Image Wrapper */}
                <div
                  style={{
                    width: "160px",
                    height: "100px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "1px solid #ddd",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                    marginBottom: "8px",
                  }}
                >
                  <img
                    src={bike.imageUrl}
                    alt={bike.bikeName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                {/* View More Button Below Image */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    (window.location.href = `/usedBikeDetail?bikeId=${bike.bikeId}`)
                  }
                >
                  View More
                </Button>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className="mt-6 flex justify-center">
        <AppPagination setPage={setPage} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default InterestedCustomers;
