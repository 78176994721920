import { useState, useEffect } from "react";
import { Tabs, Tab, TabPanel, TabsList } from "@mui/base";
import { useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import api from "../../services/api";
import MainCarousel from "../MainCarousel/MainCarousel";
import AppPagination from "../Tools/AppPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsedBikesInWorkshop,
  getWorkshopDetails,
} from "../../State/Workshop/Action";
import { getUsedBikeDetailsUnauthorized } from "../../State/UsedBike/Action";

export default function WorkshopDetail() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const workshopId = new URLSearchParams(location.search).get("workshopId");
  const [page, setPage] = useState(0);
  const [model, setModel] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workshop = useSelector((store) => store.workshopResponse.workshop);
  const images = useSelector((store) => store.workshopResponse.workshop.images);
  const bikes = useSelector(
    (store) => store.workshopResponse.bikePaginated.usedBikes
  );
  const totalPages = useSelector(
    (store) => store.workshopResponse.bikePaginated.totalPages
  );

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    }
    dispatch(getWorkshopDetails(workshopId));
  }, [workshopId]);

  useEffect(() => {
    dispatch(getUsedBikesInWorkshop(workshopId, page));
  }, [workshopId, page, model]);

  const handleViewMore = (e, bike) => {
    navigate("/usedBikeDetail?bikeId=" + bike);
  };

  return (
    <div>
      {workshop && (
        <header className="text-2xl font-extrabold p-6 bg-blue-600 text-white shadow-md rounded-xs text-center">
          {workshop.name}
        </header>
      )}
      <div className="slider p-6">
        {images && <MainCarousel imageList={images} />}
        <div>
          {workshop && (
            <Tabs defaultValue={0} className="w-full">
              <TabsList className="flex justify-center space-x-4 bg-gray-100 p-3 rounded-lg shadow-md">
                <Tab
                  className="px-6 py-3 font-semibold text-gray-700 bg-white rounded-md shadow-sm border border-gray-300 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white focus:outline-none data-[selected=true]:bg-blue-700 data-[selected=true]:text-white data-[selected=true]:border-blue-700"
                  value={0}
                >
                  Contact
                </Tab>
                <Tab
                  className="px-6 py-3 font-semibold text-gray-700 bg-white rounded-md shadow-sm border border-gray-300 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white focus:outline-none data-[selected=true]:bg-blue-700 data-[selected=true]:text-white data-[selected=true]:border-blue-700"
                  value={1}
                >
                  Address
                </Tab>
                <Tab
                  className="px-6 py-3 font-semibold text-gray-700 bg-white rounded-md shadow-sm border border-gray-300 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white focus:outline-none data-[selected=true]:bg-blue-700 data-[selected=true]:text-white data-[selected=true]:border-blue-700"
                  value={2}
                >
                  Bikes
                </Tab>
              </TabsList>

              <TabPanel
                value={0}
                className="p-6 border rounded-lg bg-white shadow-lg"
              >
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <strong>Name:</strong> {workshop.name}
                  </div>
                  <div>
                    <strong>Owner Name:</strong> {workshop.ownerName}
                  </div>
                  <div>
                    <strong>Phone 1:</strong> {workshop.phoneNumber}
                  </div>
                  <div>
                    <strong>Phone 2:</strong> {workshop.alternatePhoneNumber}
                  </div>
                  <div>
                    <strong>Email:</strong> {workshop.email}
                  </div>
                  <div>
                    <strong>Experience:</strong> {workshop.yearsOld} year(s)
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                value={1}
                className="p-6 border rounded-lg bg-white shadow-lg"
              >
                <div className="grid gap-4">
                  <div>
                    <strong>Shop #:</strong> {workshop.shopNumber}
                  </div>
                  <div>
                    <strong>Address:</strong> {workshop.address}
                  </div>
                  <div>
                    <strong>City:</strong> {workshop.city}
                  </div>
                  <div>
                    <strong>State:</strong> {workshop.state}
                  </div>
                  <div>
                    <strong>Zip Code:</strong> {workshop.zipCode}
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                value={2}
                className="p-6 border rounded-lg bg-white shadow-lg"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {bikes?.map((bike) => (
                    <div
                      key={bike.bikeId}
                      className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-transform transform hover:scale-105"
                    >
                      <div className="p-6 text-center">
                        <img
                          onClick={(e) => handleViewMore(e, bike.bikeId)}
                          className="w-full h-52 object-cover cursor-pointer rounded-md"
                          src={bike.imageUrl}
                          alt="bike_image"
                        />
                        <div className="mt-4 font-bold text-lg text-gray-800">
                          {bike.brand} {bike.model}
                        </div>
                        <div className="text-gray-500">
                          Year: {bike.purchaseYear}
                        </div>
                        <div className="text-blue-600 font-extrabold text-2xl">
                          ₹{bike.price}/-
                        </div>
                        <div className="flex justify-center items-center text-gray-600">
                          <LocationOnIcon fontSize="medium" />
                          <span className="ml-1">{bike.sellerCity}</span>
                        </div>
                        <div className="mt-4">
                          <span
                            onClick={(e) => handleViewMore(e, bike.bikeId)}
                            className="text-blue-500 font-bold cursor-pointer hover:underline"
                          >
                            View More
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-8">
                  <AppPagination setPage={setPage} totalPages={totalPages} />
                </div>
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
}
