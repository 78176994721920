import React from "react";

import { useMyContext } from "../../store/ContextApi";
import NewBikeList from "./NewBikeList";
import NewBikeListShort from "./NewBikeListShort";

const NewBikeListRouter = () => {
  const { currentUser, token } = useMyContext();

  if (currentUser && token) {
    return <NewBikeList />;
  } else {
    return <NewBikeListShort />;
  }
};

export default NewBikeListRouter;
