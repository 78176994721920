import api from "../../services/api";
import {
  ADD_STATE_FAILURE,
  ADD_STATE_REQUEST,
  ADD_STATE_SUCCESS,
  GET_STATES_BY_COUNTRY_FAILURE,
  GET_STATES_BY_COUNTRY_REQUEST,
  GET_STATES_BY_COUNTRY_SUCCESS,
  GET_STATES_FAILURE,
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATE_FAILURE,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
} from "./ActionType";

const addStateRequest = () => {
  return {
    type: ADD_STATE_REQUEST,
  };
};

const addStateSuccess = (state) => {
  return {
    type: ADD_STATE_SUCCESS,
    payload: state,
  };
};

const addStateFailure = (error) => {
  return {
    type: ADD_STATE_FAILURE,
    payload: error,
  };
};

const getStateRequest = () => {
  return {
    type: GET_STATE_REQUEST,
  };
};

const getStateSuccess = (state) => {
  return {
    type: GET_STATE_SUCCESS,
    payload: state,
  };
};

const getStateFailure = (error) => {
  return {
    type: GET_STATE_FAILURE,
    payload: error,
  };
};

const getStatesRequest = () => {
  return {
    type: GET_STATES_REQUEST,
  };
};

const getStatesSuccess = (state) => {
  return {
    type: GET_STATES_SUCCESS,
    payload: state,
  };
};

const getStatesFailure = (error) => {
  return {
    type: GET_STATES_FAILURE,
    payload: error,
  };
};

export const addState = (state) => async (dispatch) => {
  dispatch(addStateRequest());
  try {
    const response = await api.post("/states", state);
    dispatch(addStateSuccess(response.data));
    console.log(response.data);
    window.location.reload(false);
  } catch (error) {
    dispatch(addStateFailure(error));
  }
};

export const getState = (id) => async (dispatch) => {
  dispatch(getStateRequest());
  try {
    const response = await api.get(`/states/${id}`);
    dispatch(getStateSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(getStateFailure(error));
  }
};

export const getStates = () => async (dispatch) => {
  dispatch(getStatesRequest());
  try {
    const response = await api.get("/states");
    dispatch(getStatesSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(getStatesFailure(error));
  }
};

const getStatesByCountryRequest = () => {
  return {
    type: GET_STATES_BY_COUNTRY_REQUEST,
  };
};

const getStatesByCountrySuccess = (states) => {
  return {
    type: GET_STATES_BY_COUNTRY_SUCCESS,
    payload: states,
  };
};

const getStatesByCountryFailure = (error) => {
  return {
    type: GET_STATES_BY_COUNTRY_FAILURE,
    payload: error,
  };
};

export const getStatesByCountry = (countryId) => async (dispatch) => {
  dispatch(getStatesByCountryRequest());
  try {
    const response = await api.get(`/states/${countryId}`);
    dispatch(getStatesByCountrySuccess(response.data));
  } catch (error) {
    dispatch(getStatesByCountryFailure(error));
  }
};
