import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useMyContext } from "../../store/ContextApi";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import StarIcon from "@mui/icons-material/Star";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { GiWallet } from "react-icons/gi";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import { FaMediumM, FaEye } from "react-icons/fa";
import { MdGarage } from "react-icons/md";

const Sidebar = () => {
  // Access the openSidebar and setOpenSidebar function using the useMyContext hook from the ContextProvider
  const { openSidebar, setOpenSidebar } = useMyContext();

  //access the current path
  const pathName = useLocation().pathname;

  return (
    <div
      className={`fixed p-2 top-[54px] min-h-[calc(100vh-110px)] max-h-[calc(100vh-74px)]  z-20  left-0 bg-headerColor ${
        openSidebar ? "w-52" : "w-12"
      } transition-all duration-150  `}
    >
      <div className=" min-h-10  max-h-10 flex flex-end">
        {openSidebar ? (
          <button
            className="flex w-full text-white justify-end items-center gap-1"
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <span>
              <FaArrowLeft className="text-sm" />
            </span>
            <span className="font-semibold">Close</span>
          </button>
        ) : (
          <Tooltip title="Click To Expand">
            <button
              className="flex w-full text-white justify-center items-center gap-1"
              onClick={() => setOpenSidebar(!openSidebar)}
            >
              <span>
                <FaArrowRight className="text-lg" />
              </span>
            </button>
          </Tooltip>
        )}
      </div>

      <div className="flex flex-col gap-5 mt-4">
        {/* <Tooltip title={`${openSidebar ? "" : "All Users"}`}>
          <Link
            to="/admin/users"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/users")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <FaUser />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              All Users
            </span>
          </Link>
        </Tooltip>
        {/* <Tooltip title={`${openSidebar ? "" : "Audit Logs"}`}>
          <Link
            to="/admin/audit-logs"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/audit-logs")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <LiaBlogSolid className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Audit Logs
            </span>
          </Link>
        </Tooltip> */}
        <Tooltip title={`${openSidebar ? "" : "Brand"}`}>
          <Link
            to="/admin/brand"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/brand")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <FormatBoldIcon className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Brand
            </span>
          </Link>
        </Tooltip>
        <Tooltip title={`${openSidebar ? "" : "Bike Model"}`}>
          <Link
            to="/admin/bike-model"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/bike-model")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <FaMediumM className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Bike Model
            </span>
          </Link>
        </Tooltip>
        <Tooltip title={`${openSidebar ? "" : "New Bike"}`}>
          <Link
            to="/admin/new-bike"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/new-bike")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <TwoWheelerIcon className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              New Bike
            </span>
          </Link>
        </Tooltip>
        <Tooltip title={`${openSidebar ? "" : "Workshop"}`}>
          <Link
            to="/admin/workshop"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/workshop")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <MdGarage className="bg-transparent" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Workshop
            </span>
          </Link>
        </Tooltip>
        <Tooltip title={`${openSidebar ? "" : "Status Update"}`}>
          <Link
            to="/admin/status-update"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/status-update")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <FaEye className="bg-transparent" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Display Status
            </span>
          </Link>
        </Tooltip>

        <Tooltip title={`${openSidebar ? "" : "Recent Users"}`}>
          <Link
            to="/admin/recent-users"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/recent-users")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <PeopleAltIcon className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Recent Users
            </span>
          </Link>
        </Tooltip>

        <Tooltip title={`${openSidebar ? "" : "Expenses"}`}>
          <Link
            to="/admin/db-health-check"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/db-health-check")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <HeartBrokenIcon className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              DB Health Check
            </span>
          </Link>
        </Tooltip>
        <Tooltip title={`${openSidebar ? "" : "Expenses"}`}>
          <Link
            to="/admin/exception-logs"
            className={`flex text-white items-center gap-2 ${
              pathName.startsWith("/admin/exception-logs")
                ? "bg-btnColor"
                : "bg-transparent"
            }   min-h-10 max-h-10 py-2 px-2 rounded-md hover:bg-btnColor`}
          >
            <span>
              <ErrorOutlineIcon className="text-xl" />
            </span>
            <span
              className={` ${
                !openSidebar ? "opacity-0" : ""
              } transition-all font-semibold duration-150  ease-in-out`}
            >
              Exceptions
            </span>
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};

export default Sidebar;
