import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GET_EXCEPTION_LOG_SUCCESS } from "../../State/ExceptionLog/ActionType";
import { getExceptionLog } from "../../State/ExceptionLog/Action";
import moment from "moment";
import AppPagination from "../Tools/AppPagination";

const ExceptionLog = () => {
  const [exceptionLogs, setExceptionLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { exceptionLog } = useSelector((store) => store);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getExceptionLog(page));
  }, [page]);

  useEffect(() => {
    if (exceptionLog.log.exceptionLogs) {
      setLoading(false);
    }
  }, [exceptionLog]);

  return (
    <Card sx={{ maxWidth: 800, margin: "auto", mt: 4, p: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Exception Logs
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : exceptionLog.log?.exceptionLogs?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Exception Message</b>
                  </TableCell>
                  <TableCell>
                    <b>Operation Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Reference ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exceptionLog.log?.exceptionLogs?.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log.exceptionMessage}</TableCell>
                    <TableCell>{log.operationType}</TableCell>
                    <TableCell>{log.referenceId}</TableCell>
                    <TableCell>{moment(log.createdAt).format("lll")}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No exception logs found.
          </Typography>
        )}
        <div className="mt-6">
          <AppPagination
            setPage={setPage}
            totalPages={exceptionLog.log?.totalPages}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ExceptionLog;
