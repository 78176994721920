import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { Favorite, Visibility } from "@mui/icons-material";
import bigImage from "./images/main-bike.png";
import heroLogo from "./images/hero-logo.webp";
import bajajLogo from "./images/bajaj-logo.webp";
import royalEnfieldLogo from "./images/royal-enfield-logo.webp";
import tvsLogo from "./images/tvs-logo.webp";
import hondaLogo from "./images/honda-logo.webp";
import yamahaLogo from "./images/yamaha-logo.webp";
import suzukiLogo from "./images/suzuki-logo.webp";
import ktmLogo from "./images/ktm-logo.webp";
import harleyDavidsonLogo from "./images/harley-davidson-logo.jpg";
import AppPagination from "../Tools/AppPagination";
import api from "../../services/api";
import bluewasherImage from "./images/bluewasher_landing_image.png";
import bluewheelImage from "./images/bluewheel_landing_image.png";
import { useDispatch, useSelector } from "react-redux";
import { getUsedBikesUnauthorized } from "../../State/UsedBike/Action";

const LandingPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const usedBikes = useSelector(
    (store) => store.usedBikesResponse.usedBikesPaged.usedBikes
  );
  const totalPages = useSelector(
    (store) => store.usedBikesResponse.usedBikesPaged.totalPages
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsedBikesUnauthorized(page));
  }, [page]);

  return (
    <div className="w-full relative bg-gray-100 min-h-screen p-4">
      {/* Main Content Centered Below */}
      <div className="container mx-auto text-center mt-12">
        <h1 className="text-4xl font-extrabold text-blue-600">
          Chase Your Dream with Us
        </h1>
      </div>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className="mt-6 pb-16"
      >
        {usedBikes?.map((bike) => (
          <Grid item xs={12} sm={6} md={4} key={bike.bikeId}>
            <Card className="shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <img
                src={bike.imageUrl}
                alt={bike.model}
                className="w-full h-48 object-cover p-2"
              />
              <CardContent>
                <Typography variant="h6" className="font-bold">
                  {bike.brand} {bike.model}
                </Typography>
                <Typography variant="body1">Price: ₹{bike.price}</Typography>
                <Typography variant="body2">
                  Location: {bike.sellerCity}
                </Typography>
                <div className="flex justify-center gap-2 mt-2">
                  <IconButton
                    color="primary"
                    onClick={() => navigate("/login")}
                  >
                    <Favorite />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      navigate(`/used-bike-detail?bikeId=${bike.bikeId}`)
                    }
                  >
                    <Visibility />
                  </IconButton>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div className="flex justify-center mt-16 mb-12 p-4">
        <AppPagination totalPages={totalPages} setPage={setPage} />
      </div>

      <div className="p-4 flex flex-col items-center gap-4">
        <a
          href="https://www.bluewasher.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={bluewasherImage}
            alt="Blue Washer"
            className="w-full max-w-[500px] mx-auto rounded-lg shadow-md"
          />
        </a>
        <a
          href="https://www.bluewheel.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={bluewheelImage}
            alt="Blue Wheel"
            className="w-full max-w-[500px] mx-auto rounded-lg shadow-md"
          />
        </a>
      </div>

      <div className="w-full bg-white py-4 overflow-x-auto whitespace-nowrap flex gap-4 px-4 justify-center border-t border-gray-300 mt-6">
        {[
          heroLogo,
          bajajLogo,
          royalEnfieldLogo,
          tvsLogo,
          hondaLogo,
          yamahaLogo,
          suzukiLogo,
          ktmLogo,
          harleyDavidsonLogo,
        ].map((logo, index) => (
          <img
            src={logo}
            alt="Brand Logo"
            key={index}
            className="h-12 w-auto inline-block mx-2 opacity-80 hover:opacity-100 transition-opacity duration-300"
          />
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
