import { useEffect, useState } from "react";
import { TextField, Autocomplete, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppPagination from "../Tools/AppPagination";
import api from "../../services/api";
import NewBikeDetailShort from "./NewBikeDetailShort";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getBikeNames } from "../../State/NewBike/Action";

export default function NewBikeListShort() {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [model, setModel] = useState("");
  const navigate = useNavigate();

  const bikeNames = useSelector(
    (store) => store.newBikesResponse.bikeNames.bikeModelNames
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBikeNames());

    loadBikes(page, model);
  }, [page, model]);

  const loadBikes = (pageNo, model) => {
    api.get(`/public/newBikes?model=${model}&page=${pageNo}`).then((res) => {
      setBikes(res.data.bikes);
      setTotalPages(res.data.totalPages);
    });
  };

  const handleViewMore = (e, bike) => {
    navigate(`/newBikeDetailShort?bikeId=${bike}`);
  };

  const handleBikeChange = (event, value) => {
    setModel(value?.id || "");
  };

  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      {showBikeList && (
        <div>
          <div className="w-full flex justify-center">
            <picture>
              <source media="(max-width: 465px)" srcSet={bikeImage1} />
              <source media="(min-width: 700px)" srcSet={bikeImage2} />
              <img
                src={bikeImage1}
                alt="bike ad"
                className="w-full h-40 object-cover rounded-lg shadow-lg"
              />
            </picture>
          </div>

          <h2 className="text-2xl font-bold text-center mt-6 mb-4 text-gray-800">
            New Bikes
          </h2>

          <div className="flex justify-center mb-6">
            <Autocomplete
              onChange={handleBikeChange}
              onInputChange={(event, value, reason) => {
                if (reason === "clear") {
                  setModel("");
                }
              }}
              disablePortal
              {...defaultProps}
              sx={{ width: 300, backgroundColor: "white", borderRadius: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Search Bike" />
              )}
            />
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {bikes.map((bike) => (
              <div
                key={bike.bikeId}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all"
              >
                <img
                  src={bike.imageUrl}
                  alt="bike"
                  className="w-full h-48 object-cover cursor-pointer"
                  onClick={(e) => handleViewMore(e, bike.bikeId)}
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-700">
                    {bike.brand} {bike.model}
                  </h3>
                  <p className="text-sm text-gray-500">{bike.engineCapacity}</p>
                  <p className="text-lg font-bold text-blue-600">
                    ₹{bike.exShowroomPrice}/-
                  </p>
                  <button
                    onClick={(e) => handleViewMore(e, bike.bikeId)}
                    className="mt-2 text-blue-500 hover:underline text-sm"
                  >
                    View More
                  </button>
                </div>
              </div>
            ))}
          </div>

          <Box className="flex justify-center mt-6">
            <AppPagination totalPages={totalPages} setPage={setPage} />
          </Box>
        </div>
      )}
      {showBikeDetail && <NewBikeDetailShort bikeId={bikeId} />}
    </div>
  );
}
