import {
  ADD_WORKSHOP_FAILURE,
  ADD_WORKSHOP_REQUEST,
  ADD_WORKSHOP_SUCCESS,
  GET_MY_WORKSHOPS_FAILURE,
  GET_MY_WORKSHOPS_REQUEST,
  GET_MY_WORKSHOPS_SUCCESS,
  GET_USED_BIKES_IN_WORKSHOP_FAILURE,
  GET_USED_BIKES_IN_WORKSHOP_REQUEST,
  GET_USED_BIKES_IN_WORKSHOP_SUCCESS,
  GET_WORKSHOPS_FAILURE,
  GET_WORKSHOPS_REQUEST,
  GET_WORKSHOPS_SUCCESS,
  GET_WORKSHOP_DETAILS_FAILURE,
  GET_WORKSHOP_DETAILS_REQUEST,
  GET_WORKSHOP_DETAILS_SUCCESS,
  UPLOAD_WORKSHOP_IMAGE_FAILURE,
  UPLOAD_WORKSHOP_IMAGE_REQUEST,
  UPLOAD_WORKSHOP_IMAGE_SUCCESS,
} from "./ActionType";

const initialState = {
  workshopPaginated: {},
  bikePaginated: {},
  workshop: {},
  workshopImage: {},
  loading: false,
  error: null,
};

export const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case [
      GET_WORKSHOPS_REQUEST,
      GET_WORKSHOP_DETAILS_REQUEST,
      GET_USED_BIKES_IN_WORKSHOP_REQUEST,
      GET_MY_WORKSHOPS_REQUEST,
      ADD_WORKSHOP_REQUEST,
      UPLOAD_WORKSHOP_IMAGE_REQUEST,
    ]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_WORKSHOPS_SUCCESS:
      return {
        ...state,
        workshopPaginated: action.payload,
        loading: false,
        error: null,
      };
    case [
      GET_WORKSHOPS_FAILURE,
      GET_WORKSHOP_DETAILS_FAILURE,
      GET_USED_BIKES_IN_WORKSHOP_FAILURE,
      GET_MY_WORKSHOPS_FAILURE,
      ADD_WORKSHOP_FAILURE,
      UPLOAD_WORKSHOP_IMAGE_FAILURE,
    ]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_WORKSHOP_DETAILS_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
        loading: false,
        error: null,
      };
    case GET_USED_BIKES_IN_WORKSHOP_SUCCESS:
      return {
        ...state,
        bikePaginated: action.payload,
        loading: false,
        error: null,
      };
    case GET_MY_WORKSHOPS_SUCCESS:
      return {
        ...state,
        workshopPaginated: action.payload,
        loading: false,
        error: null,
      };
    case ADD_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
        loading: false,
        error: null,
      };
    case UPLOAD_WORKSHOP_IMAGE_SUCCESS:
      return {
        ...state,
        workshopImage: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
