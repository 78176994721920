import {
  ADD_REVIEW_FAILURE,
  ADD_REVIEW_REQUEST,
  ADD_REVIEW_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
} from "./ActionType";

const initialState = {
  reviewAdded: {},
  reviews: {},
  loading: false,
  error: null,
};

export const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case [ADD_REVIEW_REQUEST, GET_REVIEWS_REQUEST]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_REVIEW_SUCCESS:
      return {
        ...state,
        reviewAdded: action.payload,
        loading: false,
        error: null,
      };
    case [ADD_REVIEW_FAILURE, GET_REVIEWS_FAILURE]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
