import React from "react";
import { Pagination } from "@mui/material";
const AppPagination = ({ setPage, totalPages }) => {
  const handleChange = (page) => {
    setPage(page);
    window.scroll(0, 0);
  };

  return (
    <div>
      <Pagination
        onChange={(e, page) => handleChange(page - 1)}
        count={totalPages}
        variant="outlined"
        color="primary"
      />
    </div>
  );
};

export default AppPagination;
