import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import api from "../../services/api";
import { useLocation } from "react-router-dom";
import MainCarousel from "../MainCarousel/MainCarousel";
import styles from "./UsedBike.module.css";
import mainStyles from "../Main.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getUsedBikeDetails } from "../../State/UsedBike/Action";

export default function UsedBikeDetail() {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const bikeId = new URLSearchParams(location.search).get("bikeId");
  const bike = useSelector((store) => store.usedBikesResponse.bike);
  const images = useSelector((store) => store.usedBikesResponse.bike.images);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsedBikeDetails(bikeId));
  }, [bikeId]);

  return (
    <Box sx={{ p: 3 }}>
      {bike && (
        <>
          <Typography variant="h4" className={mainStyles.header}>
            {bike.brand} {bike.model}
          </Typography>

          <Box
            className="slider"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {images && <MainCarousel imageList={images} />}
            <Typography
              variant="h5"
              className={mainStyles.headerPrice}
              sx={{ mt: 2 }}
            >
              ₹{bike.price} /-
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Tabs
              value={tabIndex}
              onChange={(e, newIndex) => setTabIndex(newIndex)}
              centered
            >
              <Tab label="Bike Details" />
              <Tab label="Seller Details" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, p: 2 }}>
                <DetailItem label="Brand" value={bike.brand} />
                <DetailItem label="Model" value={bike.model} />
                <DetailItem label="Color" value={bike.color} />
                <DetailItem label="Price" value={`₹${bike.price}`} />
                <DetailItem
                  label="Running KM"
                  value={bike.runningKms === 0 ? "NA" : bike.runningKms}
                />
                <DetailItem label="Purchase Year" value={bike.purchaseYear} />
                {bike.modelYear && (
                  <DetailItem label="Model Year" value={bike.modelYear} />
                )}
                <DetailItem label="Description" value={bike.description} />
                <DetailItem label="Bike #" value={bike.registrationNumber} />
              </Box>
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <Box sx={{ p: 2 }}>
                <DetailItem label="Seller" value={bike.sellerName} />
                <DetailItem label="Contact" value={bike.sellerMobile} />
                <DetailItem label="Address" value={bike.sellerAddress} />
                <DetailItem label="City" value={bike.sellerCity} />
              </Box>
            </TabPanel>
          </Box>

          <Typography
            variant="body2"
            sx={{ mt: 3, fontStyle: "italic", color: "gray" }}
          >
            <b>Note:</b> BlueBike does not verify the authenticity of any bike.
            Please check all details/documents with the seller.
          </Typography>
        </>
      )}
    </Box>
  );
}

// Helper Component for Displaying Bike Details
const DetailItem = ({ label, value }) => (
  <Box sx={{ display: "flex", flexDirection: "column", minWidth: "200px" }}>
    <Typography variant="subtitle2" color="primary">
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

// Custom TabPanel to Match MUI Tabs
function TabPanel({ children, value, index }) {
  return value === index ? (
    <Box
      sx={{
        p: 3,
        mt: 2,
        borderRadius: "8px",
        boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      {children}
    </Box>
  ) : null;
}
