import {
  ADD_STATE_REQUEST,
  GET_STATES_BY_COUNTRY_FAILURE,
  GET_STATES_BY_COUNTRY_REQUEST,
  GET_STATES_BY_COUNTRY_SUCCESS,
  GET_STATES_FAILURE,
} from "./ActionType";

const initialState = {
  state: {},
  states: [],
  loading: false,
  error: null,
};

export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case [ADD_STATE_REQUEST, GET_STATES_BY_COUNTRY_REQUEST]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "ADD_STATE_SUCCESS":
      return {
        ...state,
        state: action.payload,
        loading: false,
        error: null,
      };
    case "ADD_STATE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_STATE_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_STATE_SUCCESS":
      return {
        ...state,
        state: action.payload,
        loading: false,
        error: null,
      };
    case "GET_STATE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_STATES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_STATES_SUCCESS":
      return {
        ...state,
        states: action.payload,
        loading: false,
        error: null,
      };
    case [GET_STATES_FAILURE, GET_STATES_BY_COUNTRY_FAILURE]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_STATES_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
