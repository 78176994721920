import api from "../../services/api";
import {
  GET_MODELS_BY_BRNAD_FAILURE,
  GET_MODELS_BY_BRNAD_REQUEST,
  GET_MODELS_BY_BRNAD_SUCCESS,
} from "./ActionType";

const getModelsByBrandRequest = () => ({
  type: GET_MODELS_BY_BRNAD_REQUEST,
});

const getModelsByBrandSuccess = (models) => ({
  type: GET_MODELS_BY_BRNAD_SUCCESS,
  payload: models,
});

const getModelsByBrandFailure = (error) => ({
  type: GET_MODELS_BY_BRNAD_FAILURE,
  payload: error,
});

export const getModelsByBrand = (brandId) => {
  return async (dispatch) => {
    dispatch(getModelsByBrandRequest());
    try {
      const response = await api.get(`/bikeModels/${brandId}`);
      dispatch(getModelsByBrandSuccess(response.data));
    } catch (error) {
      dispatch(getModelsByBrandFailure(error.message));
    }
  };
};
