import api from "../../services/api";
import {
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  GET_BOOKING_BY_FILTER_FAILURE,
  GET_BOOKING_BY_FILTER_REQUEST,
  GET_BOOKING_BY_FILTER_SUCCESS,
  GET_BOOKING_BY_ID_FAILURE,
  GET_BOOKING_BY_ID_REQUEST,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_ESTIMATED_PRICE_FAILURE,
  GET_ESTIMATED_PRICE_REQUEST,
  GET_ESTIMATED_PRICE_SUCCESS,
} from "./ActionType";
const createBookingRequest = () => ({ type: "CREATE_BOOKING_REQUEST" });
const createBookingSuccess = (booking) => ({
  type: "CREATE_BOOKING_SUCCESS",
  payload: booking,
});
const createBookingFailure = (error) => ({
  type: "CREATE_BOOKING_FAILURE",
  payload: error,
});

const getAllBookingsRequestByUserId = () => ({
  type: "GET_ALL_BOOKINGS_REQUEST_BY_USER_ID",
});
const getAllBookingsSuccessByUserId = (bookings) => ({
  type: "GET_ALL_BOOKINGS_SUCCESS_BY_USER_ID",
  payload: bookings,
});

const getAllBookingsFailureByUserId = (error) => ({
  type: "GET_ALL_BOOKINGS_FAILURE_BY_USER_ID",
  payload: error,
});

export const getAllBookingsByUserId = () => async (dispatch) => {
  dispatch(getAllBookingsRequestByUserId());
  try {
    const response = await api.get(`/bookings/my-bookings`, {
      withCredentials: true,
    });
    console.log(response.data);
    dispatch(getAllBookingsSuccessByUserId(response.data));
  } catch (error) {
    dispatch(getAllBookingsFailureByUserId(error));
  }
};

export const createBooking = (inputRequest) => async (dispatch) => {
  dispatch(createBookingRequest());
  try {
    const response = await api.post("/bookings", inputRequest.booking, {
      withCredentials: true,
    });
    dispatch(createBookingSuccess(response.data));
    console.log(response.data);
    inputRequest.setSuccessMessage("Booking created successfully");
  } catch (error) {
    inputRequest.setErrorMessage("Failed to create booking");
    dispatch(createBookingFailure(error));
  }
};

const getAllBookingsRequest = () => ({
  type: "GET_ALL_BOOKINGS_REQUEST",
});

const getAllBookingsSuccess = (bookings) => ({
  type: "GET_ALL_BOOKINGS_SUCCESS",
  payload: bookings,
});

const getAllBookingsFailure = (error) => ({
  type: "GET_ALL_BOOKINGS_FAILURE",
  payload: error,
});

export const getAllBookings = () => async (dispatch) => {
  dispatch(getAllBookingsRequest());
  try {
    const response = await api.get("/bookings", { withCredentials: true });
    dispatch(getAllBookingsSuccess(response.data));
  } catch (error) {
    dispatch(getAllBookingsFailure(error));
  }
};

const updateBookingRequest = () => ({
  type: "UPDATE_BOOKING_REQUEST",
});

const updateBookingSuccess = (booking) => ({
  type: "UPDATE_BOOKING_SUCCESS",
  payload: booking,
});

const updateBookingFailure = (error) => ({
  type: "UPDATE_BOOKING_FAILURE",
  payload: error,
});

export const updateBooking = (input) => async (dispatch) => {
  dispatch(updateBookingRequest());
  try {
    const response = await api.put(`/bookings/${input.id}`, input.booking, {
      withCredentials: true,
    });
    dispatch(updateBookingSuccess(response.data));
    input.setSuccessMessage("Booking updated successfully");
    window.location.reload(false);
  } catch (error) {
    dispatch(updateBookingFailure(error));
    input.setErrorMessage("Failed to update booking");
  }
};

const getEstimatedPriceRequest = () => ({
  type: GET_ESTIMATED_PRICE_REQUEST,
});

const getEstimatedPriceSuccess = (price) => ({
  type: GET_ESTIMATED_PRICE_SUCCESS,
  payload: price,
});

const getEstimatedPriceFailure = (error) => ({
  type: GET_ESTIMATED_PRICE_FAILURE,
  payload: error,
});

export const getEstimatedPrice = (washName) => async (dispatch) => {
  dispatch(getEstimatedPriceRequest());
  try {
    const response = await api.get(`/bookings/estimated-price/${washName}`, {
      withCredentials: true,
    });
    dispatch(getEstimatedPriceSuccess(response.data));
  } catch (error) {
    dispatch(getEstimatedPriceFailure(error));
  }
};

const cancelBookingRequest = () => ({
  type: CANCEL_BOOKING_REQUEST,
});

const cancelBookingSuccess = (booking) => ({
  type: CANCEL_BOOKING_SUCCESS,
  payload: booking,
});

const cancelBookingFailure = (error) => ({
  type: CANCEL_BOOKING_FAILURE,
  payload: error,
});

export const cancelBooking = (bookingId) => async (dispatch) => {
  dispatch(cancelBookingRequest());
  try {
    const response = await api.patch(`/bookings/cancel/${bookingId}`, {
      withCredentials: true,
    });
    dispatch(cancelBookingSuccess(response.data));
    window.location.reload(false);
  } catch (error) {
    dispatch(cancelBookingFailure(error));
  }
};

export const getBookingById = (bookingId) => async (dispatch) => {
  dispatch({ type: GET_BOOKING_BY_ID_REQUEST });
  try {
    let url = `/bookings/${bookingId}`;
    if (bookingId === undefined || bookingId === null || bookingId === "") {
      url = `/bookings`;
    }
    const response = await api.get(url, {
      withCredentials: true,
    });
    dispatch({ type: GET_BOOKING_BY_ID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_BOOKING_BY_ID_FAILURE, payload: error });
  }
};

const getBookingByFilterRequest = () => ({
  type: GET_BOOKING_BY_FILTER_REQUEST,
});

const getBookingByFilterSuccess = (bookings) => ({
  type: GET_BOOKING_BY_FILTER_SUCCESS,
  payload: bookings,
});

const getBookingByFilterFailure = (error) => ({
  type: GET_BOOKING_BY_FILTER_FAILURE,
  payload: error,
});

export const getBookingsByFilter = (filter) => async (dispatch) => {
  dispatch(getBookingByFilterRequest());
  try {
    let url = `/bookings/filter${filter}`;
    const response = await api.get(url, {
      withCredentials: true,
    });
    dispatch(getBookingByFilterSuccess(response.data));
  } catch (error) {
    dispatch(getBookingByFilterFailure(error));
  }
};
