import api from "../../services/api";
import {
  ADD_REVIEW_FAILURE,
  ADD_REVIEW_REQUEST,
  ADD_REVIEW_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
} from "./ActionType";

const addReviewRequest = () => ({
  type: ADD_REVIEW_REQUEST,
});
const addReviewSuccess = (payload) => ({
  type: ADD_REVIEW_SUCCESS,
  payload,
});

const addReviewFailure = (payload) => ({
  type: ADD_REVIEW_FAILURE,
  payload,
});

export const addReview = (review) => async (dispatch) => {
  dispatch(addReviewRequest());
  try {
    const response = await api.post(`/reviews`, review, {
      withCredentials: true,
    });
    dispatch(addReviewSuccess(response.data));
  } catch (error) {
    dispatch(addReviewFailure(error.message));
  }
};

const getReviewsRequest = () => ({
  type: GET_REVIEWS_REQUEST,
});

const getReviewsSuccess = (payload) => ({
  type: GET_REVIEWS_SUCCESS,
  payload,
});

const getReviewsFailure = (payload) => ({
  type: GET_REVIEWS_FAILURE,
  payload,
});

export const getReviews = (filter) => async (dispatch) => {
  dispatch(getReviewsRequest());
  try {
    const response = await api.get(`/reviews${filter}`, {
      withCredentials: true,
    });
    dispatch(getReviewsSuccess(response.data));
  } catch (error) {
    dispatch(getReviewsFailure(error.message));
  }
};
