import React from "react";

import { useMyContext } from "../../store/ContextApi";
import UsedBikeList from "./UsedBikeList";
import UsedBikeListShort from "./UsedBikeListShort";

const UsedBikeListRouter = () => {
  const { currentUser, token } = useMyContext();

  if (currentUser && token) {
    return <UsedBikeList />;
  } else {
    return <UsedBikeListShort />;
  }
};

export default UsedBikeListRouter;
