import { InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

const DateTimeDropDown = ({ setDateTime, delay }) => {
  const today = moment().format("DD/MM/YYYY");
  const today_plus_1 = moment().add(1, "days").format("DD/MM/YYYY");
  const today_plus_2 = moment().add(2, "days").format("DD/MM/YYYY");
  const dates = [today, today_plus_1, today_plus_2];

  const [date, setDate] = useState(today);
  const [times, setTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    updateTimes(today);
  }, [delay]); // Re-run if delay changes

  const updateTimes = (selectedDate) => {
    const currentHour = Number(moment().format("HH")) + delay;
    let timesArray = [];

    for (let i = 8; i <= 18; i++) {
      if (selectedDate === today && i < currentHour) continue;

      let displayHour = i % 12 || 12;
      let suffix = i < 12 ? " AM" : " PM";
      timesArray.push({ key: i, value: displayHour + suffix });
    }

    setTimes(timesArray);
    setSelectedTime(timesArray[0]?.key || null); // Default to the first available time
    updateDateTime(selectedDate, timesArray[0]?.key || null);
  };

  const updateDateTime = (selectedDate, selectedHour) => {
    if (!selectedHour) return;

    const formattedDate = moment(selectedDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const dateTimeString = `${formattedDate} ${selectedHour}:00:00`;
    const formattedDateTime = moment(
      dateTimeString,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    setDateTime(formattedDateTime);
  };

  return (
    <div className="flex flex-row gap-4">
      <div>
        <InputLabel id="date-label">Date</InputLabel>
        <Select
          labelId="date-select-label"
          id="date-select"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            updateTimes(e.target.value);
          }}
        >
          {dates.map((dateOption, i) => (
            <MenuItem key={i} value={dateOption}>
              {dateOption}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <InputLabel id="time-label">Time</InputLabel>
        <Select
          labelId="time-select-label"
          id="time-select"
          value={selectedTime}
          onChange={(e) => {
            setSelectedTime(e.target.value);
            updateDateTime(date, e.target.value);
          }}
        >
          {times.map((time) => (
            <MenuItem key={time.key} value={time.key}>
              {time.value}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DateTimeDropDown;
