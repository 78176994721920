import {
  CREATE_WASH_FAILURE,
  CREATE_WASH_REQUEST,
  CREATE_WASH_SUCCESS,
  GET_CAR_WASH_BY_CUSTOMER_FAILURE,
  GET_CAR_WASH_BY_CUSTOMER_REQUEST,
  GET_CAR_WASH_BY_CUSTOMER_SUCCESS,
  GET_FILTERED_WASHES_FAILURE,
  GET_FILTERED_WASHES_REQUEST,
  GET_FILTERED_WASHES_SUCCESS,
  GET_WASHES_FAILURE,
  GET_WASHES_REQUEST,
  GET_WASHES_SUCCESS,
} from "./ActionType";

const initialState = {
  wash: {},
  washes: [],
  pagedWash: {},
  loading: false,
  error: null,
};

export const washReducer = (state = initialState, action) => {
  switch (action.type) {
    case [
      CREATE_WASH_REQUEST,
      GET_FILTERED_WASHES_REQUEST,
      GET_CAR_WASH_BY_CUSTOMER_REQUEST,
    ]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_WASH_SUCCESS:
      return {
        ...state,
        wash: action.payload,
        loading: false,
        error: null,
      };
    case [
      CREATE_WASH_FAILURE,
      GET_FILTERED_WASHES_FAILURE,
      GET_CAR_WASH_BY_CUSTOMER_FAILURE,
    ]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_WASHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_WASHES_SUCCESS:
      return {
        ...state,
        washes: action.payload,
        loading: false,
        error: null,
      };
    case GET_WASHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_FILTERED_WASHES_SUCCESS:
      return {
        ...state,
        pagedWash: action.payload,
        loading: false,
        error: null,
      };
    case GET_CAR_WASH_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        pagedWash: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
