export const GET_NEW_BIKES_REQUEST = "GET_NEW_BIKES_REQUEST";
export const GET_NEW_BIKES_SUCCESS = "GET_NEW_BIKES_SUCCESS";
export const GET_NEW_BIKES_FAILURE = "GET_NEW_BIKES_FAILURE";

export const GET_BIKE_NAMES_REQUEST = "GET_BIKE_NAMES_REQUEST";
export const GET_BIKE_NAMES_SUCCESS = "GET_BIKE_NAMES_SUCCESS";
export const GET_BIKE_NAMES_FAILURE = "GET_BIKE_NAMES_FAILURE";

export const DELETE_BIKE_REQUEST = "DELETE_BIKE_REQUEST";
export const DELETE_BIKE_SUCCESS = "DELETE_BIKE_SUCCESS";
export const DELETE_BIKE_FAILURE = "DELETE_BIKE_FAILURE";

export const GET_BIKE_DETAIL_REQUEST = "GET_BIKE_DETAIL_REQUEST";
export const GET_BIKE_DETAIL_SUCCESS = "GET_BIKE_DETAIL_SUCCESS";
export const GET_BIKE_DETAIL_FAILURE = "GET_BIKE_DETAIL_FAILURE";

export const UPDATE_NEW_BIKE_REQUEST = "UPDATE_NEW_BIKE_REQUEST";
export const UPDATE_NEW_BIKE_SUCCESS = "UPDATE_NEW_BIKE_SUCCESS";
export const UPDATE_NEW_BIKE_FAILURE = "UPDATE_NEW_BIKE_FAILURE";

export const REGISTER_NEW_BIKE_REQUEST = "REGISTER_NEW_BIKE_REQUEST";
export const REGISTER_NEW_BIKE_SUCCESS = "REGISTER_NEW_BIKE_SUCCESS";
export const REGISTER_NEW_BIKE_FAILURE = "REGISTER_NEW_BIKE_FAILURE";
