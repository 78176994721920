import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLoggedInUsers } from "../../State/LoggedInUser/Action";
import AppPagination from "../Tools/AppPagination";
import moment from "moment";

const RecentLoggedInUsers = () => {
  const dispatch = useDispatch();
  const { loggedInUsers } = useSelector((state) => state);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getAllLoggedInUsers(page));
  }, [page]);

  return (
    <div className="p-4 bg-white shadow-md rounded-lg w-full max-w-3xl mx-auto">
      <h2 className="text-xl font-semibold mb-4 text-center">Recent Users</h2>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          {/* Table Head */}
          <thead className="bg-gray-200">
            <tr>
              <th className="border border-gray-300 p-2 text-gray-700">
                Phone No
              </th>
              <th className="border border-gray-300 p-2 text-gray-700">
                Customer
              </th>
              <th className="border border-gray-300 p-2 text-gray-700">Date</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {loggedInUsers.loggedInUsers?.loggedInUsers?.map((user, index) => (
              <tr
                key={user.id}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-100`}
              >
                <td className="border border-gray-300 p-2 text-center truncate">
                  {user.phone}
                </td>
                <td className="border border-gray-300 p-2 text-center truncate">
                  {user.customerName ? user.customerName : "—"}
                </td>
                <td className="border border-gray-300 p-2 text-center truncate">
                  {moment(user.lastLogin).format("lll")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4">
        <AppPagination
          setPage={setPage}
          totalPages={loggedInUsers.loggedInUsers?.totalPages}
        />
      </div>
    </div>
  );
};

export default RecentLoggedInUsers;
