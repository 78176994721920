import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const MainCarousel = ({ imageList }) => {
  const responsive = {
    0: { items: 1 },
    720: { items: 3 },
  };

  const items = imageList.map((item) => (
    <div className="w-full h-[300px] md:h-[350px]">
      <img
        src={item.imageUrl}
        alt=""
        className="w-full h-full object-cover rounded-lg"
        role="presentation"
      />
    </div>
  ));

  return (
    <div className="w-full h-[300px] md:h-[350px]">
      <AliceCarousel
        items={items}
        disableButtonsControls
        autoPlay
        autoPlayInterval={2000}
        infinite
        responsive={responsive}
      />
    </div>
  );
};

export default MainCarousel;
