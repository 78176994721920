export const GET_USED_BIKES_UNAUTHORIZED_REQUEST =
  "GET_USED_BIKES_UNAUTHORIZED_REQUEST";
export const GET_USED_BIKES_UNAUTHORIZED_SUCCESS =
  "GET_USED_BIKES_UNAUTHORIZED_SUCCESS";
export const GET_USED_BIKES_UNAUTHORIZED_FAILURE =
  "GET_USED_BIKES_UNAUTHORIZED_FAILURE";

export const GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST =
  "GET_USED_BIKE_DETAILS_UNAUTHORIZED_REQUEST";
export const GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS =
  "GET_USED_BIKE_DETAILS_UNAUTHORIZED_SUCCESS";
export const GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE =
  "GET_USED_BIKE_DETAILS_UNAUTHORIZED_FAILURE";

export const GET_USED_BIKES_REQUEST = "GET_USED_BIKES_REQUEST";
export const GET_USED_BIKES_SUCCESS = "GET_USED_BIKES_SUCCESS";
export const GET_USED_BIKES_FAILURE = "GET_USED_BIKES_FAILURE";

export const GET_USED_BIKE_DETAILS_REQUEST = "GET_USED_BIKE_DETAILS_REQUEST";
export const GET_USED_BIKE_DETAILS_SUCCESS = "GET_USED_BIKE_DETAILS_SUCCESS";
export const GET_USED_BIKE_DETAILS_FAILURE = "GET_USED_BIKE_DETAILS_FAILURE";

export const REGISTER_USED_BIKE_REQUEST = "REGISTER_USED_BIKE_REQUEST";
export const REGISTER_USED_BIKE_SUCCESS = "REGISTER_USED_BIKE_SUCCESS";
export const REGISTER_USED_BIKE_FAILURE = "REGISTER_USED_BIKE_FAILURE";
