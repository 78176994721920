import api from "../../services/api";
import {
  ADD_WORKSHOP_FAILURE,
  ADD_WORKSHOP_REQUEST,
  ADD_WORKSHOP_SUCCESS,
  GET_MY_WORKSHOPS_FAILURE,
  GET_MY_WORKSHOPS_REQUEST,
  GET_MY_WORKSHOPS_SUCCESS,
  GET_USED_BIKES_IN_WORKSHOP_FAILURE,
  GET_USED_BIKES_IN_WORKSHOP_REQUEST,
  GET_USED_BIKES_IN_WORKSHOP_SUCCESS,
  GET_WORKSHOPS_FAILURE,
  GET_WORKSHOPS_REQUEST,
  GET_WORKSHOPS_SUCCESS,
  GET_WORKSHOP_DETAILS_FAILURE,
  GET_WORKSHOP_DETAILS_REQUEST,
  GET_WORKSHOP_DETAILS_SUCCESS,
  UPLOAD_WORKSHOP_IMAGE_FAILURE,
  UPLOAD_WORKSHOP_IMAGE_REQUEST,
  UPLOAD_WORKSHOP_IMAGE_SUCCESS,
} from "./ActionType";

const getWorkshopsRequest = () => ({
  type: GET_WORKSHOPS_REQUEST,
});

const getWorkshopsSuccess = (workshops) => ({
  type: GET_WORKSHOPS_SUCCESS,
  payload: workshops,
});

const getWorkshopsFailure = (error) => ({
  type: GET_WORKSHOPS_FAILURE,
  payload: error,
});

export const getWorkshops = (page) => {
  return async (dispatch) => {
    dispatch(getWorkshopsRequest());
    try {
      const response = await api.get(`/workshops?page=${page}`);
      dispatch(getWorkshopsSuccess(response.data));
    } catch (error) {
      dispatch(getWorkshopsFailure(error.message));
    }
  };
};

const getWorkshopDetailsRequest = () => ({
  type: GET_WORKSHOP_DETAILS_REQUEST,
});

const getWorkshopDetailsSuccess = (workshop) => ({
  type: GET_WORKSHOP_DETAILS_SUCCESS,
  payload: workshop,
});

const getWorkshopDetailsFailure = (error) => ({
  type: GET_WORKSHOP_DETAILS_FAILURE,
  payload: error,
});

export const getWorkshopDetails = (id) => {
  return async (dispatch) => {
    dispatch(getWorkshopDetailsRequest());
    try {
      const response = await api.get(`/workshops/${id}`);
      dispatch(getWorkshopDetailsSuccess(response.data));
    } catch (error) {
      dispatch(getWorkshopDetailsFailure(error.message));
    }
  };
};

const getUsedBikesInWorkshopRequest = () => ({
  type: GET_USED_BIKES_IN_WORKSHOP_REQUEST,
});

const getUsedBikesInWorkshopSuccess = (usedBikes) => ({
  type: GET_USED_BIKES_IN_WORKSHOP_SUCCESS,
  payload: usedBikes,
});

const getUsedBikesInWorkshopFailure = (error) => ({
  type: GET_USED_BIKES_IN_WORKSHOP_FAILURE,
  payload: error,
});

export const getUsedBikesInWorkshop = (workshopId, page) => {
  return async (dispatch) => {
    dispatch(getUsedBikesInWorkshopRequest());
    try {
      const response = await api.get(
        `/usedBikes/workshop/${workshopId}?&page=${page}`
      );
      dispatch(getUsedBikesInWorkshopSuccess(response.data));
    } catch (error) {
      dispatch(getUsedBikesInWorkshopFailure(error.message));
    }
  };
};

const getMyWorkshopRequest = () => ({
  type: GET_MY_WORKSHOPS_REQUEST,
});

const getMyWorkshopSuccess = (workshops) => ({
  type: GET_MY_WORKSHOPS_SUCCESS,
  payload: workshops,
});

const getMyWorkshopFailure = (error) => ({
  type: GET_MY_WORKSHOPS_FAILURE,
  payload: error,
});

export const getMyWorkshops = (page) => {
  return async (dispatch) => {
    dispatch(getMyWorkshopRequest());
    try {
      const response = await api.get("/workshops/my-workshops?page=" + page);
      dispatch(getMyWorkshopSuccess(response.data));
    } catch (error) {
      dispatch(getMyWorkshopFailure(error.message));
    }
  };
};

const addWorkshopRequest = () => ({
  type: ADD_WORKSHOP_REQUEST,
});

const addWorkshopSuccess = (workshop) => ({
  type: ADD_WORKSHOP_SUCCESS,
  payload: workshop,
});

const addWorkshopFailure = (error) => ({
  type: ADD_WORKSHOP_FAILURE,
  payload: error,
});

export const addWorkshop = (data) => {
  return async (dispatch) => {
    dispatch(addWorkshopRequest());
    try {
      const response = await api.post("/workshops", data);
      dispatch(addWorkshopSuccess(response.data));
    } catch (error) {
      dispatch(addWorkshopFailure(error.message));
    }
  };
};

const uploadWorkshopImageRequest = () => ({
  type: UPLOAD_WORKSHOP_IMAGE_REQUEST,
});

const uploadWorkshopImageSuccess = (image) => ({
  type: UPLOAD_WORKSHOP_IMAGE_SUCCESS,
  payload: image,
});

const uploadWorkshopImageFailure = (error) => ({
  type: UPLOAD_WORKSHOP_IMAGE_FAILURE,
  payload: error,
});

export const uploadWorkshopImage = (input) => {
  return async (dispatch) => {
    dispatch(uploadWorkshopImageRequest());
    try {
      const response = await api.post("/workshops/images", input);
      dispatch(uploadWorkshopImageSuccess(response.data));
    } catch (error) {
      dispatch(uploadWorkshopImageFailure(error.message));
    }
  };
};
