export const ADD_STATE_REQUEST = "ADD_STATE_REQUEST";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_FAILURE = "ADD_STATE_FAILURE";
export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAILURE = "GET_STATE_FAILURE";
export const GET_STATES_REQUEST = "GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_STATES_BY_COUNTRY_REQUEST = "GET_STATES_BY_COUNTRY_REQUEST";
export const GET_STATES_BY_COUNTRY_SUCCESS = "GET_STATES_BY_COUNTRY_SUCCESS";
export const GET_STATES_BY_COUNTRY_FAILURE = "GET_STATES_BY_COUNTRY_FAILURE";
