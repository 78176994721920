import { Grid, Button, Box, Typography } from "@mui/material";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

const DealerHome = () => {
  const navigate = useNavigate();
  const handleButtonClick = (action) => () => {
    navigate(action);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        background: "linear-gradient(135deg, #1e3c72, #2a5298)",
        color: "white",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          marginBottom: 4,
          letterSpacing: 1,
          textTransform: "uppercase",
          textShadow: "2px 2px 10px rgba(0,0,0,0.3)",
          marginTop: "5vh",
          "@media (max-width: 600px)": {
            fontSize: "26px",
            marginBottom: 2,
            marginTop: "10vh",
          },
        }}
      >
        Dealer Dashboard
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{
          flex: 1,
          "@media (max-width: 600px)": {
            marginTop: "5vh",
          },
        }}
      >
        {buttons.map((button, index) => (
          <Grid item key={index}>
            <Button
              variant="contained"
              onClick={handleButtonClick(button.action)}
              sx={{
                width: 160,
                height: 160,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "20px",
                background:
                  "linear-gradient(145deg, rgba(255,255,255,0.2), rgba(255,255,255,0.1))",
                color: "white",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255,255,255,0.2)",
                transition: "all 0.3s ease-in-out",
                boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
                "&:hover": {
                  background: "rgba(255,255,255,0.3)",
                  transform: "scale(1.05)",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.4)",
                },
                "@media (max-width: 600px)": {
                  width: 130,
                  height: 130,
                },
              }}
            >
              {button.icon}
              <Typography
                sx={{
                  marginTop: 1,
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                }}
              >
                {button.label}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const buttons = [
  {
    label: "Add Workshop",
    icon: <HomeRepairServiceIcon sx={{ fontSize: 60, color: "#FFC107" }} />,
    action: "/add-workshop",
  },
  {
    label: "Add Bike",
    icon: <TwoWheelerIcon sx={{ fontSize: 60, color: "#4FC3F7" }} />, // Updated Icon
    action: "/sell-bike",
  },
  {
    label: "Show Workshop",
    icon: <VisibilityIcon sx={{ fontSize: 60, color: "#8BC34A" }} />,
    action: "/show-my-workshop",
  },
  {
    label: "Customers",
    icon: <PersonIcon sx={{ fontSize: 60, color: "#FF9800" }} />,
    action: "/interest-customers",
  },
];

export default DealerHome;
