import api from "../../services/api";
import {
  GET_DB_HEALTH_CHECK_FAILURE,
  GET_DB_HEALTH_CHECK_REQUEST,
  GET_DB_HEALTH_CHECK_SUCCESS,
} from "./ActionType";

const getDBHealthCheckRequest = () => {
  return {
    type: GET_DB_HEALTH_CHECK_REQUEST,
  };
};

const getDBHealthCheckSuccess = (dbHealthCheck) => {
  return {
    type: GET_DB_HEALTH_CHECK_SUCCESS,
    payload: dbHealthCheck,
  };
};

const getDBHealthCheckFailure = (error) => {
  return {
    type: GET_DB_HEALTH_CHECK_FAILURE,
    payload: error,
  };
};

export const getDBHealthCheck = () => async (dispatch) => {
  dispatch(getDBHealthCheckRequest());
  try {
    const response = await api.get(`/db-health-check`, {
      withCredentials: true,
    });
    dispatch(getDBHealthCheckSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(getDBHealthCheckFailure(error));
  }
};
