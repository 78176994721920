import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { color } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { cancelBooking } from "../../State/Booking/Action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CancellationModal({ bookingId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { booking } = useSelector((store) => store);
  const errorMessage = "Some error occured, please try again later";
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(cancelBooking(bookingId));
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ background: "#CD0300" }}
        onClick={handleOpen}
      >
        Cancel
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Cancel Booking
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to cancel this booking?
          </Typography>
          <div className="mt-4 w-full text-center">
            <Button
              variant="contained"
              sx={{ background: "#CD0300" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ marginLeft: "10px" }}
            >
              Close
            </Button>
            <div>
              {!booking?.cancelled && (
                <span className="text-red-700">{errorMessage}</span>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
