export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAILURE = "ADD_CITY_FAILURE";
export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_CITIES_BY_STATE_REQUEST = "GET_CITIES_BY_STATE_REQUEST";
export const GET_CITIES_BY_STATE_SUCCESS = "GET_CITIES_BY_STATE_SUCCESS";
export const GET_CITIES_BY_STATE_FAILURE = "GET_CITIES_BY_STATE_FAILURE";
