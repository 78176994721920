import { useState } from "react";
import styles from "./Brand.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import api from "../../services/api";

export default function Brand() {
  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBrandName("");
    setBrandDescription("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const brand = { brandName, brandDescription };
    setIsLoading(true);
    if (brandName !== "") {
      api
        .post("/brands", brand, {
          withCredentials: true,
        })
        .then((response) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        <header className={mainStyles.header}>Add Brand</header>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setBrandName(e.target.value)}
            label="Enter Brand Name"
          />
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            onChange={(e) => setBrandDescription(e.target.value)}
            label="Enter Brand Description"
            multiline
            rows={3}
          />
        </div>
        <div className={styles.componentContainer}>
          <Button
            className={styles.saveBtn}
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {" "}
            Save Brand
          </Button>
        </div>
        <div className={styles.componentContainer}>
          {isSaved && (
            <div>
              <p className={mainStyles.success}>Brand Saved Successfully</p>
            </div>
          )}
          {hasError && (
            <div>
              <p className={mainStyles.error}>
                Some error occurred while saving.
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
