import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../store/ContextApi";

const Home = () => {
  const { isDealer, token, currentUser } = useMyContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token && currentUser) {
      setIsLoading(false);
    }
  }, [token, currentUser]);

  useEffect(() => {
    if (!isLoading) {
      if (isDealer) {
        navigate("/dealer-home");
      } else {
        navigate("/customer-home");
      }
    }
  }, [isDealer, navigate, isLoading]);

  return null;
};

export default Home;
