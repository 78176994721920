import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminSidebar from "./AdminAreaSidebar";
import UserList from "./UserList";
import UserDetails from "./UserDetails";
import { useMyContext } from "../../store/ContextApi";
import AuditLogsDetails from "./AuditLogsDetails";
import AdminAuditLogs from "./AdminAuditLogs";
import AllBookings from "../Booking/AllBookings";
import WashList from "../Wash/WashList";
import ReviewList from "../Review/ReviewList";
import RecentLoggedInUsers from "../Users/RecentLoggedInUsers";
import EstimatedPrice from "../EstimatedPrice/EstimatedPrice";
import Expense from "../Expense/Expense";
import DBHealthCheck from "../DBHealthCheck/DBhealthCheck";
import ExceptionLog from "../ExceptionLog/ExceptionLog";
import Brand from "../Brand/Brand";
import BikeModel from "../BikeModel/BikeModel";
import NewBike from "../NewBike/NewBike";
import Workshop from "../Workshop/Workshop";
import StatusUpdate from "../Status/StatusUpdate";

const Admin = () => {
  // Access the openSidebar hook using the useMyContext hook from the ContextProvider
  const { openSidebar } = useMyContext();
  return (
    <div className="flex">
      <AdminSidebar />
      <div
        className={`transition-all overflow-hidden flex-1 duration-150 w-full min-h-[calc(100vh-74px)] ${
          openSidebar ? "lg:ml-52 ml-12" : "ml-12"
        }`}
      >
        <Routes>
          <Route path="audit-logs" element={<AdminAuditLogs />} />
          <Route path="audit-logs/:noteId" element={<AuditLogsDetails />} />
          <Route path="users" element={<UserList />} />
          <Route path="users/:userId" element={<UserDetails />} />
          <Route path="all-bookings" element={<AllBookings />} />
          <Route path="wash-list" element={<WashList />} />
          <Route path="review-list" element={<ReviewList />} />
          <Route path="recent-users" element={<RecentLoggedInUsers />} />
          <Route path="estimated-price" element={<EstimatedPrice />} />
          <Route path="expenses" element={<Expense />} />
          <Route path="db-health-check" element={<DBHealthCheck />} />
          <Route path="exception-logs" element={<ExceptionLog />} />

          <Route path="wash-list" element={<WashList />} />
          <Route path="brand" element={<Brand />} />
          <Route path="bike-model" element={<BikeModel />} />
          <Route path="new-bike" element={<NewBike />} />
          <Route path="workshop" element={<Workshop />} />
          <Route path="status-update" element={<StatusUpdate />} />
          {/* Add other routes as necessary */}
        </Routes>
      </div>
    </div>
  );
};

export default Admin;
