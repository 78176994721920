export const CREATE_WASH_REQUEST = "CREATE_WASH_REQUEST";
export const CREATE_WASH_SUCCESS = "CREATE_WASH_SUCCESS";
export const CREATE_WASH_FAILURE = "CREATE_WASH_FAILURE";

export const GET_WASHES_REQUEST = "GET_WASHES_REQUEST";
export const GET_WASHES_SUCCESS = "GET_WASHES_SUCCESS";
export const GET_WASHES_FAILURE = "GET_WASHES_FAILURE";

export const UPDATE_WASH_REQUEST = "UPDATE_WASH_REQUEST";
export const UPDATE_WASH_SUCCESS = "UPDATE_WASH_SUCCESS";
export const UPDATE_WASH_FAILURE = "UPDATE_WASH_FAILURE";

export const GET_FILTERED_WASHES_REQUEST = "GET_FILTERED_WASHES_REQUEST";
export const GET_FILTERED_WASHES_SUCCESS = "GET_FILTERED_WASHES_SUCCESS";
export const GET_FILTERED_WASHES_FAILURE = "GET_FILTERED_WASHES_FAILURE";

export const GET_CAR_WASH_BY_CUSTOMER_REQUEST =
  "GET_CAR_WASH_BY_CUSTOMER_REQUEST";
export const GET_CAR_WASH_BY_CUSTOMER_SUCCESS =
  "GET_CAR_WASH_BY_CUSTOMER_SUCCESS";
export const GET_CAR_WASH_BY_CUSTOMER_FAILURE =
  "GET_CAR_WASH_BY_CUSTOMER_FAILURE";
