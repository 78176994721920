import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const AboutPage = () => {
  return (
    <div className="font-sans p-6 max-w-3xl mx-auto text-gray-800">
      <h1 className="text-center text-blue-600 text-4xl font-extrabold mb-4">
        About Us
      </h1>
      <p className="text-lg text-gray-700 text-center mb-6">
        Welcome to <span className="font-bold text-blue-500">Blue Bike</span>,
        your go-to platform for buying and selling used bikes. We connect bike
        enthusiasts with trusted sellers, making it easier than ever to find
        your dream ride at the best price. Our dealer-friendly platform offers a
        vast selection of bikes, ensuring that both individual buyers and
        professional dealers can find what they need.
      </p>

      <h2 className="text-2xl font-semibold text-blue-500 mt-6">
        Why Choose Blue Bike?
      </h2>
      <p className="text-gray-700 mt-2">
        At Blue Bike, we ensure a seamless experience for both buyers and
        sellers. Here’s why you should choose us:
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-2 mt-3">
        <li>
          <strong className="text-blue-500">Vast Selection:</strong> Browse a
          wide range of used bikes from top brands.
        </li>
        <li>
          <strong className="text-blue-500">Dealer-Friendly:</strong> A
          dedicated platform that supports both individual and professional
          sellers.
        </li>
        <li>
          <strong className="text-blue-500">Trusted Sellers:</strong> Verified
          listings to ensure secure transactions.
        </li>
        <li>
          <strong className="text-blue-500">Easy Process:</strong> User-friendly
          platform for hassle-free buying and selling.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-blue-500 mt-6">Our Mission</h2>
      <p className="text-gray-700 mt-2">
        We aim to make buying and selling used bikes more accessible,
        transparent, and reliable. Whether you're looking for an affordable
        daily commuter or a high-performance superbike,
        <span className="font-bold text-blue-500">Blue Bike</span> has something
        for everyone.
      </p>

      <h3 className="text-center text-xl font-bold text-gray-800 mt-10">
        Follow Us
      </h3>
      <p className="text-center text-gray-700 mt-2">
        Stay updated with the latest listings, offers, and trends in the biking
        world:
      </p>
      <div className="flex justify-center gap-6 mt-4">
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
          className="text-blue-600 hover:text-blue-800"
        >
          <FaFacebook size={30} />
        </a>
        <a
          href="https://twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          className="text-blue-400 hover:text-blue-600"
        >
          <FaTwitter size={30} />
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
          className="text-pink-500 hover:text-pink-700"
        >
          <FaInstagram size={30} />
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
          className="text-blue-700 hover:text-blue-900"
        >
          <FaLinkedin size={30} />
        </a>
      </div>
    </div>
  );
};

export default AboutPage;
