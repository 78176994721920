import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { LocationOn, Star } from "@mui/icons-material";
import AppPagination from "../Tools/AppPagination";
import api from "../../services/api";
import workshopsImage from "./images/workshops.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsedBikesInWorkshop,
  getWorkshops,
} from "../../State/Workshop/Action";
import { useMyContext } from "../../store/ContextApi";

export default function WorkshopList() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let role = localStorage.getItem("role");
  const { isDealer, isAdmin } = useMyContext();
  const workshops = useSelector(
    (store) => store.workshopResponse.workshopPaginated.workshops
  );
  const totalPages = useSelector(
    (store) => store.workshopResponse.workshopPaginated.totalPages
  );

  useEffect(() => {
    dispatch(getWorkshops(page));
  }, [dispatch, page]);

  const handleViewMore = (e, workshopId) => {
    navigate(`/workshopDetail?workshopId=${workshopId}`);
  };

  const handleUpdate = (e, workshopId) => {
    navigate(`/updateWorkshop?workshopId=${workshopId}`);
  };

  return (
    <div className="w-full min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={workshopsImage}
          alt="Workshops"
          className="w-full h-72 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <h1 className="text-white text-4xl font-bold">Find Your Workshop</h1>
        </div>
      </div>

      {/* Workshops List */}
      <div className="container mx-auto px-4 py-10">
        <h2 className="text-3xl font-semibold text-gray-800 text-center mb-8">
          Available Workshops
        </h2>

        {/* Workshop Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {workshops &&
            workshops.map((workshop) => (
              <div
                key={workshop.workshopId}
                className="bg-white shadow-lg rounded-lg overflow-hidden transition transform hover:-translate-y-2 duration-300 flex flex-col"
              >
                {/* Workshop Image */}
                <div className="relative">
                  <img
                    src={workshop.imageUrl}
                    alt="Workshop"
                    className="w-full h-48 object-cover cursor-pointer"
                    onClick={(e) => handleViewMore(e, workshop.workshopId)}
                  />
                </div>

                {/* Workshop Details */}
                <div className="p-5 text-center flex-grow">
                  <h3 className="text-xl font-bold text-gray-900">
                    {workshop.name}
                  </h3>

                  {/* Rating */}
                  <div className="flex justify-center gap-1 mt-1 text-yellow-500">
                    {[...Array(workshop.rating)].map((_, i) => (
                      <Star key={i} fontSize="small" />
                    ))}
                  </div>

                  {/* Years Old */}
                  <p className="text-gray-600 mt-2">
                    {workshop.yearsOld} year(s) old
                  </p>

                  {/* Location */}
                  <div className="flex items-center justify-center text-gray-500 mt-2">
                    <LocationOn fontSize="small" />
                    <span className="ml-1">
                      {workshop.address}, {workshop.city}
                    </span>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-center gap-4 mt-6 mb-6">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleViewMore(e, workshop.workshopId)}
                    className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition"
                  >
                    View More
                  </Button>

                  {isAdmin && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={(e) => handleUpdate(e, workshop.workshopId)}
                      className="bg-red-500 text-white px-6 py-3 rounded-md hover:bg-red-600 transition"
                    >
                      Update
                    </Button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Pagination - Always at Bottom */}
      <div className="flex justify-center mt-12 mb-8">
        {totalPages > 1 && (
          <AppPagination totalPages={totalPages} setPage={setPage} />
        )}
      </div>
    </div>
  );
}
