import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "../../services/api";
import { FcGoogle } from "react-icons/fc";
import { FaGithub } from "react-icons/fa";
import Divider from "@mui/material/Divider";
import Buttons from "../../utils/Buttons";
import InputField from "../InputField/InputField";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMyContext } from "../../store/ContextApi";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { Email } from "@mui/icons-material";

const Signup = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);
  // Access the token and setToken function using the useMyContext hook from the ContextProvider
  const { token } = useMyContext();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  //react hook form initialization
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: "",
      otp: "",
      phoneNumber: "",
      email: "",
    },
    mode: "onTouched",
  });

  useEffect(() => {
    setRole("ROLE_USER");
  }, []);

  const onSubmitHandler = async (data) => {
    const { phoneNumber, fullName, otp, email } = data;
    const input = {
      phoneNumber,
      fullName,
      otp,
      email,
      role: [role],
    };

    try {
      setLoading(true);
      console.log(input);
      const response = await api.post("/auth/public/signup-by-phone", input);

      toast.success("Reagister Successful");
      reset();
      if (response.data) {
        navigate("/login");
      }
    } catch (error) {
      // Add an error programmatically by using the setError function provided by react-hook-form
      //setError(keyword,message) => keyword means the name of the field where I want to show the error

      if (
        error?.response?.data?.message ===
        "Error: Phone number is already used!"
      ) {
        setError("phoneNumber", {
          message: "Error: Phone number is already used!",
        });
      } else if (
        error?.response?.data?.message === "Error: Email Id is already used!"
      ) {
        setError("email", {
          message: "Error: Email Id is already used!",
        });
      } else if (error?.response?.data?.message === "Error: Invalid OTP!") {
        setError("otp", {
          message: "Error: Invalid OTP!",
        });
      } else {
        toast.error("Some error occured. Please try again");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetOtp = () => {
    return async () => {
      const phone = document.getElementById("phoneNumber").value;
      console.log(phone);
      // const input = {
      //   phoneNumber: phone,
      // };

      try {
        setLoading(true);
        // console.log(input);
        const response = await api.post("/otp/phone/" + phone);
        if (response.data) {
          toast.success("OTP sent successfully");
        } else {
          toast.error("OTP not sent");
        }
      } catch (error) {
        // Add an error programmatically by using the setError function provided by react-hook-form
        //setError(keyword,message) => keyword means the name of the field where I want to show the error

        toast.error("Please fill all the fields");
      } finally {
        setLoading(false);
      }
    };
  };

  //if there is token  exist navigate to the user to the home page if he tried to access the login page
  useEffect(() => {
    if (token) navigate("/");
  }, [navigate, token]);

  return (
    <div className="min-h-[calc(100vh-74px)] flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="sm:w-[450px] w-[360px]  shadow-custom py-6 sm:px-8 px-4"
      >
        <div>
          <h1 className="font-montserrat text-center font-bold text-2xl">
            Register
          </h1>
          {/* <p className="text-slate-600 text-center">
            Enter your credentials to create new account
          </p> */}
          <div className="flex items-center justify-between gap-1 py-5 ">
            <a
              href={`${apiUrl}/oauth2/authorization/google`}
              className="flex gap-1 items-center justify-center flex-1 border p-2 shadow-sm shadow-slate-200 rounded-md hover:bg-slate-300 transition-all duration-300"
            >
              <span>
                <FcGoogle className="text-2xl" />
              </span>
              <span className="font-semibold sm:text-customText text-xs">
                Login with Google
              </span>
            </a>
          </div>

          <Divider className="font-semibold">OR</Divider>
        </div>

        {/* <div className="flex flex-col gap-2">
          <InputField
            label="UserName"
            required
            id="username"
            type="text"
            message="*UserName is required"
            placeholder="type your username"
            register={register}
            errors={errors}
          />{" "}
          <InputField
            label="Full Name"
            required
            id="fullName"
            type="text"
            message="*Full Name is required"
            placeholder="type your name"
            register={register}
            errors={errors}
          />{" "}
          <InputField
            label="Email"
            required
            id="email"
            type="email"
            message="*Email is required"
            placeholder="type your email"
            register={register}
            errors={errors}
          />
          <InputField
            label="Password"
            required
            id="password"
            type="password"
            message="*Password is required"
            placeholder="type your password"
            register={register}
            errors={errors}
            min={6}
          />
        </div>
        <Buttons
          disabled={loading}
          onClickhandler={() => {}}
          className="bg-customRed font-semibold flex justify-center text-white w-full py-2 hover:text-slate-400 transition-colors duration-100 rounded-sm my-3"
          type="text"
        >
          {loading ? <span>Loading...</span> : "Register"}
        </Buttons> 

        <p className="text-center text-sm text-slate-700 mt-2">
          Already have an account?{" "}
          <Link
            className="font-semibold underline hover:text-black"
            to="/login"
          >
            Login
          </Link>
        </p> 

        <Divider className="font-semibold">OR</Divider>
        */}
        <div>
          <InputField
            label="Full Name"
            required
            id="fullName"
            type="text"
            message="*Full Name is required"
            placeholder="type your name"
            register={register}
            errors={errors}
          />{" "}
          <div className="flex flex-row justify-between">
            <InputField
              label="Phone Number"
              required
              id="phoneNumber"
              type="phone"
              message="*Phone is required"
              placeholder="type your phone number"
              register={register}
              errors={errors}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />{" "}
            <div className="mt-7">
              {!otpSent && (
                <Button
                  variant="contained"
                  className="h-10"
                  onClick={handleGetOtp()}
                >
                  Get OTP{" "}
                </Button>
              )}
            </div>
          </div>
          <InputField
            label="OTP"
            required
            id="otp"
            type="number"
            message="*OTP is required"
            placeholder="enter otp"
            register={register}
            errors={errors}
          />
          <InputField
            label="email"
            required
            id="email"
            type="email"
            message="*Email is required"
            placeholder="enter email"
            register={register}
            errors={errors}
          />
          <div className="mt-4">
            <Button
              disabled={loading}
              className="bg-customRed font-semibold flex justify-center text-white w-full py-2 hover:text-slate-400 transition-colors duration-100 rounded-sm my-3"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </div>
        </div>
        <p className="text-center text-sm text-slate-700 mt-6">
          Already have an account?{" "}
          <Link
            className="font-semibold underline hover:text-black"
            to="/login"
          >
            Sign In
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Signup;
