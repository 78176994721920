import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import AppPagination from "../Tools/AppPagination";
import { getCustomerUsedBikeInterest } from "../../State/Interest/Action";

const CustomerUsedBikeInterest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usedBikeInterest = useSelector(
    (store) => store.interestResponse.interestPaginated.interestedUsedBikes
  );
  const totalPages = useSelector(
    (store) => store.interestResponse.interestPaginated.totalPages
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getCustomerUsedBikeInterest(page));
  }, [dispatch, page]);

  const handleCardClick = (bikeId) => {
    navigate(`/usedBikeDetail?bikeId=${bikeId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          fontWeight: "bold",
          color: "primary.main",
          textTransform: "uppercase",
          letterSpacing: 1,
        }}
      >
        Interested Used Bikes
      </Typography>
      <Grid container spacing={3}>
        {usedBikeInterest?.map((bike, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ maxWidth: 345, boxShadow: 3, cursor: "pointer" }}
              onClick={() => handleCardClick(bike.bikeId)}
            >
              <CardMedia
                component="img"
                height="200"
                image={bike.imageUrl}
                alt={bike.bikeName}
              />
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    {bike.bikeName}
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardClick(bike.bikeId);
                    }}
                    sx={{ color: "primary.main" }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <AttachMoneyIcon sx={{ color: "green" }} />
                  <Typography variant="body2" color="text.secondary">
                    <strong>Price:</strong> ₹{bike.price}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <PersonIcon sx={{ color: "blue" }} />
                  <Typography variant="body2" color="text.secondary">
                    <strong>Seller:</strong> {bike.sellerName}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={3}>
        <AppPagination setPage={setPage} totalPages={totalPages} />
      </Box>
    </Box>
  );
};

export default CustomerUsedBikeInterest;
