import api from "../../services/api";
import {
  SEND_SMS_OTP_FAILURE,
  SEND_SMS_OTP_REQUEST,
  SEND_SMS_OTP_SUCCESS,
  SMS_OTP_SIGNIN_FAILURE,
  SMS_OTP_SIGNIN_REQUEST,
  SMS_OTP_SIGNIN_SUCCESS,
} from "./ActionType";

const sendSmsOtpRequest = () => {
  return {
    type: SEND_SMS_OTP_REQUEST,
  };
};

const sendSmsOtpSuccess = (success) => {
  return {
    type: SEND_SMS_OTP_SUCCESS,
    payload: success,
  };
};

const sendSmsOtpFailure = (error) => {
  return {
    type: SEND_SMS_OTP_FAILURE,
    payload: error,
  };
};

export const sendSmsOtp = (phone) => async (dispatch) => {
  dispatch(sendSmsOtpRequest());
  try {
    const response = await api.post("/otp/phone/" + phone, {
      phone,
    });
    dispatch(sendSmsOtpSuccess(response.data));
  } catch (error) {
    dispatch(sendSmsOtpFailure(error));
  }
};

const smsOtpSigninRequest = () => {
  return {
    type: SMS_OTP_SIGNIN_REQUEST,
  };
};

const smsOtpSigninSuccess = (success) => {
  return {
    type: SMS_OTP_SIGNIN_SUCCESS,
    payload: success,
  };
};

const smsOtpSigninFailure = (error) => {
  return {
    type: SMS_OTP_SIGNIN_FAILURE,
    payload: error,
  };
};

export const smsOtpSignin = (otpLoginRequest) => async (dispatch) => {
  dispatch(smsOtpSigninRequest());
  try {
    const response = await api.post("/auth/public/otp-signin", otpLoginRequest);
    dispatch(smsOtpSigninSuccess(response.data));
  } catch (error) {
    dispatch(smsOtpSigninFailure(error));
  }
};

// const handleSuccessfulLogin = (token, decodedToken) => {
//     const user = {
//       username: decodedToken.sub,
//       roles: decodedToken.roles ? decodedToken.roles.split(",") : [],
//     };
//     localStorage.setItem("JWT_TOKEN", token);
//     localStorage.setItem("USER", JSON.stringify(user));

//     //store the token on the context state  so that it can be shared any where in our application by context provider
//     setToken(token);

//     navigate("/create-booking");
//   };
