import {
  ADD_CITY_REQUEST,
  GET_CITIES_BY_STATE_FAILURE,
  GET_CITIES_BY_STATE_REQUEST,
  GET_CITIES_BY_STATE_SUCCESS,
  GET_CITIES_FAILURE,
} from "./ActionType";

const initialState = {
  city: {},
  cities: [],
  loading: false,
  error: null,
};

export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case [ADD_CITY_REQUEST, GET_CITIES_BY_STATE_REQUEST]:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "ADD_CITY_SUCCESS":
      return {
        ...state,
        city: action.payload,
        loading: false,
        error: null,
      };
    case "ADD_CITY_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_CITY_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CITY_SUCCESS":
      return {
        ...state,
        city: action.payload,
        loading: false,
        error: null,
      };
    case "GET_CITY_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_CITIES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CITIES_SUCCESS":
      return {
        ...state,
        cities: action.payload,
        loading: false,
        error: null,
      };
    case [GET_CITIES_FAILURE, GET_CITIES_BY_STATE_FAILURE]:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CITIES_BY_STATE_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
