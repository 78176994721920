import { thunk } from "redux-thunk";
import { bookingReducer } from "./Booking/Reducer";
import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { stateReducer } from "./State/Reducer";
import { workerReducer } from "./Worker/Reducer";
import { washReducer } from "./Wash/Reducer";
import { contactUsReducer } from "./ContactUs/Reducer";
import { reviewsReducer } from "./Reviews/Reducer";
import { authReducer } from "./Auth/Reducer";
import { loggedInUserReducer } from "./LoggedInUser/Reducer";
import { estimatedPriceReducer } from "./EstimatedPrice/Reducer";
import { cityReducer } from "./City/Reducer";
import { expenseReducer } from "./Expense/Reducer";
import { dbHealthCheckReducer } from "./DBHealthCheck/Reducer";
import { exceptionLogReducer } from "./ExceptionLog/Reducer";
import { usedBikeReducer } from "./UsedBike/Reducer";
import { newBikeReducer } from "./NewBike/Reducer";
import { workshopReducer } from "./Workshop/Reducer";
import { countryReducer } from "./Country/Reducer";
import { colorReducer } from "./Color/Reducer";
import { modelReducer } from "./Model/Reducer";
import { brandReducer } from "./Brand/Reducer";
import { interestReducer } from "./Interest/Reducer";

const rootReducers = combineReducers({
  booking: bookingReducer,

  worker: workerReducer,
  wash: washReducer,
  contactUs: contactUsReducer,
  review: reviewsReducer,
  auth: authReducer,
  loggedInUsers: loggedInUserReducer,
  estimatedPrice: estimatedPriceReducer,

  expense: expenseReducer,
  dbHealthCheck: dbHealthCheckReducer,
  exceptionLog: exceptionLogReducer,

  usedBikesResponse: usedBikeReducer,
  newBikesResponse: newBikeReducer,
  workshopResponse: workshopReducer,
  cityResponse: cityReducer,
  stateResponse: stateReducer,
  countryResponse: countryReducer,
  colorResponse: colorReducer,
  modelResponse: modelReducer,
  brandResponse: brandReducer,
  interestResponse: interestReducer,
});

export const store = legacy_createStore(rootReducers, applyMiddleware(thunk));
