import api from "../../services/api";
import {
  GET_ALL_LOGGED_IN_USERS_FAILURE,
  GET_ALL_LOGGED_IN_USERS_REQUEST,
  GET_ALL_LOGGED_IN_USERS_SUCCESS,
} from "./ActionType";

const getAllLoggedInUsersRequest = () => {
  return {
    type: GET_ALL_LOGGED_IN_USERS_REQUEST,
  };
};

const getAllLoggedInUsersSuccess = (loggedInUsers) => {
  return {
    type: GET_ALL_LOGGED_IN_USERS_SUCCESS,
    payload: loggedInUsers,
  };
};

const getAllLoggedInUsersFailure = (error) => {
  return {
    type: GET_ALL_LOGGED_IN_USERS_FAILURE,
    payload: error,
  };
};

export const getAllLoggedInUsers = (page) => async (dispatch) => {
  dispatch(getAllLoggedInUsersRequest());
  try {
    const response = await api.get(`/public/logged-in-users?page=` + page, {
      withCredentials: true,
    });
    dispatch(getAllLoggedInUsersSuccess(response.data));
    console.log(response.data);
  } catch (error) {
    dispatch(getAllLoggedInUsersFailure(error));
  }
};
