import {
  ADD_EXPENSE_FAILURE,
  ADD_EXPENSE_REQUEST,
  ADD_EXPENSE_SUCCESS,
  GET_EXPENSE_FAILURE,
  GET_EXPENSE_REQUEST,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSE_SUMMARY_FAILURE,
  GET_EXPENSE_SUMMARY_REQUEST,
  GET_EXPENSE_SUMMARY_SUCCESS,
} from "./ActionType";

const initialState = {
  expenses: [],
  expenseCreated: {},
  expenseSummary: {},
  loading: false,
  error: null,
};

export const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_EXPENSE_SUCCESS:
      return {
        ...state,
        expenseCreated: action.payload,
        loading: false,
        error: null,
      };
    case ADD_EXPENSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EXPENSE_SUCCESS:
      return {
        ...state,
        expenses: action.payload,
        loading: false,
        error: null,
      };
    case GET_EXPENSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_EXPENSE_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EXPENSE_SUMMARY_SUCCESS:
      return {
        ...state,
        expenseSummary: action.payload,
        loading: false,
        error: null,
      };
    case GET_EXPENSE_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
