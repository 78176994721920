import api from "../../services/api";
import {
  ADD_EXPENSE_FAILURE,
  ADD_EXPENSE_REQUEST,
  ADD_EXPENSE_SUCCESS,
  GET_EXPENSE_FAILURE,
  GET_EXPENSE_REQUEST,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSE_SUMMARY_FAILURE,
  GET_EXPENSE_SUMMARY_REQUEST,
  GET_EXPENSE_SUMMARY_SUCCESS,
} from "./ActionType";

const addExpenseRequest = () => {
  return {
    type: ADD_EXPENSE_REQUEST,
  };
};

const addExpenseSuccess = (expenseCreated) => {
  return {
    type: ADD_EXPENSE_SUCCESS,
    payload: expenseCreated,
  };
};

const addExpenseFailure = (error) => {
  return {
    type: ADD_EXPENSE_FAILURE,
    payload: error,
  };
};

export const addExpense = (expense) => {
  return async (dispatch) => {
    dispatch(addExpenseRequest());
    try {
      const response = await api.post(`/expense`, expense, {
        withCredentials: true,
      });
      dispatch(addExpenseSuccess(response.data));
    } catch (error) {
      dispatch(addExpenseFailure(error.message));
    }
  };
};

const getExpenseRequest = () => {
  return {
    type: GET_EXPENSE_REQUEST,
  };
};

const getExpenseSuccess = (expense) => {
  return {
    type: GET_EXPENSE_SUCCESS,
    payload: expense,
  };
};

const getExpenseFailure = (error) => {
  return {
    type: GET_EXPENSE_FAILURE,
    payload: error,
  };
};

export const getExpense = (filter) => {
  return async (dispatch) => {
    dispatch(getExpenseRequest());
    try {
      const response = await api.get(`/expense${filter}`, {
        withCredentials: true,
      });
      dispatch(getExpenseSuccess(response.data));
    } catch (error) {
      dispatch(getExpenseFailure(error.message));
    }
  };
};

const getExpenseSummaryRequest = () => {
  return {
    type: GET_EXPENSE_SUMMARY_REQUEST,
  };
};

const getExpenseSummarySuccess = (expenseSummary) => {
  return {
    type: GET_EXPENSE_SUMMARY_SUCCESS,
    payload: expenseSummary,
  };
};

const getExpenseSummaryFailure = (error) => {
  return {
    type: GET_EXPENSE_SUMMARY_FAILURE,
    payload: error,
  };
};

export const getExpenseSummary = () => {
  return async (dispatch) => {
    dispatch(getExpenseSummaryRequest());
    try {
      const response = await api.get(`/expense/summary`, {
        withCredentials: true,
      });
      dispatch(getExpenseSummarySuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(getExpenseSummaryFailure(error.message));
    }
  };
};
