import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookingsByUserId } from "../../State/Booking/Action";
import moment from "moment";
import UpdateBookingDialog from "./UpdateBookingDialog";
import CancellationModal from "../Tools/CancellationModal";
import { Card, CardContent, Typography, Box, IconButton } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventIcon from "@mui/icons-material/Event";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export default function MyBookings() {
  const dispatch = useDispatch();
  const { booking } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getAllBookingsByUserId());
  }, [dispatch]);

  const getStatusStyles = (status) => {
    switch (status) {
      case "BOOKED":
        return "bg-blue-100 text-blue-600";
      case "COMPLETED":
        return "bg-green-100 text-green-600";
      case "CANCELLED":
        return "bg-red-100 text-red-600";
      case "PENDING":
        return "bg-yellow-100 text-yellow-600";
      default:
        return "bg-gray-100 text-gray-600";
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-center font-extrabold text-2xl py-4">My Bookings</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {booking.bookings?.map((bookingItem) => (
          <Card
            key={bookingItem.bookingId}
            className="border-2 border-blue-500 shadow-md hover:shadow-2xl transform hover:scale-105 transition-all duration-300"
          >
            <CardContent>
              {/* Header with Vehicle & Type */}
              <Box className="flex items-center justify-between border-b pb-2">
                <Typography
                  variant="h6"
                  className="font-bold text-blue-600 flex items-center"
                >
                  <DirectionsCarIcon className="mr-2 text-blue-400" />
                  {bookingItem.vehicle} - {bookingItem.type}
                </Typography>
                <Typography
                  className={`px-3 py-1 text-sm rounded-md font-medium ${getStatusStyles(
                    bookingItem.status
                  )}`}
                >
                  {bookingItem.status}
                </Typography>
              </Box>

              {/* Booking Details */}
              <Box className="py-3 space-y-2">
                <Typography
                  variant="body2"
                  className="text-gray-700 flex items-center"
                >
                  <ConfirmationNumberIcon className="mr-2 text-gray-500" />
                  <strong>Booking ID:</strong> {bookingItem.bookingNumber}
                </Typography>

                <Typography
                  variant="body2"
                  className="text-gray-700 flex items-center"
                >
                  <EventIcon className="mr-2 text-gray-500" />
                  <strong>Date:</strong>{" "}
                  {moment(bookingItem.scheduledAt).format("lll")}
                </Typography>

                <Typography
                  variant="body2"
                  className="text-gray-700 flex items-center"
                >
                  <MonetizationOnIcon className="mr-2 text-gray-500" />
                  <strong>Estimated Price:</strong> ₹
                  {Math.round(bookingItem.price)}
                </Typography>
              </Box>

              {/* Action Buttons */}
              <Box className="flex justify-between items-center mt-4">
                {/* Cancel Button (Only Show When Applicable) */}
                {bookingItem.status !== "CANCELLED" &&
                  !bookingItem.washCreated &&
                  bookingItem.status !== "BOOKED" && (
                    <CancellationModal bookingId={bookingItem.bookingId} />
                  )}

                {/* Update Booking Button */}
                {bookingItem.status !== "CANCELLED" &&
                  !bookingItem.washCreated && (
                    <IconButton>
                      <UpdateBookingDialog
                        bookingId={bookingItem.bookingId}
                        vehicle={bookingItem.vehicle.toUpperCase()}
                        washType={bookingItem.type}
                        bookingAt={bookingItem.scheduledAt}
                        address={bookingItem.address}
                      />
                    </IconButton>
                  )}
              </Box>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
