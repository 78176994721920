import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../../State/City/Action";
import {
  getEstimatedPriceList,
  updateEstimatedPrice,
} from "../../State/EstimatedPrice/Action";

const EstimatedPrice = () => {
  const [priceDetails, setPriceDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [cityId, setCityId] = useState("");
  const { city, estimatedPrice, updated } = useSelector((state) => state);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCityChange = (e) => {
    setCityId(e.target.value);
  };

  const handleUpdateClick = (price) => {
    setSelectedRow(price);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleSave = () => {
    // Save the updated price details
    console.log(selectedRow);
    dispatch(updateEstimatedPrice(selectedRow));
    setSelectedRow(null);
    setDialogOpen(false);
    // window.location.reload();
  };

  const handleDialogChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = [
      "basePrice",
      "travelCharge",
      "additionalCharge",
      "discount",
    ].includes(name)
      ? parseFloat(value) || 0
      : value;

    setSelectedRow((prev) => {
      const updatedRow = { ...prev, [name]: parsedValue };

      if (
        ["basePrice", "travelCharge", "additionalCharge", "discount"].includes(
          name
        )
      ) {
        const base = updatedRow.basePrice || 0;
        const travel = updatedRow.travelCharge || 0;
        const additional = updatedRow.additionalCharge || 0;
        const discountVal = updatedRow.discount || 0;

        const subtotal = base + travel + additional - discountVal;
        const gst = subtotal * 0.18; // 18% GST
        const total = subtotal + gst;

        updatedRow.gst = parseFloat(gst.toFixed(2));
        updatedRow.total = Math.round(total);
      }
      return updatedRow;
    });
  };

  useEffect(() => {
    dispatch(getCities());
  }, []);

  useEffect(() => {
    if (cityId) {
      dispatch(getEstimatedPriceList(cityId));
    }
  }, [cityId]);

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: "900px", mx: "auto" }}>
      <Typography variant="h5" align="center" gutterBottom>
        Estimated Price Details
      </Typography>
      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel>City</InputLabel>
          <Select name="city" value={cityId} onChange={handleCityChange}>
            {city.cities.map((city) => (
              <MenuItem key={city.cityId} value={city.cityId}>
                {city.cityName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Paper sx={{ overflowX: "auto" }}>
            <table className="w-full border-collapse text-sm text-center">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="border p-4">Car Type</th>
                  <th className="border p-4">Bike Type</th>
                  <th className="border p-4">Wash Type</th>
                  <th className="border p-4">Pin Code</th>
                  <th className="border p-4">Base Price</th>
                  <th className="border p-4">Travel Charge</th>
                  <th className="border p-4">Additional Charge</th>
                  <th className="border p-4">Discount</th>
                  <th className="border p-4">GST</th>
                  <th className="border p-4">Total</th>
                  <th className="border p-4">Remarks</th>
                  <th className="border p-4">Update</th>
                </tr>
              </thead>
              <tbody>
                {estimatedPrice.estimatedPrice.map((price, index) => (
                  <tr
                    key={price.id}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.carType}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.bikeType}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.washType}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.pinCode}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.basePrice}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.travelCharge}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.additionalCharge}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.discount}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {parseFloat(price.gst).toFixed(2)}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {Math.round(price.total)}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Typography variant="subtitle1">
                        {price.remarks}
                      </Typography>
                    </td>
                    <td className="border p-4">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdateClick(price)}
                      >
                        Update
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Price Details</DialogTitle>
        <DialogContent>
          {selectedRow && (
            <>
              <Typography variant="subtitle1">
                Car Type: {selectedRow.carType}
              </Typography>
              <Typography variant="subtitle1">
                Bike Type: {selectedRow.bikeType}
              </Typography>
              <Typography variant="subtitle1">
                Wash Type: {selectedRow.washType}
              </Typography>
              <Typography variant="subtitle1">
                Pin Code: {selectedRow.pinCode}
              </Typography>
              {[
                "basePrice",
                "travelCharge",
                "additionalCharge",
                "discount",
              ].map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  margin="dense"
                  label={key}
                  name={key}
                  value={selectedRow[key]}
                  onChange={handleDialogChange}
                />
              ))}
              <TextField
                fullWidth
                margin="dense"
                label="GST"
                value={selectedRow.gst?.toFixed(2) || ""}
                disabled
              />
              <TextField
                fullWidth
                margin="dense"
                label="Total"
                value={selectedRow.total || ""}
                disabled
              />
              <TextField
                fullWidth
                margin="dense"
                label="Remarks"
                value={selectedRow.remarks}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EstimatedPrice;
