import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";
import styles from "./NewBike.module.css";
import mainStyles from "../Main.module.css";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";
import AppPagination from "../Tools/AppPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBike,
  getBikeNames,
  getNewBikes,
} from "../../State/NewBike/Action";
import { useMyContext } from "../../store/ContextApi";

export default function NewBikeList() {
  const [bikeId, setBikeId] = useState("");
  const [page, setPage] = useState(0);
  const [model, setModel] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAdmin } = useMyContext();

  const bikes = useSelector(
    (store) => store.newBikesResponse.newBikesPaged.bikes
  );
  const totalPages = useSelector(
    (store) => store.newBikesResponse.newBikesPaged.totalPages
  );
  const bikeNames = useSelector(
    (store) => store.newBikesResponse.bikeNames.bikeModelNames
  );

  const deleted = useSelector((store) => store.newBikesResponse.bikeDeleted);

  useEffect(() => {
    const modelId = new URLSearchParams(location.search).get("model");
    if (modelId) {
      setModel(modelId);
    }
    dispatch(getBikeNames());
    loadBikes();
  }, [page, model]);

  const loadBikes = () => {
    dispatch(getNewBikes(page, model));
  };

  const handleUpdate = (bikeId) =>
    navigate(`/update-new-bike?bikeId=${bikeId}`);
  const handleDelete = () => {
    dispatch(deleteBike(bikeId));
    if (deleted) {
      api.put(`/newBikes/${bikeId}/delete`).then(() => {
        setOpen(false);
        loadBikes();
        window.scrollTo(0, 0);
      });
    }
  };
  const handleViewMore = (bikeId) =>
    navigate(`/newBikeDetail?bikeId=${bikeId}`);
  const handleBikeChange = (modelObj) => {
    setModel(modelObj ? modelObj.id : ""); // Check for null before accessing 'id'
  };

  return (
    <div>
      <div className="text-center py-4 text-2xl font-bold text-primary">
        New Bikes
      </div>
      <Box display="flex" justifyContent="center" mb={2}>
        <Autocomplete
          onChange={(event, value) => handleBikeChange(value)}
          options={bikeNames}
          getOptionLabel={(option) => option.name}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setModel(""); // Reset model when "X" is clicked
            }
          }}
          sx={{
            width: 300,
            backgroundColor: "white",
            borderRadius: "20px",
            boxShadow: 3,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Bike"
              variant="outlined"
              sx={{ borderRadius: "20px" }}
            />
          )}
        />
      </Box>
      <Grid container spacing={3} justifyContent="center">
        {bikes?.map((bike) => (
          <Grid item xs={12} sm={6} md={4} key={bike.bikeId}>
            <Card sx={{ boxShadow: 5, borderRadius: "15px" }}>
              <CardMedia
                component="img"
                height="200"
                image={bike.imageUrl}
                alt={bike.model}
                onClick={() => handleViewMore(bike.bikeId)}
                sx={{
                  cursor: "pointer",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              />
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6" fontWeight="bold">
                  {bike.brand} {bike.model}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {bike.engineCapacity} CC
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="secondary.main"
                >
                  ₹{bike.exShowroomPrice}/-
                </Typography>
                <Box display="flex" justifyContent="center" mt={2} gap={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Visibility />}
                    onClick={() => handleViewMore(bike.bikeId)}
                  >
                    View
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Edit />}
                        onClick={() => handleUpdate(bike.bikeId)}
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => {
                          setBikeId(bike.bikeId);
                          setOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={3}>
        <AppPagination setPage={setPage} totalPages={totalPages} />
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle textAlign="center" fontWeight="bold">
          Delete Bike?
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            Are you sure you want to delete this bike?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" startIcon={<Delete />}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
