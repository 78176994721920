import api from "../../services/api";
import {
  SAVE_CONTACT_US_FAILURE,
  SAVE_CONTACT_US_REQUEST,
  SAVE_CONTACT_US_SUCCESS,
} from "./ActionType";

const saveContactUsRequest = () => {
  return {
    type: SAVE_CONTACT_US_REQUEST,
  };
};

const saveContactUsSuccess = (contactUs) => {
  return {
    type: SAVE_CONTACT_US_SUCCESS,
    payload: contactUs,
  };
};

const saveContactUsFailure = (error) => {
  return {
    type: SAVE_CONTACT_US_FAILURE,
    payload: error,
  };
};

export const saveContactUs = (input) => async (dispatch) => {
  dispatch(saveContactUsRequest());
  try {
    const response = await api.post(`/public/contact-us`, input.contactUs, {
      withCredentials: true,
    });
    dispatch(saveContactUsSuccess(response.data));
    console.log(response.data);
    if (response.data.success) input.setSuccessMessage(response.data.message);
    else input.setErrorMessaage(response.data.message);
  } catch (error) {
    input.setErrorMessage("Failed to save contact us");
    dispatch(saveContactUsFailure(error));
  }
};
